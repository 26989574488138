import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  createStyles, Grid, Theme, Typography, WithStyles, withStyles, withWidth, Paper,
  List, ListItem, ListItemText, TextField, Select, MenuItem, Fade, Button,
} from '@material-ui/core';
import { WithWidthProps, isWidthDown } from '@material-ui/core/withWidth';
import { GridSpacing } from '@material-ui/core/Grid';
import { RouteComponentProps } from 'react-router-dom';
import axios, { CancelTokenSource } from 'axios';
import SaveIcon from '@material-ui/icons/Save';
import i18next from 'i18next';
import moment from 'moment';
import { ConversionTypes, displayUnitTypes } from '../util/conversionUtil';
import {
  AuthContext, AuthInterface, UserData,
} from '../components/AuthContext';
import { localeToLanguageAndFlag } from '../util/languageUtil';

const styles = (theme: Theme) => createStyles({
  root: {
    overflow: 'hidden',
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1),
    alignItems: 'center',
    width: '100%',
  },
  [theme.breakpoints.up('md')]: {
    root: {
      paddingTop: theme.spacing(0),
      padding: 30,
    },
  },
  titleHeader: {
    backgroundColor: theme.palette.primary.light,
    marginTop: 5,
    paddingTop: 5,
    paddingBottom: 5,
    width: '100%',
  },
  listText: {
    width: 200,
    maxWidth: 200,
  },
  button: {
    marginBottom: theme.spacing(1),
  },
});

type UserDataTypes = 'Comment' | 'Locale' | 'UserId' | 'UnitType';

interface UserInfoPanel {
  userName: string;
  email: string;
  CreatedAt: number;
  UpdatedAt: number;
}

interface SettingsPanel {
  Locale: string;
  UnitType: string;
}

interface State {
  cancelToken: CancelTokenSource;
  userInfo: UserInfoPanel;
  userInfoEdited: boolean;
  settings: SettingsPanel;
  settingsEdited: boolean;
  loaded: boolean;
}

interface Props extends RouteComponentProps,
  WithStyles<typeof styles>, WithWidthProps, WithTranslation {
}

class Profile extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      cancelToken: axios.CancelToken.source(),
      userInfo: {
        userName: '', email: '', CreatedAt: 0, UpdatedAt: 0,
      },
      userInfoEdited: false,
      settings: { Locale: 'en', UnitType: 'metric' },
      settingsEdited: false,
      loaded: false,
    };
    this.updateSettings = this.updateSettings.bind(this);
  }

  public componentDidMount() {
    const { user }: AuthInterface = this.context;
    const { userInfo } = this.state;
    const { settings } = this.state;
    Object.assign(userInfo, user);
    settings.Locale = user.Locale;
    settings.UnitType = user.UnitType;
    this.setState({ userInfo, settings, loaded: true });
  }

  private save = (edited: 'userInfo' | 'settings') => (event: React.MouseEvent<HTMLElement>) => {
    const { api, user }: AuthInterface = this.context;
    const editedPanel: UserInfoPanel | SettingsPanel = this.state[edited];
    const currentUserdata: UserData = { ...user, ...editedPanel };
    Object.assign(user, currentUserdata);
    i18next.changeLanguage(user.Locale);
    moment.locale(user.Locale);
    api.userData<string>('PUT', this.state.cancelToken, currentUserdata);
    switch (edited) {
      case 'userInfo': this.setState({ userInfoEdited: false });
        break;
      case 'settings': this.setState({ settingsEdited: false });
        break;
      default:
    }
  };

  private updateSettings = (type: 'Locale' | 'UnitType') => (event: React.ChangeEvent<{ value: unknown }>) => {
    const { user }: AuthInterface = this.context;
    const { settings } = this.state;
    settings[type] = event.target.value as string;
    const origSettings: SettingsPanel = Object.assign({}, settings);
    origSettings.Locale = user.Locale;
    origSettings.UnitType = user.UnitType;
    const edited = JSON.stringify(settings) !== JSON.stringify(origSettings);
    this.setState({ settings, settingsEdited: edited });
  }

  private listTextArea = (text: string, value: string, readOnly: boolean) => {
    const { classes } = this.props;
    return (
      <ListItem>
        <ListItemText classes={{ root: classes.listText }}>
          {text}
        </ListItemText>
        <TextField
          fullWidth
          value={value}
          InputProps={{
            readOnly,
          }}
        />
      </ListItem>
    );
  }

  public render() {
    const { classes, width, t } = this.props;
    const currentWidth = width || 'xs';
    const isMobile = (currentWidth === 'xs');
    const isTabOrMobile = isWidthDown('sm', currentWidth);
    let spacing: GridSpacing = 4;
    if (isMobile) {
      spacing = 1;
    } else if (isTabOrMobile) {
      spacing = 2;
    }
    if (this.state.loaded) {
      return (
        <main className={classes.root}>
          <Grid container alignItems="center" style={{ paddingTop: 8, paddingBottom: 16 }}>
            <Grid item className={classes.titleHeader}>
              <Typography align="center" variant="h4">
                {t('pages:profile.header')}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="center" alignItems="center" direction="column">
            <Grid item container spacing={spacing} style={{ maxWidth: 700 }}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  {t('pages:profile.userInfo')}
                </Typography>
                <Paper>
                  <List>
                    {this.listTextArea(`${t('pages:profile.username')}: `, this.state.userInfo.userName, true)}
                    {this.listTextArea(`${t('pages:profile.email')}: `, this.state.userInfo.email, true)}
                    {this.listTextArea(`${t('pages:profile.created')}: `, moment(this.state.userInfo.CreatedAt).format('LLL'), true)}
                    {this.listTextArea(`${t('pages:profile.updated')}: `, moment(this.state.userInfo.UpdatedAt).format('LLL'), true)}
                  </List>
                </Paper>
              </Grid>

              <Grid item container xs={12} justify="space-between">
                <Grid item>
                  <Typography variant="h6">
                    {t('pages:profile.settings')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Fade in={this.state.settingsEdited}>
                    <Button
                      className={classes.button}
                      onClick={this.save('settings')}
                    >
                      <SaveIcon />
                      <Typography>{t('components:button.save')}</Typography>
                    </Button>
                  </Fade>
                </Grid>
                <Grid item xs={12}>
                  <Paper>
                    <List component="div">
                      <ListItem divider alignItems="center">
                        <ListItemText>
                          {t('pages:profile.language')}
                        </ListItemText>
                        <Select
                          displayEmpty
                          value={this.state.settings.Locale}
                          onChange={this.updateSettings('Locale')}
                        >
                          {i18next.languages.map((language, index) => (
                            <MenuItem value={language} key={language}>
                              {localeToLanguageAndFlag(language)}
                            </MenuItem>
                          ))}
                        </Select>
                      </ListItem>
                      <ListItem divider alignItems="center">
                        <ListItemText>
                          {t('pages:profile.units')}
                        </ListItemText>
                        <Select
                          displayEmpty
                          value={this.state.settings.UnitType}
                          onChange={this.updateSettings('UnitType')}
                        >
                          {ConversionTypes.map(type => (
                            <MenuItem value={type} key={type}>
                              {displayUnitTypes(type)}
                            </MenuItem>
                          ))}
                        </Select>
                      </ListItem>
                    </List>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </main>
      );
    }
    return null;
  }
}

Profile.contextType = AuthContext;
export default withStyles(styles)(withTranslation()(withWidth()(Profile)));
