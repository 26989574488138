import React from 'react';
import {
  Grid, Typography, Divider, Paper, Theme, createStyles, WithStyles, withStyles,
} from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { isWidthDown } from '@material-ui/core/withWidth';
import { GridSpacing } from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import CustomIcons from '../../util/customIcons';
import KpiPanels from './KpiPanels';


const styles = (theme: Theme) => createStyles({
  card: {
    height: '100%',
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  link: {
    textDecoration: 'none',
    color: 'black',
  },
});

interface Props extends WithStyles<typeof styles> {
  loading: boolean;
  currentWidth: Breakpoint;
  avgData: {
    vol: number;
    work: number;
    down: number;
    fuel: number;
  };
  prevAvgData: {
    vol: number;
    work: number;
    down: number;
    fuel: number;
  };
  convLabels: string[];
  username: string;
  machinesSelected: number;
  totalNumOfMachines: number;
  showFiltered: boolean;
  filterExists: boolean;
}

const OverviewHeader = (props: Props) => {
  const { avgData, prevAvgData, username } = props;
  const { t } = useTranslation();
  const isMobile = (props.currentWidth === 'xs');
  const isTabOrMobile = isWidthDown('sm', props.currentWidth);

  const kpiData = [{
    val: `${avgData.vol.toFixed(0)} ${props.convLabels[0]}`,
    change: ((((avgData.vol / (prevAvgData.vol))) - 1) * 100),
    useChange: true,
    label: t('components:kpi.totalVolume'),
    iconPath: CustomIcons.volIconPath,
  }, {
    val: `${avgData.work.toFixed(0)} h`,
    change: ((((avgData.work / (prevAvgData.work))) - 1) * 100),
    useChange: true,
    label: t('components:kpi.totalWork'),
    iconPath: CustomIcons.workPath,
  }, {
    val: `${avgData.down.toFixed(0)} h`,
    change: ((((avgData.down / (prevAvgData.down))) - 1) * 100),
    useChange: true,
    label: t('components:kpi.totalDowntime'),
    iconPath: CustomIcons.downPath,
  }, {
    val: `${avgData.fuel.toFixed(0)} ${props.convLabels[1]}`,
    change: ((((avgData.fuel / (prevAvgData.fuel))) - 1) * 100),
    useChange: true,
    label: t('components:kpi.totalFuel'),
    iconPath: CustomIcons.fuelPath,
  }];
  let spacing: GridSpacing = 4;
  if (isMobile) {
    spacing = 1;
  } else if (isTabOrMobile) {
    spacing = 2;
  }

  let showString = '';
  if (!props.showFiltered) {
    showString = `${t('pages:overview.all')} ${props.totalNumOfMachines} ${t('pages:overview.machines')}`;
  } else {
    showString = `${props.machinesSelected}/${props.totalNumOfMachines} ${t('pages:overview.machines')}`;
  }

  return (
    <Grid item>
      <Grid container spacing={spacing}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Paper style={{ height: '100%' }}>
            <div style={{ padding: 8 }}>
              <Typography align="left" variant="h5">{`${t('pages:overview.welcome')} ${username}`}</Typography>
              <Typography align="left" variant="body1">
                <b>{t('pages:overview.lastLogin')}</b>
                {'2019-xx-xx:00:00'}
              </Typography>
              <Divider />
              <Grid container alignItems="center" justify="space-between">
                <Grid item>
                  <Typography align="center" variant="body1">
                    <b>{t('pages:overview.displaying')}</b>
                    {showString}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Grid>
        <Grid item md={12} lg={6}>
          <KpiPanels currentWidth={props.currentWidth} data={kpiData} loading={props.loading} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(OverviewHeader);
