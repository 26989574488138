import React from 'react';
import { match } from 'react-router-dom';
import moment from 'moment';
import Analysis from './Analysis';
import Loading from '../../../components/Loading';
import { AuthContext, AuthInterface } from '../../../components/AuthContext';
import { Machine } from '..';

interface State {
  isLoading: boolean;
  error?: Error;
  machine?: Machine;
  machineID?: string;
}

interface Params {
  id: string;
  page: string;
  startDate?: string;
  endDate?: string;
}

interface Props {
  match: match<Params>;
}

const withFetching = (Component: React.ComponentType<any>): React.ComponentClass<any, {}> => { // eslint-disable-line
  class WithFetching extends React.Component<Props, State> {
    public constructor(props: Props) {
      super(props);

      this.state = {
        isLoading: true,
      };
    }

    public componentDidMount() {
      const { id } = this.props.match.params;
      const { api }: AuthInterface = this.context;
      api.getMachine(id)
        .then((machine) => {
          this.setState({
            machine,
            isLoading: false,
          });
        }).catch((error) => {
          this.setState({ error });
        });
    }

    public render() {
      const {
        id,
        page,
        startDate,
        endDate,
      } = this.props.match.params;
      let start = startDate;
      let end = endDate;

      if (this.state.error !== undefined) {
        return <p>{`Error: ${this.state.error.message}`}</p>;
      }
      if (this.state.isLoading) {
        return <Loading />;
      }
      const { machine } = this.state;
      if (startDate === undefined || endDate === undefined) {
        start = moment().startOf('week').format('YYYY-MM-DD');
        end = moment().endOf('week').format('YYYY-MM-DD');
        const savedDateJson = sessionStorage.getItem('dateRange');
        if (savedDateJson) {
          const savedDate = JSON.parse(savedDateJson);
          const range = savedDate.split('~');
          const savedStartDate = moment(range[0]);
          const savedEndDate = moment(range[1]);
          if (savedStartDate.isValid() || savedEndDate.isValid()) {
            start = savedStartDate.format('YYYY-MM-DD');
            end = savedEndDate.format('YYYY-MM-DD');
          }
        }
      }

      return (
        <Component
          machine={machine}
          page={page}
          machineID={id}
          startDate={start}
          endDate={end}
          {...this.props}
        />
      );
    }
  }
  WithFetching.contextType = AuthContext;
  return WithFetching;
};

export default withFetching(Analysis);
