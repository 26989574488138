import React from 'react';
import {
  WithStyles, withStyles, Theme, createStyles, Paper, Grid,
} from '@material-ui/core';
import { ChartData } from 'react-chartjs-2';
import ChartExportButton from './ChartExportButton';
import { SpeciesData } from '../../util/dataFormatting';
import Loading from '../Loading';

const styles = (theme: Theme) => createStyles({
  paper: {
    width: '100%',
    height: '100%',
  },
  loading: {
    backgroundColor: theme.palette.background.paper,
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: 4,
  },
});

interface Props extends WithStyles<typeof styles> {
  loaded?: boolean;
  datasets?: ChartData<Chart.ChartData>[];
  tableData?: Map<string, SpeciesData[]>[];
  name: string;
  isTabOrMobile: boolean;
  component: JSX.Element;
  customClasses?: string;
  tooltip?: string;
}


export const ChartPaper = (props: Props) => {
  const { classes } = props;
  const [mouseOver, toggleMouseOver] = React.useState(false);

  const handleMouseOver = () => {
    toggleMouseOver(!mouseOver);
  };

  return (
    <Paper className={props.customClasses ? props.customClasses : classes.paper} onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOver} style={{ position: 'relative' }}>
      {props.loaded === false ? null : (
        <ChartExportButton
          barData={props.datasets}
          tableData={props.tableData}
          filename={props.name}
          titles={[props.name]}
          hidden={props.isTabOrMobile ? false : !mouseOver}
          tooltip={props.tooltip}
        />
      )}
      <div style={{ position: 'relative', height: '100%' }}>
        {props.component}
        {props.loaded === false ? <Grid container justify="center" alignItems="center" className={classes.loading}><Loading /></Grid> : null}
      </div>
    </Paper>
  );
};

export default withStyles(styles)(ChartPaper);
