import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import {
  WithStyles, withStyles, Theme, createStyles, TableHead,
  TableRow, TableCell, TableBody, Tabs, Tab, Typography,
} from '@material-ui/core';
import { Translation } from 'react-i18next';
import { SpeciesData } from '../../util/dataFormatting';
import { TableSummary, TableCellNormal } from '../../util/MapToTable';

const styles = (theme: Theme) => createStyles({
  table: {
    margin: theme.spacing(2),
    marginTop: 0,
    maxHeight: 400,
    overflow: 'auto',
  },
  [theme.breakpoints.down('xs')]: {
    table: {
      margin: theme.spacing(0),
      marginTop: 0,
    },
  },
  export: {
    position: 'relative',
    zIndex: 1000,
  },
  tabs: {
    borderBottom: 'solid',
  },
});

const TableCellHead = withStyles((theme: Theme) => createStyles({
  head: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.paper,
    color: 'black',
    fontSize: 14,
    fontWeight: 'bold',
    paddingRight: theme.spacing(1),
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);


interface Props extends WithStyles<typeof styles> {
  list: Map<string, SpeciesData[]>;
  summary: Map<string, SpeciesData[]>;
  labels: string[];
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <div>{children}</div>
    </Typography>
  );
}

export const MachineProductTable = (props: Props) => {
  const { classes, labels } = props;
  const [value, setValue] = useState(0);
  const array = Array.from(props.list.entries());
  const summaryArray = Array.from(props.summary.values());
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Translation>
      {t => (
        <div>
          <Tabs className={classes.tabs} value={value} onChange={handleChange}>
            {array.map((node, index) => (
              <Tab label={node[1][0].speciesGroupName} key={`tab${node[0]}`} />
            ))}
          </Tabs>
          {array.map((node, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <TabPanel value={value} index={index} key={`panel${index}`}>
              <div className={classes.table}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCellHead>{`${t('components:chart.production.products')}`}</TableCellHead>
                      <TableCellHead align="right">{`${t('components:chart.production.volume')} (${labels[0]}${t('components:chart.production.sub')})`}</TableCellHead>
                      <TableCellHead align="right">{`${t('components:chart.production.volume')} (${labels[1]}${t('components:chart.production.sob')})`}</TableCellHead>
                      <TableCellHead align="right">{`${t('components:chart.production.avgLogVol')} (${labels[0]}${t('components:chart.production.sub')})`}</TableCellHead>
                      <TableCellHead align="right">{`${t('components:chart.production.logs')} ${labels[3]}`}</TableCellHead>
                      <TableCellHead align="right">{`${t('components:chart.production.avgLen')} (${labels[4]})`}</TableCellHead>
                      <TableCellHead align="right">{`${t('components:chart.production.avgDiam')} (${labels[5]})`}</TableCellHead>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {node[1].map(data => (
                      <TableRow key={data.speciesIdentity + data.productIdentity}>
                        <TableCellNormal align="center">{data.productName}</TableCellNormal>
                        <TableCellNormal align="right">{Number(data.m3Sub).toFixed(2)}</TableCellNormal>
                        <TableCellNormal align="right">{Number(data.m3Sob).toFixed(2)}</TableCellNormal>
                        <TableCellNormal align="right">{(data.m3Sub / data.numberOfLogs).toFixed(2)}</TableCellNormal>
                        <TableCellNormal align="right">{data.numberOfLogs}</TableCellNormal>
                        <TableCellNormal align="right">{Number(data.avgLogLength).toFixed(0)}</TableCellNormal>
                        <TableCellNormal align="right">{Number(data.avgLogDiameter).toFixed(0)}</TableCellNormal>
                      </TableRow>
                    ))}
                    {summaryArray.length > 0
                      ? (
                        <TableRow>
                          <TableSummary align="center">Summary</TableSummary>
                          <TableSummary align="right">{Number(summaryArray[index][0].m3Sub).toFixed(2)}</TableSummary>
                          <TableSummary align="right">{Number(summaryArray[index][0].m3Sob).toFixed(2)}</TableSummary>
                          <TableSummary align="right">{(summaryArray[index][0].m3Sub / summaryArray[index][0].numberOfLogs).toFixed(2)}</TableSummary>
                          <TableSummary align="right">{summaryArray[index][0].numberOfLogs}</TableSummary>
                          <TableSummary align="right">{Number(summaryArray[index][0].avgLogLength).toFixed(0)}</TableSummary>
                          <TableSummary align="right">{Number(summaryArray[index][0].avgLogDiameter).toFixed(0)}</TableSummary>
                        </TableRow>
                      )
                      : null}
                  </TableBody>
                </Table>
              </div>
            </TabPanel>
          ))}
        </div>
      )}
    </Translation>
  );
};

export default withStyles(styles)(MachineProductTable);
