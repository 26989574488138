import React from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { AuthContext, AuthInterface } from '../components/AuthContext';


interface Props extends RouteComponentProps {
  isChanged: boolean;
}

class Login extends React.Component<Props> {
  public constructor(props: Props) {
    super(props);
    this.componentDidMount = this.componentDidMount.bind(this);
  }

  public componentDidMount() {
    const { reAuth }: AuthInterface = this.context;
    reAuth();
  }

  public render() {
    const { isAuth } = this.context;
    const { from } = this.props.location.state || { from: { pathname: '/overview' } };
    if (!isAuth()) {
      return <div>loading...</div>;
    }
    return <Redirect to={from} />;
  }
}
Login.contextType = AuthContext;

export default (Login);
