import {
  createStyles, Grid, Theme, WithStyles, withStyles,
} from '@material-ui/core';
import React from 'react';
import { ChartData } from 'react-chartjs-2';
import withWidth, { WithWidthProps, isWidthDown } from '@material-ui/core/withWidth';
import { withTranslation, WithTranslation } from 'react-i18next';
import { GridSpacing } from '@material-ui/core/Grid';
import moment from 'moment';
import {
  SpeciesData, M3Data, parseMultipleValues, createDoughnut, createChartData, createOptionsCustom,
} from '../../../util/dataFormatting';
import { AuthInterface, AuthContext } from '../../../components/AuthContext';
import ChartExportButton from '../../../components/Charts/ChartExportButton';
import SpeciesTable from '../../../components/Charts/SpeciesTable';
import DoughnutChart from '../../../components/Charts/DoughnutChart';
import ProductTable from '../../../components/Charts/ProductTable';
import BarChart from '../../../components/Charts/BarChart';
import DashHead from '../../../components/Charts/DashHead';
import { createConvFunctions, ChartUnitOptions, convertHprTable } from '../../../util/conversionUtil';
import ChartPaper from '../../../components/Charts/ChartPaper';

const styles = (theme: Theme) => createStyles({
  root: {
    width: '100%',
    margin: 20,
  },
  paper: {
    width: '100%',
    height: '100%',
  },
});

const defaultDataset = {
  labels: [],
  datasets: [
    {
      type: 'bar',
      label: '',
      backgroundColor: 'rgba(138,173,61,1)',
      borderColor: 'rgba(138,173,61,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(138,173,61,0.6)',
      hoverBorderColor: 'rgba(138,173,61,1)',
      data: [],
    },
  ],
};

const defaultDataset2 = {
  labels: [],
  datasets: [
    {
      type: 'doughnut',
      label: '',
      backgroundColor: 'rgba(138,173,61,1)',
      borderColor: 'rgba(138,173,61,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(138,173,61,0.6)',
      hoverBorderColor: 'rgba(138,173,61,1)',
      data: [],
    },
  ],
};

const PRODUCTION_PATH = '/production';
interface Props extends WithStyles<typeof styles>, WithWidthProps, WithTranslation {
  fileId: string;
  fileName: string;
  chartUnitOptions: ChartUnitOptions;
}

interface State {
  isLoading1: boolean;
  isLoading2: boolean;
  isLoading3: boolean;
  summaryValues: number[];
  chartDataList: ChartData<Chart.ChartData>[];
  speciesDataList: Map<string, SpeciesData[]>;
  productsDataList: Map<string, SpeciesData[]>;
  doughnutData: ChartData<Chart.ChartData>;
}

class HprOverview extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    const chartDataList = new Array(6).fill(defaultDataset);
    const speciesDataList = new Map<string, SpeciesData[]>();
    const productsDataList = new Map<string, SpeciesData[]>();
    const doughnutData = defaultDataset2;
    this.state = {
      isLoading1: true,
      isLoading2: true,
      isLoading3: true,
      summaryValues: [0, 0, 0, 0, 0, 0, 0],
      chartDataList,
      speciesDataList,
      productsDataList,
      doughnutData,
    };
  }

  public componentDidMount() {
    const { api }: AuthInterface = this.context;
    const { t } = this.props;
    const options = createConvFunctions(this.props.chartUnitOptions);

    api.getFile<M3Data>(PRODUCTION_PATH, this.props.fileId, 'week').then((response) => {
      const parsed = parseMultipleValues(response.map(({
        harvestDate: time,
        operator: label,
        m3SubSum: value1,
        m3SobSum: value2,
        stemCount: value3,
        logCount: value4,
        avgLogLength: value5,
        avgLogDiameter: value6,
      }) => ({ time: moment(time).format('M/D/YY'), label: t('components:chart.production.total'), value: [value1, value2, value3, value4, value5, value6] })), options);
      const charts = createChartData(6, parsed.labels, parsed.operatorList, parsed.dataMap);
      this.setState({
        isLoading1: false,
        chartDataList: charts,
        summaryValues: parsed.summary,
      });
    }).catch(() => {
      this.setState({
        isLoading1: false,
      });
    });

    api.getFile<SpeciesData>(`${PRODUCTION_PATH}/species`, this.props.fileId, 'day').then((response) => {
      const speciesDataList = new Map<string, SpeciesData[]>();
      convertHprTable(response, options);
      response.forEach((node) => {
        const isIn = speciesDataList.get(node.speciesGroupName);
        isIn ? isIn.push(node) : speciesDataList.set(node.speciesGroupName, [node]);
      });
      this.setState({
        speciesDataList,
        doughnutData: createDoughnut(speciesDataList),
        isLoading2: false,
      });
    }).catch(() => {
      this.setState({
        isLoading2: false,
      });
    });


    api.getFile<SpeciesData>(`${PRODUCTION_PATH}/speciesandproducts`, this.props.fileId, 'day').then((response) => {
      const productsDataList = new Map<string, SpeciesData[]>();
      convertHprTable(response, options);
      response.forEach((node) => {
        const isIn = productsDataList.get(node.speciesGroupName);
        isIn ? isIn.push(node) : productsDataList.set(node.speciesGroupName, [node]);
      });
      this.setState({
        productsDataList,
        isLoading3: false,
      });
    }).catch(() => {
      this.setState({
        isLoading3: false,
      });
    });
  }

  public render() {
    const { width, t } = this.props;
    const {
      chartDataList, speciesDataList, productsDataList, doughnutData, summaryValues,
    } = this.state;

    const isMobile = ((width || 'xs') === 'xs');
    const isTabOrMobile = isWidthDown('sm', (width || 'xs'));
    let chartHeigth = 250;
    let spacing: GridSpacing = 4;
    if (isMobile) {
      chartHeigth = 200;
      spacing = 1;
    } else if (isTabOrMobile) {
      chartHeigth = 300;
      spacing = 2;
    }

    const titles = [t('components:chart.production.volume'), t('components:kpi.totalLogs'), t('components:kpi.totalStems'), t('components:chart.production.species'), t('components:chart.production.products')];
    return (
      <>
        {this.state.isLoading1 || this.state.isLoading2 || this.state.isLoading3 ? null
          : (
            <div>
              <ChartExportButton
                barData={[chartDataList[0],
                  chartDataList[2],
                  chartDataList[3]]}
                tableData={[speciesDataList, productsDataList]}
                filename={`${t('pages:hpr.overview')}_${this.props.fileName}`}
                tooltip={t('components:button.downloadAll')}
                titles={titles}
                hidden={false}
              />
            </div>
          )
        }
        <Grid container spacing={spacing}>
          <DashHead
            loading={this.state.isLoading1}
            data={{
              subSum: summaryValues[0],
              stems: summaryValues[2],
              logs: summaryValues[3],
              fileInfo: {
                machineOwner: '"Owner"', machineId: 'XXXX-XXXX', objectName: '"Object"', objectId: 'XXXX-XXXX', OrderNr: 'XXXX-XXXX', fileName: this.props.fileName,
              },
            }}
            volUnit={this.props.chartUnitOptions.valueLabelList[0]}
          />
          <Grid item xs={12} sm={6}>
            <ChartPaper
              loaded={!this.state.isLoading2}
              tableData={[speciesDataList]}
              isTabOrMobile={isTabOrMobile}
              name={t('components:chart.production.species')}
              tooltip={t('components:misc.downloadRawData')}
              component={(
                <SpeciesTable
                  list={speciesDataList}
                  labels={this.props.chartUnitOptions.valueLabelList}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ChartPaper
              loaded={!this.state.isLoading2}
              datasets={[doughnutData]}
              isTabOrMobile={isTabOrMobile}
              name={t('components:chart.production.volume')}
              component={(
                <DoughnutChart
                  datasets={doughnutData}
                  unitLabel={this.props.chartUnitOptions.valueLabelList[0]}
                  options={{
                    title:
                      { text: t('components:chart.production.volPerSpec'), display: true },
                    maintainAspectRatio: false,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <ChartPaper
              loaded={!this.state.isLoading3}
              tableData={[productsDataList]}
              isTabOrMobile={isTabOrMobile}
              name={t('components:chart.production.products')}
              tooltip={t('components:misc.downloadRawData')}
              component={(
                <ProductTable
                  list={productsDataList}
                  labels={this.props.chartUnitOptions.valueLabelList}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ChartPaper
              loaded={!this.state.isLoading1}
              datasets={[chartDataList[0]]}
              isTabOrMobile={isTabOrMobile}
              name={t('components:chart.production.volume')}
              component={(
                <BarChart
                  datasets={chartDataList[0]}
                  height={chartHeigth}
                  options={createOptionsCustom(this.props.chartUnitOptions.valueLabelList[0], { num: 5000, label: t('components:chart.goal') }, 1)}
                  title={`${t('components:chart.production.volume')} (${t('components:chart.production.sub')})`}
                  key={chartHeigth.toString() + this.state.isLoading1}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ChartPaper
              loaded={!this.state.isLoading1}
              datasets={[chartDataList[2]]}
              isTabOrMobile={isTabOrMobile}
              name={t('components:chart.production.stems')}
              component={(
                <BarChart
                  datasets={chartDataList[2]}
                  height={chartHeigth}
                  options={createOptionsCustom(this.props.chartUnitOptions.valueLabelList[2])}
                  title={t('components:chart.production.stems')}
                  key={chartHeigth.toString() + this.state.isLoading1}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ChartPaper
              loaded={!this.state.isLoading1}
              datasets={[chartDataList[3]]}
              isTabOrMobile={isTabOrMobile}
              name={t('components:chart.production.logs')}
              component={(
                <BarChart
                  datasets={chartDataList[3]}
                  height={chartHeigth}
                  options={createOptionsCustom(this.props.chartUnitOptions.valueLabelList[3])}
                  title={t('components:chart.production.logs')}
                  key={chartHeigth.toString() + this.state.isLoading1}
                />
              )}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}
HprOverview.contextType = AuthContext;

export default withWidth()(withStyles(styles)(withTranslation()(HprOverview)));
