import React from 'react';
import {
  Route, Switch, Redirect,
} from 'react-router-dom';
import Admin from '../pages/Admin';
import Analysis from '../pages/Machines/Analysis/index';
import FileAnalysis from '../pages/Files/FileAnalysis';
import Machines from '../pages/Machines/index';
import Overview from '../pages/Overview';
import Files from '../pages/Files/Files';
import Report from '../pages/Report';
import Login from '../pages/Login';
import PrivateRoute from './PrivateRoute';
import { AuthConsumer } from './AuthContext';
import withTracker from '../helpers/withTracker';
import Profile from '../pages/Profile';

const Routes = () => (
  <Switch>
    <PrivateRoute path="/admin/" component={withTracker(Admin)} />
    <PrivateRoute path="/machines/:id/:page(monitoring|production)/:startDate?/:endDate?" component={withTracker(Analysis)} />
    <PrivateRoute path="/machines/" component={withTracker(Machines)} />
    <PrivateRoute path="/overview/:startDate?/:endDate?" component={withTracker(Overview)} />
    <PrivateRoute path="/report/" component={withTracker(Report)} />
    <PrivateRoute path="/profile/" component={withTracker(Profile)} />
    <Route path="/login/" component={withTracker(Login)} />
    <AuthConsumer>
      {({ isAuth, accessRights }) => (
        <>
          {accessRights('FileViewer') ? (
            <Switch>
              <PrivateRoute path="/files/:id" component={withTracker(FileAnalysis)} />
              <PrivateRoute path="/files/" component={withTracker(Files)} />
            </Switch>
          ) : <Redirect to="/overview" />
          }
          <Route
            exact
            path="/"
            render={() => (
              isAuth() ? (
                <Redirect to="/overview" />
              ) : (
                <Redirect to="/login" />
              )
            )}
          />
          <Route
            path="/login/"
            render={() => (
              isAuth() ? (
                <Redirect to="/overview" />
              ) : (
                <Redirect to="/login" />
              )
            )}
          />
        </>
      )}
    </AuthConsumer>
  </Switch>
);

export default Routes;
