import React from 'react';
import {
  WithStyles, withStyles, Theme, createStyles,
} from '@material-ui/core';
import { ChartData } from 'react-chartjs-2';
import CustomChart from './CustomChart';
import { ColorPicker } from '../../util/colors';

const styles = (theme: Theme) => createStyles({
});

interface Props extends WithStyles<typeof styles> {
  datasets: ChartData<Chart.ChartData>;
  options: Chart.ChartOptions;
  title?: string;
  height?: number;
  width?: number;
  key?: string;
}


export const BarChart = (props: Props) => (
  <CustomChart
    chartType="Bar"
    key={props.key}
    datasets={props.datasets}
    title={props.title}
    width={props.width}
    height={props.height}
    options={props.options}
    origColor={ColorPicker.chartPallet.normal}
    dimColor={ColorPicker.chartPallet.dim}
  />
);

export default withStyles(styles)(BarChart);
