import axios, { CancelTokenSource } from 'axios';
import Keycloak from 'keycloak-js';
import i18next from 'i18next';
import moment from 'moment';
import { Credentials, UserData, AccessRights } from '../components/AuthContext';
import env from '../helpers/env';
import { SFFile } from '../pages/Files/Files';
import { Machine } from '../pages/Machines';
import { MachineData } from '../util/dataFormatting';

interface MachinesResponse {
  hits: {
    hits: Machine[];
  };
}

interface FileListResponse {
  fileList: SFFile[];
}

export interface FileTaggingResponse {
  filename: string;
  gbqtableId: string;
}

interface MetabaseToken {
  metabaseToken: string;
  metabaseURL: string;
}

interface GetResourceOptions {
  method?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: any;
  retry?: boolean;
}

interface ParsedToken {
  username: string;
  email: string;
  family_name: string;
  given_name: string;
  identityId: string;
  token: string;
  resource_access?: { connectbucking: { roles: string[] } };
}

interface GetFileUploadURLResponse {
  err: object;
  url: string;
  filename: string;
  tagging: string;
  statusText: string;
}

interface DownloadLinkResponse {
  url: string;
  err: object;
}

export default class API {
  private getKeycloak: () => Keycloak.KeycloakInstance;

  private getCredentials: () => Credentials;

  private updateCredentials: (credentials: Credentials) => (void);

  private updateContext: (
    authorized: boolean,
    credentials: Credentials,
    accessRights: AccessRights,
    user: UserData
  ) => (void);

  private getUserdata: () => UserData;

  public constructor(
    getKeycloak: () => Keycloak.KeycloakInstance,
    getCredentials: () => Credentials,
    updateContext: (
      authorized: boolean,
      credentials: Credentials,
      accessRights: AccessRights,
      user: UserData
    ) => void,
    updateCredentials: (credentials: Credentials) => void,
    getUserdata: () => UserData,
  ) {
    this.getKeycloak = getKeycloak;
    this.getCredentials = getCredentials;
    this.updateContext = updateContext;
    this.getUserdata = getUserdata;
    this.updateCredentials = updateCredentials;
  }

  private authHeader() {
    return {
      'Content-Type': 'application/json',
      Authorization: this.getCredentials().userToken,
    };
  }

  private authHeaderKC() {
    return {
      'Content-Type': 'application/json',
      Authorization: this.getKeycloak().token,
    };
  }

  public logout() {
    this.updateContext(
      false,
      {
        userIdentityId: '',
        userToken: '',
      }, [], {
        userName: '', UserId: '', firstName: '', lastName: '', email: '', Comment: '', Locale: 'en-GB', CreatedAt: 0, UnitType: 'metric', UpdatedAt: 0, OverviewMachines: [], OverviewFilter: 0,
      },
    );
    localStorage.clear();
    sessionStorage.clear();
    this.getKeycloak().logout();
  }

  public static parseUser(parsed: ParsedToken, userdata: UserData): UserData {
    const user = userdata;
    user.email = parsed.email;
    user.userName = parsed.username;
    user.firstName = parsed.given_name;
    user.lastName = parsed.family_name;
    return user;
  }

  public static parseCredentials(parsed: ParsedToken): Credentials {
    const credentials: Credentials = {
      userIdentityId: parsed.identityId,
      userToken: parsed.token,
    };
    return credentials;
  }

  public login() {
    const keycloak = this.getKeycloak();
    return keycloak.init({
      onLoad: 'login-required',
      checkLoginIframe: false,
      flow: 'standard',
    }).success(() => {
      this.userData<UserData>('GET').then((userdata) => {
        if (keycloak.authenticated && keycloak.tokenParsed !== undefined) {
          const parsed: ParsedToken = <ParsedToken>keycloak.tokenParsed; // eslint-disable-line
          const user = API.parseUser(parsed, userdata);
          i18next.changeLanguage(userdata.Locale);
          moment.locale(userdata.Locale);

          const credentials = API.parseCredentials(parsed);
          let accessRights: AccessRights;
          parsed.resource_access ? accessRights = parsed.resource_access.connectbucking.roles
            : accessRights = [];

          this.updateContext(true, credentials, accessRights, user);
        }
      });
    }).error(() => this.logout());
  }

  public updateTokenKC() {
    const keycloak: Keycloak.KeycloakInstance = this.getKeycloak();

    return new Promise((resolve, reject) => {
      keycloak.updateToken(3000).success(() => {
        const parsed: ParsedToken = <ParsedToken>keycloak.tokenParsed; // eslint-disable-line

        const user = API.parseUser(parsed, this.getUserdata());
        const credentials = API.parseCredentials(parsed);
        let accessRights: AccessRights;
        parsed.resource_access ? accessRights = parsed.resource_access.connectbucking.roles
          : accessRights = [];

        this.updateContext(true, credentials, accessRights, user);
        resolve();
      }).error((error) => { reject(error); });
    });
  }

  public refreshTokenMic() {
    return new Promise((resolve, reject) => {
      this.getResourceKC<string>(env.api.refreshToken, { method: 'get' }, { 'Content-Type': 'application/json', Authorization: `Bearer ${this.getKeycloak().token}` })
        .then((token) => {
          const cred = this.getCredentials();
          if (cred.userToken !== token) {
            cred.userToken = token;
            this.updateCredentials(cred);
            resolve();
          }
          reject();
        });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getResource<T>(url: string, options: GetResourceOptions): Promise<T> {
    const {
      method = 'get', data, params, retry = true,
    } = options;
    const requestOptions = {
      url,
      method,
      data,
      params,
      headers: this.authHeader(),
    };
    return axios(requestOptions)
      .then(res => res.data)
      .catch((e) => {
        if (e.response && e.response.status === 401) {
          if (retry) {
            const noRetry = Object.assign({}, options, { retry: false });
            return this.refreshTokenMic().then(() => this.updateTokenKC()
              .then(() => new Promise((resolve) => {
                this.getResource<T>(url, noRetry).then((response) => { resolve(response); });
              }).catch(() => {
                this.logout();
              })));
          }
        }
        throw e;
      });
  }

  private getResourceKC<T>(
    url: string,
    options: GetResourceOptions,
    headers: object = this.authHeaderKC(),
    cancelToken?: CancelTokenSource,
    progressCallback?: (pe: ProgressEvent) => void,
  ): Promise<T> {
    const {
      method = 'get', data, params, retry = true,
    } = options;
    const requestOptions = {
      url,
      method,
      data,
      params,
      headers,
      onUploadProgress: progressCallback,
      cancelToken: cancelToken ? (cancelToken.token) : undefined,
    };
    return axios(requestOptions)
      .then(res => res.data)
      .catch((e) => {
        if (e.response) {
          if (retry) {
            const noRetry = Object.assign({}, options, { retry: false });
            if (e.response.status === 401) {
              return this.updateTokenKC()
                .then(() => new Promise((resolve) => {
                  this.getResourceKC<T>(
                    url,
                    noRetry,
                    this.authHeaderKC(),
                    cancelToken,
                    progressCallback,
                  ).then((response) => { resolve(response); });
                }).catch(() => {
                  this.logout();
                }));
            } if (e.response.status === 403) {
              return this.refreshTokenMic().then(() => this.updateTokenKC()
                .then(() => new Promise((resolve) => {
                  this.getResourceKC<T>(
                    url,
                    noRetry,
                    this.authHeaderKC(),
                    cancelToken,
                    progressCallback,
                  ).then((response) => { resolve(response); });
                }).catch(() => {
                  this.logout();
                })));
            }
          }
        }
        throw e;
      });
  }

  public getMachines(): Promise<Machine[]> {
    const data = {
      query: {
        size: 100,
        _source: ['thingName', 'label', 'createdAt', 'domain', 'externalId'],
        query: {
          match_all: {}, // eslint-disable-line @typescript-eslint/camelcase
        },
      },
    };
    return this.getResource<MachinesResponse>(env.api.thingsFind, { method: 'post', data })
      .then(res => res.hits.hits);
  }

  public getMachine(externalId: string): Promise<Machine> {
    const data = {
      query: {
        query: {
          match: {
            externalId,
          },
        },
      },
    };
    return this.getResource<MachinesResponse>(env.api.thingsFind, { method: 'post', data })
      .then(res => res.hits.hits[0]);
  }

  public getMetabaseToken(
    dashboard: number,
    machineID?: string,
    dateRange?: string,
  ): Promise<MetabaseToken> {
    const options = {
      params: { dashboard, machineID, dateRange },
    };
    return this.getResource<MetabaseToken>(env.api.metabaseToken, options);
  }

  public getPdfUrl(url: string): string { // eslint-disable-line
    const encodedUrl = encodeURIComponent(url);
    return `${env.api.pdfBaseUrl}?url=${encodedUrl}&selector=.LoadingSpinner&hidden=true&scale=0.6&printBackground=false`;
  }


  public getFileList(): Promise<SFFile[]> {
    return this.getResourceKC<FileListResponse>(env.api.getFileList, {})
      .then(res => res.fileList);
  }

  public getFileTagging(filename: string): Promise<FileTaggingResponse> {
    return this.getResourceKC<FileTaggingResponse>(env.api.getFileList, { method: 'get', params: { filename } })
      .then(res => res);
  }

  public getFile<T>(path: string, tableid: string, timegroup: string): Promise<T[]> { // eslint-disable-line
    return this.getResourceKC<T[]>(env.api.getFile + path, { method: 'get', params: { tableid, timegroup } })
      .then(res => res);
  }

  public getFileGeo<T>(path: string, tableid: string): Promise<T[]> { // eslint-disable-line
    return this.getResourceKC<T[]>(env.api.getFile + path, { method: 'get', params: { tableid } })
      .then(res => res);
  }

  public getFileUploadURL(filename: string): Promise<GetFileUploadURLResponse> {
    return this.getResourceKC<GetFileUploadURLResponse>(env.api.uploadFile, { method: 'get', params: { filename } })
      .then(res => res);
  }

  public directUploadFile(
    progressCallback: (pe: ProgressEvent) => void,
    data: object,
    url: string,
    tagging: string,
  ): Promise<object> {
    const headers = {
      'Content-Type': 'multipart/form-data',
      'x-amz-tagging': tagging,
    };

    return this.getResourceKC<object>(url, { method: 'put', data }, headers, undefined, progressCallback)
      .then();
  }

  public deleteFile(filename: string): Promise<object> {
    return this.getResourceKC<object>(env.api.deleteFile, { method: 'post', params: { filename } })
      .then(res => res);
  }

  public downloadFile(filename: string): Promise<DownloadLinkResponse> {
    return this.getResourceKC<DownloadLinkResponse>(env.api.downloadFile, { method: 'get', params: { filename } })
      .then(res => res);
  }

  public getMachineData(
    path: string,
    machineString: string,
    dateRange: string,
    timegroup: string,
    sumMachines: boolean,
    operatorSort: boolean,
    cancelToken: CancelTokenSource,
  ): Promise<MachineData[]> {
    return this.getResourceKC<MachineData[]>(env.api.getMachineData + path, {
      method: 'get',
      params: {
        machineString, dateRange, timegroup, sumMachines, operatorSort,
      },
    }, undefined, cancelToken);
  }

  public getOneMachine<T>(
    path: string,
    machine: string,
    dateRange: string,
    cancelToken: CancelTokenSource,
    timegroup?: string,
  ): Promise<T[]> {
    return this.getResourceKC<T[]>(env.api.getMachineData + path, { method: 'get', params: { machine, dateRange, timegroup } }, undefined, cancelToken);
  }

  public userData<T>(method: 'POST' | 'PUT' | 'GET' | 'DELETE', cancelToken?: CancelTokenSource, data?: object) {
    return this.getResourceKC<T>(env.api.userdata, { method, data }, undefined, cancelToken);
  }
}
