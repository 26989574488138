import {
  createStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  Grid,
  Switch,
  Tooltip,
  Fade,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import * as React from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { Translation } from 'react-i18next';
import moment from 'moment';
import { Machine } from '.';
import { AuthContext, AuthInterface } from '../../components/AuthContext';

const styles = (theme: Theme) => createStyles({
  root: {
    margin: 30,
    marginTop: theme.spacing(3),
    alignItems: 'center',
    justify: 'center',
  },
  h3: {
    marginLeft: theme.spacing(),
  },
  table: {
    minWidth: 700,
  },
  tableContainer: {
    overflowX: 'auto',
  },
  tr: {
    cursor: 'pointer',
    height: 'auto',
  },
  trHead: {
    height: 'auto',
  },
  td: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(0),
    color: 'black',
    fontSize: 14,
  },
  tdh: {
    fontSize: 16,
    fontWeight: 'bolder',

    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  [theme.breakpoints.up('sm')]: {
    td: {
      '&:first-child': {
        paddingLeft: theme.spacing(1),
      },
      '&:last-child': {
        paddingRight: 0,
      },
    },
    tableContainer: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  link: {
    '&:hover': {
      textDecoration: 'underline',
    },
    textDecoration: 'none',
    color: 'black',
  },

  titleHeader: {
    backgroundColor: theme.palette.primary.light,
    margin: 20,
    marginBottom: 0,
    paddingTop: 5,
    paddingBottom: 5,
    width: '100%',
  },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  machines: Machine[];
  savedSwitches: { id: string; val: boolean }[];
}

interface State {
  switches: { id: string; val: boolean }[];
  origSwitches: { id: string; val: boolean }[];
  edited: boolean;
}

class MachineList extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    const switches: { id: string; val: boolean }[] = props.savedSwitches.map(a => ({ ...a }));
    this.state = { switches, origSwitches: props.savedSwitches, edited: false };

    this.saveSwitches = this.saveSwitches.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
    this.cancelSwitches = this.cancelSwitches.bind(this);
  }

  private saveSwitches = () => {
    const { api, user }: AuthInterface = this.context;
    const array: string[] = [];
    const { switches } = this.state;
    switches.forEach((machine) => {
      if (machine.val) {
        array.push(machine.id);
      }
    });
    const modifiedUser = user;
    modifiedUser.OverviewMachines = array;
    if (array.length === 0) {
      modifiedUser.OverviewFilter = 0;
    }
    this.setState({ edited: false, origSwitches: switches.map(a => ({ ...a })) });
    api.userData<string>('PUT', undefined, modifiedUser);
  }

  private cancelSwitches = () => {
    this.setState(prevState => ({
      switches: prevState.origSwitches.map(a => ({ ...a })),
      edited: false,
    }));
  }

  private handleSwitch = (id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const map = this.state.switches;
    const orig = this.state.origSwitches;
    const temp = map.find(machine => id === machine.id);
    if (temp !== undefined) {
      temp.val = !temp.val;
      const edited = JSON.stringify(map) !== JSON.stringify(orig);
      this.setState({ switches: map, edited });
    }
  }

  private onRowClick = (id: string) => {
    this.props.history.push(`/machines/${id}/monitoring`);
  }

  public render() {
    const { classes, machines } = this.props;

    /* eslint-disable no-underscore-dangle */
    return (
      <Translation>
        {t => (
          <>
            <Grid container spacing={3} justify="center" className={classes.titleHeader}>
              <Typography variant="h4">
                {t('machines.machines')}
              </Typography>
            </Grid>
            <Grid className={classes.root} item xs>
              <div className={classes.tableContainer}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow className={classes.trHead}>
                      <TableCell className={[classes.td, classes.tdh].join(' ')}>
                        <Grid container>
                          <Grid item>
                            <Tooltip title={t('pages:machines.showOverview')}>
                              <Link className={classes.link} to="/overview">
                                {t('components:navigation.overview')}
                              </Link>
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Fade in={this.state.edited}>
                              <Tooltip title={t('components:button.save')}>
                                <CheckIcon onClick={this.saveSwitches} />
                              </Tooltip>
                            </Fade>
                          </Grid>
                          <Grid item>
                            <Fade in={this.state.edited}>
                              <Tooltip title={t('components:button.cancel')}>
                                <ClearIcon onClick={(this.cancelSwitches)} />
                              </Tooltip>
                            </Fade>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell className={[classes.td, classes.tdh].join(' ')}>{t('pages:machines.machineNr')}</TableCell>
                      <TableCell className={[classes.td, classes.tdh].join(' ')}>{t('pages:machines.name')}</TableCell>
                      <TableCell className={[classes.td, classes.tdh].join(' ')}>{t('pages:machines.domain')}</TableCell>
                      <TableCell className={[classes.td, classes.tdh].join(' ')}>{t('pages:machines.created')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {machines.map((machine, index) => (
                      <TableRow hover key={machine._id} className={classes.tr}>
                        <TableCell className={classes.td}>
                          <Switch
                            key={machine._id}
                            checked={this.state.switches[index].val}
                            onChange={this.handleSwitch(machine._id)}
                          />
                        </TableCell>
                        <TableCell
                          className={classes.td}
                          onClick={
                            () => this.onRowClick(machine._source.externalId)
                          }
                        >
                          {machine._source.externalId}
                        </TableCell>
                        <TableCell
                          className={classes.td}
                          onClick={
                            () => this.onRowClick(machine._source.externalId)
                          }
                        >
                          {machine._source.label}
                        </TableCell>
                        <TableCell
                          className={classes.td}
                          onClick={
                            () => this.onRowClick(machine._source.externalId)
                          }
                        >
                          {machine._source.domain}
                        </TableCell>
                        <TableCell
                          className={classes.td}
                          onClick={
                            () => this.onRowClick(machine._source.externalId)
                          }
                        >
                          {moment(machine._source.createdAt).format('LL')}
                        </TableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </Grid>
          </>
        )}
      </Translation>
    );
    /* eslint-enable no-underscore-dangle */
  }
}

MachineList.contextType = AuthContext;
export default withRouter(withStyles(styles)(MachineList));
