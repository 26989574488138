import React from 'react';
import {
  WithStyles, withStyles, Theme, createStyles,
} from '@material-ui/core';
import withWidth, { WithWidthProps } from '@material-ui/core/withWidth';
import { ScatterplotLayer } from '@deck.gl/layers';
import DeckGL from 'deck.gl';
import { HeatmapLayer } from '@deck.gl/aggregation-layers';
import { StaticMap } from 'react-map-gl';
import { AuthInterface, AuthContext } from '../../../components/AuthContext';

const styles = (theme: Theme) => createStyles({
  root: {
    width: '100%',
    margin: 20,
  },
  paper: {
    width: '100%',
    height: '100%',
  },
});

interface Props extends WithStyles<typeof styles>, WithWidthProps {
  fileId: string;
  fileName: string;
}

// TODO Fixa en ny token och style kopplad till dasa konto
// Man bör även fixa så denna inte lagras i källkoden.
const MAPBOX_TOKEN = 'pk.eyJ1Ijoiam9uYXN0aG9yc3NvbiIsImEiOiJjazJ3MDhzOHkwOXdtM21xbHM4c2kzMjI3In0.F5-vXezTPO0L4G98535HZw';
const mapStyle = 'mapbox://styles/jonasthorsson/ck2wy341d21lo1cpbbfepmms9/draft';

const INITIAL_VIEW_STATE = {
  latitude: 61,
  longitude: 14,
  zoom: 5,
  maxZoom: 30,
  pitch: 0,
  bearing: 0,
};

interface Stem {
  stemNumber: number;
  harvestDate: string;
  speciesGroupName: string;
  speciesGroupPresentationOrder: number;
  dbh: number;
  m3sob: number;
  m3sub: number;
  numberOflogs: number;
  latitude: number;
  longitude: number;
}

interface State {
  mapData: Stem[];
}

class HprMap extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      mapData: [],
    };
  }

  public componentDidMount() {
    const { api }: AuthInterface = this.context;
    api.getFileGeo<Stem>('/geo/stems.json', this.props.fileId).then((response) => {
      this.setState({ mapData: response });
    });
  }

  public renderLayers() {
    const { mapData } = this.state;
    return [
      new HeatmapLayer<Stem>({
        id: 'heatmap-layer',
        data: mapData,
        pickable: false,
        getPosition: d => [d.longitude, d.latitude],
        radiusPixels: 60,
        intensity: 2,
        threshold: 0.03,
      }),
      new ScatterplotLayer<Stem>({
        id: 'scatter-plot',
        data: mapData,
        radiusScale: 1,
        radiusMinPixels: 0.5,
        getRadius: 0.4,
        getPosition: d => [d.longitude, d.latitude],
      }),
    ];
  }

  public render() {
    return (
      <div style={{ position: 'relative', height: 1080 }}>
        <DeckGL
          layers={this.renderLayers()}
          initialViewState={INITIAL_VIEW_STATE}
          controller
          width="100%"
          height={1080}
        >
          <StaticMap
            reuseMaps
            mapStyle={mapStyle}
            preventStyleDiffing
            mapboxApiAccessToken={MAPBOX_TOKEN}
            width="100%"
            height={1080}
          />
        </DeckGL>
      </div>
    );
  }
}
HprMap.contextType = AuthContext;

export default withWidth()(withStyles(styles)(HprMap));
