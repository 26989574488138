import React from 'react';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import {
  Grid,
  Theme,
  createStyles,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import './App.css';
import Routes from './components/Routes';
import { customTheme } from './components/Theme';
import { AuthProvider } from './components/AuthContext';
import Navigation from './components/Navigation';
import Footer from './components/Footer';


const styles = (theme: Theme) => createStyles({
  container: {
    maxWidth: 1600,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  content: {
    minHeight: '100%',
    marginBottom: -100,
  },
  push: {
    height: 100,
  },
});


interface Props extends WithStyles<typeof styles> {}

const App = (props: Props) => (
  <MuiThemeProvider theme={customTheme}>
    <Router>
      <AuthProvider>
        <div className={props.classes.content}>
          <Navigation />
          <Grid container className={props.classes.container} direction="row" justify="center" alignItems="center">
            <Routes />
          </Grid>
          <div className={props.classes.push} />
        </div>
        <Footer />
      </AuthProvider>
    </Router>
  </MuiThemeProvider>
);

export default withStyles(styles)(App);
