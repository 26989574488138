import {
  createStyles, Divider, Grid, Paper, Table, TableBody, TableCell, TableRow,
  Theme, WithStyles, withStyles,
} from '@material-ui/core';
import withWidth, { WithWidthProps } from '@material-ui/core/withWidth';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import CustomIcons from '../../util/customIcons';
import KpiPanels from './KpiPanels';

const styles = (theme: Theme) => createStyles({
  card: {
    height: '100%',
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  cardContent: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  paper: {
    padding: theme.spacing(2),
  },
  tableTextBold: {
    fontWeight: 'bold',
  },
  [theme.breakpoints.down('xs')]: {
    tableTextBold: {
      fontSize: 10,
    },
    tableText: {
      fontSize: 10,
    },
    cardContent: {
      padding: 0,
      '&:last-child': {
        paddingBottom: 0,
      },
    },
  },
});

const TableCellSmall = withStyles((theme: Theme) => createStyles({
  body: {
    paddingRight: 0,
  },
}))(TableCell);

interface DashHeadData {
  subSum: number;
  stems: number;
  logs: number;
  fileInfo: {
    machineId: string;
    machineOwner: string;
    objectName: string;
    objectId: string;
    OrderNr: string;
    fileName: string;
  };
}

interface Props extends WithStyles<typeof styles>, WithWidthProps, WithTranslation {
  data: DashHeadData;
  volUnit: string;
  loading: boolean;
}

export const DashHead = (props: Props) => {
  const {
    classes, data, volUnit, width, t,
  } = props;

  const currentWidth = width || 'xs';
  const isMobile = (currentWidth === 'xs');

  const kpiData = [{
    val: (props.data.subSum ? props.data.subSum.toFixed(0) : '') + volUnit,
    change: 0,
    useChange: false,
    label: t('components:kpi.totalVolume'),
    iconPath: CustomIcons.volIconPath,
  }, {
    val: (props.data.subSum && props.data.stems ? (props.data.subSum / props.data.stems).toFixed(2) : '') + volUnit,
    change: 0,
    useChange: false,
    label: t('components:kpi.averageStemVol'),
    iconPath: CustomIcons.avgIconPath,
  }, {
    val: data.stems.toFixed(0),
    change: 0,
    useChange: false,
    label: t('components:kpi.totalStems'),
    iconPath: CustomIcons.stemsPath,
  }, {
    val: data.logs.toFixed(0),
    change: 0,
    useChange: false,
    label: t('components:kpi.totalLogs'),
    iconPath: CustomIcons.logsPath,
  }];

  return (
    <React.Fragment>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Paper style={{ height: '100%', overflow: 'auto' }}>
          <div className={classes.paper}>
            <Typography gutterBottom align="center" variant={isMobile ? 'h6' : 'h4'}>
              {data.fileInfo.fileName}
              {' '}
            </Typography>
            <Divider />
            <Table size="small" padding={isMobile ? 'none' : 'default'}>
              <TableBody>
                <TableRow>
                  <TableCellSmall><Typography variant="subtitle1" className={classes.tableTextBold}>{t('pages:hpr.machineId')}</Typography></TableCellSmall>
                  <TableCellSmall><Typography variant="subtitle1" className={classes.tableText}>{data.fileInfo.machineId}</Typography></TableCellSmall>
                  <TableCellSmall><Typography variant="subtitle1" className={classes.tableTextBold}>{t('pages:hpr.machineOwner')}</Typography></TableCellSmall>
                  <TableCellSmall><Typography variant="subtitle1" className={classes.tableText}>{data.fileInfo.machineOwner}</Typography></TableCellSmall>
                </TableRow>
                <TableRow>
                  <TableCellSmall><Typography variant="subtitle1" className={classes.tableTextBold}>{t('pages:hpr.objectName')}</Typography></TableCellSmall>
                  <TableCellSmall><Typography variant="subtitle1" className={classes.tableText}>{data.fileInfo.objectName}</Typography></TableCellSmall>
                  <TableCellSmall><Typography variant="subtitle1" className={classes.tableTextBold}>{t('pages:hpr.objectId')}</Typography></TableCellSmall>
                  <TableCellSmall><Typography variant="subtitle1" className={classes.tableText}>{data.fileInfo.objectId}</Typography></TableCellSmall>
                </TableRow>
                <TableRow>
                  <TableCellSmall><Typography variant="subtitle1" className={classes.tableTextBold}>{t('pages:hpr.orderNr')}</Typography></TableCellSmall>
                  <TableCellSmall><Typography variant="subtitle1" className={classes.tableText}>{data.fileInfo.OrderNr}</Typography></TableCellSmall>
                  <TableCellSmall><Typography variant="subtitle1" className={classes.tableTextBold} /></TableCellSmall>
                  <TableCellSmall><Typography variant="subtitle1" className={classes.tableText} /></TableCellSmall>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </Paper>
      </Grid>
      <Grid container item md={12} lg={6}>
        <KpiPanels currentWidth={currentWidth} data={kpiData} loading={props.loading} />
      </Grid>
    </React.Fragment>
  );
};

export default withWidth()(withStyles(styles)(withTranslation()(DashHead)));
