import React from 'react';
import {
  TableRow, TableCell, withStyles, Theme, createStyles,
} from '@material-ui/core';
import { Translation } from 'react-i18next';
import { SpeciesData } from './dataFormatting';

export enum DisplayType {
  Species,
  Product,
}

export const TableCellNormal = withStyles((theme: Theme) => createStyles({
  body: {
    paddingRight: theme.spacing(1),
  },
}))(TableCell);

const TableCellSubHead = withStyles((theme: Theme) => createStyles({
  body: {
    backgroundColor: theme.palette.grey[200],
    borderColor: theme.palette.grey[200],
    fontSize: 14,
    paddingRight: theme.spacing(1),
  },
}))(TableCell);

export const TableSummary = withStyles((theme: Theme) => createStyles({
  body: {
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    borderTopColor: theme.palette.common.black,
    borderBottomStyle: 'hidden',
    fontSize: 14,
    fontWeight: 'bold',
    paddingRight: theme.spacing(1),
  },
}))(TableCell);

export const combineData = (orig: SpeciesData, add: SpeciesData) => {
  const origData = orig;
  origData.speciesGroupName = add.speciesGroupName;
  origData.productName = add.productName;
  origData.operatorName = add.operatorName;
  origData.avgLogDiameter += (Number(add.avgLogDiameter) * Number(add.numberOfLogs));
  origData.avgLogLength += (Number(add.avgLogLength) * Number(add.numberOfLogs));
  origData.m3Sob += Number(add.m3Sob);
  origData.m3Sub += Number(add.m3Sub);
  origData.numberOfStems += Number(add.numberOfStems);
  origData.numberOfLogs += Number(add.numberOfLogs);
  return origData;
};

const getRowHead = (elem: SpeciesData, displayType: DisplayType) => {
  switch (displayType) {
    case DisplayType.Species:
      return elem.operatorName;
    case DisplayType.Product:
      return (elem.productName ? elem.productName : 'Unspecified');
    default:
      return '';
  }
};

const getSubSumHead = (elem: SpeciesData, displayType: DisplayType) => {
  switch (displayType) {
    case DisplayType.Species:
      return elem.speciesGroupName;
    case DisplayType.Product:
      return elem.operatorName;
    default:
      return '';
  }
};

const createTableRow = (subRowsList: JSX.Element[], node: SpeciesData,
  displayType: DisplayType) => {
  subRowsList.push(
    <TableRow key={node.operatorName + node.speciesGroupName + node.productName}>
      <TableCellNormal align="center">{getRowHead(node, displayType)}</TableCellNormal>
      <TableCellNormal align="right">{Number(node.m3Sub).toFixed(2)}</TableCellNormal>
      <TableCellNormal align="right">{Number(node.m3Sob).toFixed(2)}</TableCellNormal>
      {displayType === DisplayType.Species ? <TableCellNormal align="right">{(node.m3Sub / node.numberOfStems).toFixed(2)}</TableCellNormal> : null}
      {displayType === DisplayType.Product ? <TableCellNormal align="right">{(node.m3Sub / node.numberOfLogs).toFixed(2)}</TableCellNormal> : null}
      {displayType === DisplayType.Product ? null : <TableCellNormal align="right">{node.numberOfStems}</TableCellNormal>}
      <TableCellNormal align="right">{node.numberOfLogs}</TableCellNormal>
      <TableCellNormal align="right">{Number(node.avgLogLength).toFixed(0)}</TableCellNormal>
      <TableCellNormal align="right">{Number(node.avgLogDiameter).toFixed(0)}</TableCellNormal>
    </TableRow>,
  );
};

const createTableSubHead = (rowList: JSX.Element[], subSumRow: SpeciesData,
  displayType: DisplayType, compact: boolean) => {
  const Cell = compact ? TableCellNormal : TableCellSubHead;
  rowList.push(
    <TableRow key={`head${getSubSumHead(subSumRow, displayType)}`}>
      <Cell component="th" scope="row">
        {getSubSumHead(subSumRow, displayType)}
      </Cell>
      <Cell align="right">{subSumRow.m3Sub.toFixed(2)}</Cell>
      <Cell align="right">{subSumRow.m3Sob.toFixed(2)}</Cell>
      {displayType === DisplayType.Species ? <Cell align="right">{(subSumRow.m3Sub / subSumRow.numberOfStems).toFixed(2)}</Cell> : null}
      {displayType === DisplayType.Product ? <Cell align="right">{(subSumRow.m3Sub / subSumRow.numberOfLogs).toFixed(2)}</Cell> : null}
      {displayType === DisplayType.Product ? null : <Cell align="right">{subSumRow.numberOfStems}</Cell>}
      <Cell align="right">{subSumRow.numberOfLogs}</Cell>
      <Cell align="right">{(subSumRow.avgLogLength).toFixed(0)}</Cell>
      <Cell align="right">{subSumRow.avgLogDiameter.toFixed(0)}</Cell>
    </TableRow>,
  );
};

export const dataToRows = (dataMap: Map<string, SpeciesData[]>, displayType: DisplayType,
  compact: boolean) => {
  const rowList: JSX.Element[] = [];

  let sumRow: SpeciesData = {
    speciesGroupName: '', speciesIdentity: '', productName: '', avgLogLength: 0, avgLogDiameter: 0, m3Sob: 0, m3Sub: 0, numberOfLogs: 0, numberOfStems: 0, operatorName: '',
  };
  dataMap.forEach((element) => {
    let subSumRow: SpeciesData = {
      speciesGroupName: '', speciesIdentity: '', productName: '', avgLogLength: 0, avgLogDiameter: 0, m3Sob: 0, m3Sub: 0, numberOfLogs: 0, numberOfStems: 0, operatorName: '',
    };
    const subRowsList: JSX.Element[] = [];
    element.forEach((node) => {
      subSumRow = combineData(subSumRow, node);
      if (!compact) createTableRow(subRowsList, node, displayType);
    });
    subSumRow.avgLogLength /= subSumRow.numberOfLogs;
    subSumRow.avgLogDiameter /= subSumRow.numberOfLogs;
    createTableSubHead(rowList, subSumRow, displayType, compact);
    subRowsList.forEach((node) => {
      rowList.push(node);
    });
    sumRow = combineData(sumRow, subSumRow);
  });
  if (dataMap.size > 1) {
    sumRow.avgLogLength /= sumRow.numberOfLogs;
    sumRow.avgLogDiameter /= sumRow.numberOfLogs;
    rowList.push(
      <Translation key="Sum">
        {t => (
          <TableRow>
            <TableSummary component="th" scope="row">
              {t('components:chart.summary')}
            </TableSummary>
            <TableSummary align="right">{sumRow.m3Sub.toFixed(2)}</TableSummary>
            <TableSummary align="right">{sumRow.m3Sob.toFixed(2)}</TableSummary>
            {displayType === DisplayType.Species ? <TableSummary align="right">{(sumRow.m3Sub / sumRow.numberOfStems).toFixed(2)}</TableSummary> : null}
            {displayType === DisplayType.Product ? <TableSummary align="right">{(sumRow.m3Sub / sumRow.numberOfLogs).toFixed(2)}</TableSummary> : null}
            {displayType === DisplayType.Product ? null : <TableSummary align="right">{sumRow.numberOfStems}</TableSummary>}
            <TableSummary align="right">{sumRow.numberOfLogs}</TableSummary>
            <TableSummary align="right">{sumRow.avgLogLength.toFixed(0)}</TableSummary>
            <TableSummary align="right">{sumRow.avgLogDiameter.toFixed(0)}</TableSummary>
          </TableRow>
        )}
      </Translation>,
    );
  }
  return rowList;
};
