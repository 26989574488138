import React from 'react';
import { Translation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  createStyles, Theme, WithStyles, Grid, Snackbar, IconButton, SnackbarContent,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import FileList from '../../components/FileList';
import FileUpload from '../../components/FileUpload';
import { AuthContext, AuthInterface } from '../../components/AuthContext';
import Loading from '../../components/Loading';
import { FileTaggingResponse } from '../../api/API';

export interface SFFile {
  ETag: string;
  Key: string;
  LastModified: string;
  Size: number;
  gbqtableId: string;
}

const styles = (theme: Theme) => createStyles({
  root: {
    marginTop: theme.spacing(3),
    alignItems: 'center',
    justify: 'center',
    width: '100%',
  },
  [theme.breakpoints.up('md')]: {
    root: {
      margin: 30,
    },
  },
  titleHeader: {
    backgroundColor: theme.palette.primary.light,
    margin: 0,
    marginBottom: 0,
    paddingTop: 5,
    paddingBottom: 5,
    width: '100%',
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: '#1976d2',
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },


});

interface Props extends RouteComponentProps, WithStyles<typeof styles> {}

interface PollObject {
  filename: string;
  uploaded: boolean;
  tries: number;
}

export type snackbarVariant = 'success' | 'error' | 'info' | 'warning';

interface SnackbarMessage {
  snackbarText: string;
  snackbarType: snackbarVariant;
}
interface State {
  windowWidth: number;
  data: SFFile[];
  isLoading: boolean;
  snackbarOpen: boolean;
  snackbarText: string;
  snackbarType: snackbarVariant;
}

class Files extends React.Component<Props, State> {
  private variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
  };

  public constructor(props: Props) {
    super(props);
    this.timer = undefined;
    this.filesIsMouted = false;
    this.onResize = this.onResize.bind(this);
    this.pollObjects = [];
    this.messageQueue = {
      snackbarText: '',
      snackbarType: 'info',
    };
    this.state = {
      windowWidth: window.innerWidth,
      data: [],
      isLoading: true,
      snackbarOpen: false,
      snackbarText: '',
      snackbarType: 'info',
    };
  }

  public componentWillMount() {
    this.setState({ windowWidth: window.innerWidth });
    window.addEventListener('resize', this.onResize);
  }

  public componentDidMount() {
    const po = sessionStorage.getItem('PollObjects');
    if (po !== null) {
      this.pollObjects = JSON.parse(po);
    }
    const lc = sessionStorage.getItem('FilesState');
    if (lc !== null) {
      const savedState = JSON.parse(lc);
      this.setState({
        data: savedState.data,
        isLoading: savedState.isLoading,
      }, () => {
        this.getFileList();
      });
    } else {
      this.getFileList();
    }
    this.filesIsMouted = true;
  }

  public componentWillUnmount() {
    const { isAuth }: AuthInterface = this.context;
    this.filesIsMouted = false;
    window.removeEventListener('resize', this.onResize);
    window.clearTimeout(this.timer);
    if (isAuth()) {
      sessionStorage.setItem('FilesState', JSON.stringify(this.state));
      sessionStorage.setItem('PollObjects', JSON.stringify(this.pollObjects));
    }
  }

  private onResize() {
    this.setState({ windowWidth: window.innerWidth });
  }

  private getFileList() {
    const { api }: AuthInterface = this.context;
    api.getFileList()
      .then((fileList) => {
        fileList.map(file => localStorage.setItem(file.Key, file.gbqtableId));
        this.setState({ data: fileList, isLoading: false }, () => this.verifyFileList());
      })
      .catch();
  }

  private verifyFileList = () => {
    this.state.data.forEach((file) => {
      if (file.gbqtableId === 'undefined') {
        if ((this.pollObjects.find(({ filename }) => filename === file.Key) === undefined)) {
          this.updateFileList([{
            filename: file.Key,
            gbqtableId: 'NA',
          }]);
        }
      }
    });
    this.setPollTimer();
  }

  private setPollTimer = (timeout?: number) => {
    clearTimeout(this.timer);
    if (this.filesIsMouted) {
      const times = [3000, 5000, 15000, 25000, 45000];
      const time = timeout && timeout <= 4 ? times[timeout] : times[0];
      if (this.pollObjects.length !== 0) {
        this.timer = window.setTimeout(() => {
          this.updatePollObjects();
        },
        time);
      }
    }
  }

  private updatePollObjects = async () => {
    const { api }: AuthInterface = this.context;
    const updatedPollObjects: FileTaggingResponse[] = [];
    let minTries = 5;
    const promises = this.pollObjects.map(async (po) => {
      const sfFile = this.state.data.find(file => file.Key === po.filename);
      if (sfFile === undefined || sfFile.gbqtableId !== 'undefined') {
        const index = this.pollObjects.indexOf(po);
        this.pollObjects.splice(index, 1);
      } else {
        await api.getFileTagging(po.filename)
          .then((res) => {
            if (res.gbqtableId !== 'undefined') {
              const index = this.pollObjects.indexOf(po);
              this.pollObjects.splice(index, 1);
              updatedPollObjects.push(res);
            } else if (po.tries >= 4) {
              const index = this.pollObjects.indexOf(po);
              this.pollObjects.splice(index, 1);
              res.gbqtableId = 'NA';
              updatedPollObjects.push(res);
            } else {
              const index = this.pollObjects.indexOf(po);
              this.pollObjects[index].tries = po.tries + 1;
            }
          })
          .catch(() => {
            const index = this.pollObjects.indexOf(po);
            this.pollObjects.splice(index, 1);
            const NAFileTagging: FileTaggingResponse = {
              filename: po.filename,
              gbqtableId: 'NA',
            };
            updatedPollObjects.push(NAFileTagging);
          });
      }

      if (po.tries < minTries) {
        minTries = po.tries;
      }
    });

    await Promise.all(promises);

    this.updateFileList(updatedPollObjects);
    this.setPollTimer(minTries);
  }

  private updateFileList = (updatedPollObjects: FileTaggingResponse[]) => {
    if (updatedPollObjects.length !== 0) {
      const tempFilelist = this.state.data;
      const promises = updatedPollObjects.map(async (uo) => {
        const file: SFFile | undefined = (tempFilelist.find(({ Key }) => Key === uo.filename));
        if (file !== undefined) {
          file.gbqtableId = uo.gbqtableId;
          localStorage.setItem(file.Key, file.gbqtableId);
        }
      });
      Promise.all(promises).then(() => {
        this.setState({
          data: tempFilelist,
        });
      });
    }
  }


  public reloadFileList = (filename?: string) => {
    if (filename !== undefined) {
      const po: PollObject = {
        filename,
        uploaded: true,
        tries: 0,
      };
      this.pollObjects.push(po);
    }
    this.getFileList();
  }

  public deleteFileInList = (filename: string) => {
    this.setState((prevState) => {
      const filtered = [...prevState.data.filter(file => file.Key !== filename)];
      localStorage.removeItem(filename);
      return { data: filtered };
    });
  }

  private closeSnackbar = () => {
    this.setState({
      snackbarOpen: false,
    });
  }

  private onExited = () => {
    if (this.messageQueue.snackbarText !== '') {
      this.openSnackbar(this.messageQueue.snackbarText, this.messageQueue.snackbarType);
    }
  }

  public openSnackbar = (snackbarText: string, snackbarType: snackbarVariant = 'info') => {
    if (this.state.snackbarOpen) {
      this.setState({
        snackbarOpen: false,
      });
      this.messageQueue = {
        snackbarText,
        snackbarType,
      };
    } else {
      this.setState({
        snackbarOpen: true,
        snackbarText,
        snackbarType,
      });
      this.messageQueue = {
        snackbarText: '',
        snackbarType: 'info',
      };
    }
  }

  private timer: number | undefined;

  private pollObjects: PollObject[];

  private filesIsMouted: boolean;

  private messageQueue: SnackbarMessage;

  public render() {
    const { classes } = this.props;
    const isMobile = (this.state.windowWidth < 700);
    const Icon = this.variantIcon[this.state.snackbarType];
    return (
      <Translation>
        {t => (
          <main className={classes.root}>
            <Grid container spacing={3} justify="center" className={classes.titleHeader}>
              <Typography variant="h4">
                {t('components:navigation.files')}
              </Typography>
            </Grid>
            {this.state.isLoading
              ? (
                <Grid container justify="center">
                  <Loading />
                </Grid>
              )
              : (
                <>
                  {isMobile
                    ? (
                      <>
                        <Grid container justify="center" alignItems="flex-start">
                          <Grid item xs={10}>
                            <FileUpload
                              files={this.state.data}
                              triggerParentUpdate={this.reloadFileList}
                              setNotification={this.openSnackbar}
                            />
                          </Grid>
                        </Grid>
                        <Grid container alignItems="flex-start">
                          <FileList
                            files={this.state.data}
                            triggerParentUpdate={this.reloadFileList}
                            setNotification={this.openSnackbar}
                          />
                        </Grid>
                      </>
                    )
                    : (
                      <Grid container alignItems="flex-start">
                        <Grid item xs={10}>
                          <FileList
                            files={this.state.data}
                            triggerParentUpdate={this.deleteFileInList}
                            setNotification={this.openSnackbar}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <FileUpload
                            files={this.state.data}
                            triggerParentUpdate={this.reloadFileList}
                            setNotification={this.openSnackbar}
                          />
                        </Grid>
                      </Grid>
                    )
                  }
                </>
              )
            }

            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              open={this.state.snackbarOpen}
              autoHideDuration={6000}
              onClose={this.closeSnackbar}
              onExited={this.onExited}
            >
              <SnackbarContent
                className={clsx(classes[this.state.snackbarType])}
                aria-describedby="client-snackbar"
                message={(
                  <span id="client-snackbar" className={classes.message}>
                    <Icon className={clsx(classes.icon, classes.iconVariant)} />
                    {this.state.snackbarText}
                  </span>
                )}
                action={[
                  <IconButton key="close" aria-label="close" color="inherit" onClick={this.closeSnackbar}>
                    <CloseIcon className={classes.icon} />
                  </IconButton>,
                ]}
              />
            </Snackbar>
          </main>

        )}
      </Translation>
    );
  }
}
Files.contextType = AuthContext;
export default withStyles(styles)(Files);
