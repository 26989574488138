import {
  Theme, Grid, createStyles, WithStyles, withStyles,
} from '@material-ui/core';
import withWidth, { WithWidthProps, isWidthDown } from '@material-ui/core/withWidth';
import React from 'react';
import axios, { CancelTokenSource } from 'axios';
import { GridSpacing } from '@material-ui/core/Grid';
import { withTranslation, WithTranslation } from 'react-i18next';
import moment from 'moment';
import { AuthContext, AuthInterface } from '../../../components/AuthContext';
import {
  parseMultipleValues, createChartData, FormattedMachineData, createOptionsCustom,
} from '../../../util/dataFormatting';
import { createConvFunctions, ChartUnitOptions } from '../../../util/conversionUtil';
import BarChart from '../../../components/Charts/BarChart';
import KpiPanels from '../../../components/Charts/KpiPanels';
import CustomIcons from '../../../util/customIcons';
import { ColorPicker } from '../../../util/colors';
import ChartPaper from '../../../components/Charts/ChartPaper';
import CustomChart from '../../../components/Charts/CustomChart';
import ChartExportButton from '../../../components/Charts/ChartExportButton';

const styles = (theme: Theme) => createStyles({
  timeChart: {
    width: '100%',
    paddingTop: 8,
  },
  root: {
    width: '100%',
    margin: 20,
  },
});

/*
Options for the conversion of values.
convList, which is optional, contatins the unit conversions,
  or null if no conversion for the value is needed.
valueLabelList containes the unit labels to be shown in the chars and tables.
*/
const convOpt: { [index: string]: ChartUnitOptions } = {};
convOpt.metric = { valueLabelList: ['', '', 'm\xB3', '', 'L', '%'] };
convOpt.imperial = {
  convList: [
    null,
    null,
    { from: 'm3', to: 'ft3' },
    { from: 'l', to: 'gal' },
    null,
  ],
  valueLabelList: ['', '', 'ft\xB3', '', 'gal', '%'],
};

const emptyData: {
  labels: string[]; datasets: Chart.ChartDataSets[];
} = { labels: [], datasets: [] };
const emptyDataList = [emptyData, emptyData, emptyData, emptyData, emptyData, emptyData, emptyData];

interface Props extends WithStyles<typeof styles>, WithWidthProps, WithTranslation {
  machineName: string;
  machineId: string;
  dateRange: string;
  rendered: boolean;
}

interface State {
  isLoading: boolean;
  cancelToken: CancelTokenSource;
  conversionOption: ChartUnitOptions;
  summaryValues: number[];
  chartDataList: {
    labels: string[]; datasets: Chart.ChartDataSets[];
  }[];
}

class MachineMonitoring extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: true,
      cancelToken: axios.CancelToken.source(),
      conversionOption: convOpt.metric,
      summaryValues: [0, 0, 0, 0, 0, 0],
      chartDataList: emptyDataList,
    };

    this.retrieveData = this.retrieveData.bind(this);
  }

  public componentDidMount() {
    this.retrieveData();
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.dateRange !== prevProps.dateRange) {
      this.retrieveData();
    }
  }

  public componentWillUnmount() {
    this.state.cancelToken.cancel();
  }

  private retrieveData = () => {
    const { api, user }: AuthInterface = this.context;
    const { t } = this.props;

    let { conversionOption } = this.state;
    conversionOption = convOpt[user.UnitType];
    const options = createConvFunctions(conversionOption);
    options[4].average = true; // Fuel usage

    this.state.cancelToken.cancel();
    this.setState({ isLoading: true, cancelToken: axios.CancelToken.source() }, () => {
      api.getOneMachine<FormattedMachineData>('/monitor/formatted', this.props.machineId, this.props.dateRange,
        this.state.cancelToken, 'day').then((response) => {
        if (response.length === 0) {
          this.setState({
            isLoading: false,
            summaryValues: [0, 0, 0, 0, 0, 0],
            chartDataList: emptyDataList,
          });
        } else {
          const parsed = parseMultipleValues(response.map(({
            startTime: time,
            operator: label,
            et: value1,
            downtime: value2,
            summary: value3,
            numberOfStems: value4,
            fuel: value5,
            tu: value6,
          }) => ({ time: moment(time).format('M/D/YY'), label, value: [value1, value2, value3, value4, value5, value6] })), options, this.props.dateRange);
          const charts = createChartData(6, parsed.labels, parsed.operatorList, parsed.dataMap);

          let workdata: number[] = new Array(parsed.labels.length).fill(0);
          let downdata: number[] = new Array(parsed.labels.length).fill(0);
          parsed.operatorList.forEach((operator, index) => {
            let valMatrix = parsed.dataMap.get(operator);
            valMatrix = valMatrix || [];
            const workCol = valMatrix.map(value => value[0]);
            const downCol = valMatrix.map(value => value[1]);

            workdata = workdata.map((val, i) => (val + workCol[i]));
            downdata = downdata.map((val, i) => (val + downCol[i]));
          });
          const timeChart = {
            labels: parsed.labels,
            datasets: [{
              type: 'line',
              stack: 'stack1',
              label: t('components:chart.monitoring.downtime'),
              lineTension: 0,
              backgroundColor: ColorPicker.complementFaded[3],
              borderColor: ColorPicker.complement[3],
              borderWidth: 1,
              data: downdata,
            }, {
              type: 'line',
              stack: 'stack1',
              label: t('components:chart.monitoring.work'),
              fill: '-1',
              lineTension: 0,
              backgroundColor: ColorPicker.primaryFaded[3],

              borderColor: ColorPicker.primary[3],
              borderWidth: 1,
              data: workdata,
            }] as Chart.ChartDataSets[],
          };

          this.setState({
            isLoading: false,
            summaryValues: parsed.summary,
            chartDataList: [timeChart, ...charts],
          });
        }
      }).catch((e) => {
        if (!axios.isCancel(e)) {
          this.setState({
            isLoading: false,
            summaryValues: [0, 0, 0, 0, 0, 0],
            chartDataList: emptyDataList,
          });
        }
      });
    });
  }


  public render() {
    const { classes, t } = this.props;
    const { conversionOption, summaryValues, chartDataList } = this.state;
    const currentWidth = this.props.width ? this.props.width : 'xs';
    const isMobile = (currentWidth === 'xs');
    const isTabOrMobile = isWidthDown('sm', currentWidth);
    let timeChartHeight = 170;
    let chartHeigth = 250;
    let spacing: GridSpacing = 4;
    if (isMobile) {
      chartHeigth = 200;
      timeChartHeight = 120;
      spacing = 1;
    } else if (isTabOrMobile) {
      chartHeigth = 300;
      timeChartHeight = 170;
      spacing = 2;
    }


    const kpiData = [{
      val: `${summaryValues[2].toFixed(0)}${conversionOption.valueLabelList[2]}`,
      change: 0,
      useChange: false,
      label: t('components:kpi.totalVolume'),
      iconPath: CustomIcons.volIconPath,
    }, {
      val: `${summaryValues[0].toFixed(0)}h`,
      change: 0,
      useChange: false,
      label: t('components:kpi.totalWork'),
      iconPath: CustomIcons.workPath,
    }, {
      val: `${summaryValues[1].toFixed(0)}h`,
      change: 0,
      useChange: false,
      label: t('components:kpi.totalDowntime'),
      iconPath: CustomIcons.downPath,
    }, {
      val: `${summaryValues[4].toFixed(2)}${conversionOption.valueLabelList[4]}`,
      change: 0,
      useChange: false,
      label: `${t('components:kpi.averageFuel')}/${conversionOption.valueLabelList[2]}`,
      iconPath: CustomIcons.fuelPath,
    }];

    const timeChartOptions = createOptionsCustom(
      t('components:time.hours'),
    );
    timeChartOptions.maintainAspectRatio = false;
    timeChartOptions.scales = {
      xAxes: [{ gridLines: { display: false } }],
      yAxes: [{ stacked: true, ticks: { min: 0 }, scaleLabel: { display: true, labelString: t('components:time.hours'), fontSize: 14 } }],
    } as Chart.ChartScales;
    if (timeChartOptions.tooltips) {
      timeChartOptions.tooltips.mode = 'index';
      timeChartOptions.tooltips.intersect = false;
      timeChartOptions.tooltips.filter = item => (item.xLabel !== undefined);
      timeChartOptions.tooltips.callbacks = {
        label: item => `${typeof item.yLabel === 'number' ? Math.floor(item.yLabel) : ''}h ${typeof item.yLabel === 'number' ? ((item.yLabel - Math.floor(item.yLabel)) * 60).toFixed(0) : ''}m`,
      };
    }

    const exportTitles = [t('components:chart.monitoring.monitoring'), t('components:chart.production.volume'), t('components:chart.monitoring.stemsPerHExport'), t('components:chart.monitoring.fuel'), t('components:chart.monitoring.tu')];

    return (
      <>
        {this.state.isLoading ? null
          : (
            <div>
              <ChartExportButton
                barData={[chartDataList[0],
                  chartDataList[3],
                  chartDataList[4],
                  chartDataList[5],
                  chartDataList[6]]}
                filename={`${t('components:chart.monitoring.monitoring')}_${this.props.machineName}`}
                tooltip={t('components:button.downloadAll')}
                titles={exportTitles}
                hidden={false}
              />
            </div>
          )
        }
        <Grid container spacing={spacing} justify="center">
          <Grid item xs={12}>
            <Grid container spacing={spacing} direction={isWidthDown('md', currentWidth) ? 'column-reverse' : 'row'}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <ChartPaper
                  loaded={!this.state.isLoading}
                  datasets={[chartDataList[0]]}
                  isTabOrMobile={isTabOrMobile}
                  name={`${this.props.machineName}_${t('components:chart.monitoring.monitoring')}`}
                  customClasses={classes.timeChart}
                  component={(
                    <CustomChart
                      chartType="Line"
                      width={200}
                      height={timeChartHeight}
                      datasets={chartDataList[0]}
                      options={timeChartOptions}
                      origColor={[ColorPicker.complementFaded[3], ColorPicker.primaryFaded[3]]}
                      dimColor={[ColorPicker.complementDim[3], ColorPicker.primaryDim[3]]}
                      key={timeChartHeight + String(this.props.rendered) + this.state.isLoading}
                    />
                  )}
                />
              </Grid>
              <Grid container item md={12} lg={6}>
                <KpiPanels
                  currentWidth={currentWidth}
                  data={kpiData}
                  loading={this.state.isLoading}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <ChartPaper
              loaded={!this.state.isLoading}
              datasets={[chartDataList[3]]}
              isTabOrMobile={isTabOrMobile}
              name={`${this.props.machineName}_${t('components:chart.production.volume')}`}
              component={
                <BarChart datasets={chartDataList[3]} height={chartHeigth} options={createOptionsCustom(conversionOption.valueLabelList[2], undefined, 1)} title={`${t('components:chart.monitoring.volume')} ${conversionOption.valueLabelList[2]}`} key={chartHeigth.toString() + this.state.isLoading} />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ChartPaper
              loaded={!this.state.isLoading}
              datasets={[chartDataList[4]]}
              isTabOrMobile={isTabOrMobile}
              name={`${this.props.machineName}_${t('components:chart.monitoring.stemsPerHExport')}`}
              component={
                <BarChart datasets={chartDataList[4]} height={chartHeigth} options={createOptionsCustom(conversionOption.valueLabelList[3], undefined, 0)} title={t('components:chart.monitoring.stemsPerH')} key={chartHeigth.toString() + this.state.isLoading} />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ChartPaper
              loaded={!this.state.isLoading}
              datasets={[chartDataList[5]]}
              isTabOrMobile={isTabOrMobile}
              name={`${this.props.machineName}_${t('components:chart.monitoring.fuel')}`}
              component={
                <BarChart datasets={chartDataList[5]} height={chartHeigth} options={createOptionsCustom(conversionOption.valueLabelList[4])} title={`${t('components:chart.monitoring.fuel')} ${conversionOption.valueLabelList[4]}/${conversionOption.valueLabelList[2]}`} key={chartHeigth.toString() + this.state.isLoading} />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ChartPaper
              loaded={!this.state.isLoading}
              datasets={[chartDataList[6]]}
              isTabOrMobile={isTabOrMobile}
              name={`${this.props.machineName}_${t('components:chart.monitoring.tu')}`}
              component={
                <BarChart datasets={chartDataList[6]} height={chartHeigth} options={createOptionsCustom(conversionOption.valueLabelList[5], undefined, 1)} title={`${t('components:chart.monitoring.tu')} %`} key={chartHeigth.toString() + this.state.isLoading} />
              }
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

MachineMonitoring.contextType = AuthContext;
export default withWidth()(withStyles(styles)(withTranslation()(MachineMonitoring)));
