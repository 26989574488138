import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import {
  WithStyles, withStyles, Theme, createStyles, TableHead, TableRow, TableCell, TableBody,
  Typography, Switch, Grid,
} from '@material-ui/core';
import { Translation } from 'react-i18next';
import { SpeciesData } from '../../util/dataFormatting';
import { dataToRows, DisplayType } from '../../util/MapToTable';

const styles = (theme: Theme) => createStyles({
  table: {
    margin: theme.spacing(2),
    marginTop: 0,
    maxHeight: 400 - 38 - 16, // Size of DoughnutChart - header and padding
    overflow: 'auto',
  },
  [theme.breakpoints.down('xs')]: {
    table: {
      margin: theme.spacing(0),
      marginTop: 0,
    },
  },
});

const TableCellHead = withStyles((theme: Theme) => createStyles({
  head: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.paper,
    color: 'black',
    fontSize: 14,
    fontWeight: 'bold',
    paddingRight: theme.spacing(1),
  },
  body: {
    fontSize: 24,
  },
}))(TableCell);

interface Props extends WithStyles<typeof styles> {
  list: Map<string, SpeciesData[]>;
  labels: string[];
}


export const SpeciesTable = (props: Props) => {
  const { classes, labels } = props;
  const [compact, toggleChecked] = useState(true);
  const handleToggle = (event: React.ChangeEvent) => {
    toggleChecked(!compact);
  };
  return (
    <Translation>
      {t => (
        <div className={classes.root}>
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <Typography variant="subtitle2" align="center">
                <Switch checked={!compact} onChange={handleToggle} />
                {t('components:misc.detailed')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h6" align="center">{t('components:chart.production.species')}</Typography>
            </Grid>
            <Grid item xs={4} />
          </Grid>
          <div className={classes.table}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCellHead>{`${t('components:chart.production.species')} / ${t('components:chart.production.operator')}`}</TableCellHead>
                  <TableCellHead align="right">{`${t('components:chart.production.volume')} (${labels[0]}${t('components:chart.production.sub')})`}</TableCellHead>
                  <TableCellHead align="right">{`${t('components:chart.production.volume')} (${labels[1]}${t('components:chart.production.sob')})`}</TableCellHead>
                  <TableCellHead align="right">{`${t('components:chart.production.avgStem')} (${labels[0]}${t('components:chart.production.sub')})`}</TableCellHead>
                  <TableCellHead align="right">{`${t('components:chart.production.stems')} ${labels[2]}`}</TableCellHead>
                  <TableCellHead align="right">{`${t('components:chart.production.logs')} ${labels[3]}`}</TableCellHead>
                  <TableCellHead align="right">{`${t('components:chart.production.avgLen')} (${labels[4]})`}</TableCellHead>
                  <TableCellHead align="right">{`${t('components:chart.production.avgDiam')} (${labels[5]})`}</TableCellHead>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataToRows(props.list, DisplayType.Species, compact)}
              </TableBody>
            </Table>
          </div>
        </div>
      )}
    </Translation>
  );
};

export default withStyles(styles)(SpeciesTable);
