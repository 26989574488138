import React from 'react';
import { useTranslation } from 'react-i18next';

const Report = () => {
  const { t } = useTranslation();
  return (
    <p>{t('report.title')}</p>
  );
};

export default Report;
