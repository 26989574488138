import {
  createStyles, Grid, Paper, Tab, Tabs, Theme, WithStyles, withStyles, Typography,
} from '@material-ui/core';
import React from 'react';
import { Translation } from 'react-i18next';
import { AuthContext, AuthInterface } from '../../../components/AuthContext';
import DrfOverview from './DrfOverview';
import { ChartUnitOptions } from '../../../util/conversionUtil';

const styles = (theme: Theme) => createStyles({
  root: {
    width: '100%',
    margin: 20,
  },
  paper: {
    width: '100%',
    height: '100%',
  },
  tab: {
    marginBottom: 10,
  },

});

/*
Options for the conversion of values.
convList, which is optional, contatins the unit conversions,
  or null if no conversion for the value is needed.
valueLabelList containes the unit labels to be shown in the chars and tables.
*/
const convOpt: { [index: string]: ChartUnitOptions } = {};
convOpt.metric = { valueLabelList: ['m\xB3', '', ''] };
convOpt.imperial = {
  convList: [{ from: 'm3', to: 'ft3' }, null, null],
  valueLabelList: ['ft\xB3', '', ''],
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <Typography
      style={{ width: '100%' }}
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </Typography>
  );
}

interface Props extends WithStyles<typeof styles> {
  fileId: string;
  fileName: string;
}

interface State {
  tabValue: number;
}

class DrfPages extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      tabValue: 0,
    };
  }

  private handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ tabValue: newValue });
  };

  public render() {
    const { classes } = this.props;
    const { user }: AuthInterface = this.context;

    const conversionOption: ChartUnitOptions = convOpt[user.UnitType];
    return (
      <Translation>
        {t => (
          <>
            <div className={classes.tab}>
              <Paper square>
                <Tabs value={this.state.tabValue} onChange={this.handleChange}>
                  <Tab label={t('pages:drf.overview')} key="overview" />
                  <Tab label="Tab2" key="tab2" />
                  <Tab label="Tab3" key="tab3" />
                </Tabs>
              </Paper>
            </div>
            <Grid container spacing={0} justify="center">
              <TabPanel value={this.state.tabValue} index={0}>
                <DrfOverview
                  fileId={this.props.fileId}
                  fileName={this.props.fileName}
                  chartUnitOptions={conversionOption}
                />
              </TabPanel>
              <TabPanel value={this.state.tabValue} index={1} />
            </Grid>
          </>
        )}
      </Translation>
    );
  }
}
DrfPages.contextType = AuthContext;

export default withStyles(styles)(DrfPages);
