import React, { useState, useRef } from 'react';
import {
  WithStyles, withStyles, Theme, createStyles, Typography, Box, Grid,
} from '@material-ui/core';
import {
  Bar, ChartData, Line, Doughnut,
} from 'react-chartjs-2';
import { ChartLegendLabelItem } from 'chart.js';

const styles = (theme: Theme) => createStyles({
});

interface Props extends WithStyles<typeof styles> {
  datasets: ChartData<Chart.ChartData>;
  options: Chart.ChartOptions;
  origColor: string[];
  dimColor: string[];
  title?: string;
  numberBoxLegend?: boolean;
  height?: number;
  width?: number;
  key?: string|number;
  chartType: 'Bar'|'Line'|'Doughnut';
}

export const CustomChart = (props: Props) => {
  const inputEl: any = useRef(null); // eslint-disable-line @typescript-eslint/no-explicit-any
  const [legendRendered, setLegendRendered] = useState(false);
  const [, forceUpdate] = useState();
  const [data, changeData] = useState<ChartData<Chart.ChartData>>(props.datasets);
  const plugins = [
    {
      afterDatasetsDraw() {
        if (!legendRendered) {
          setLegendRendered(true);
        }
      },
    },
  ];


  const handleLegendHover = (datasetIndex: number) => {
    const chart = inputEl.current.chartInstance;
    const thisLegend = chart.getDatasetMeta(datasetIndex);
    if (thisLegend.hidden !== true) {
      chart.legend.legendItems.forEach((tick: ChartLegendLabelItem) => {
        if (tick.datasetIndex !== datasetIndex) {
          chart.data.datasets[tick.datasetIndex].backgroundColor = props.dimColor[
            tick.datasetIndex
          ];
        }
      });
      changeData(chart.data);
      chart.update(); // re-draw chart to hide dataset
      forceUpdate({}); // re-draw component to update legend styles
    }
  };

  const handleLegendLeave = (datasetIndex: number) => {
    const chart = inputEl.current.chartInstance;
    chart.legend.legendItems.forEach((tick: ChartLegendLabelItem) => {
      chart.data.datasets[tick.datasetIndex].backgroundColor = props.origColor[tick.datasetIndex];
    });
    changeData(chart.data);
    chart.update(); // re-draw chart to hide dataset
    forceUpdate({}); // re-draw component to update legend styles
  };

  const handleLegendClick = (datasetIndex: number) => {
    const chart = inputEl.current.chartInstance;
    const thisLegend = chart.getDatasetMeta(datasetIndex);
    if (thisLegend.hidden === null || thisLegend.hidden === false) {
      thisLegend.hidden = true;
      handleLegendLeave(datasetIndex);
    } else {
      thisLegend.hidden = false;
      handleLegendHover(datasetIndex);
    }
  };

  let chartType;
  switch (props.chartType) {
    case 'Bar': chartType = (
      <Bar
        ref={inputEl}
        key={props.key}
        data={data}
        width={props.width ? props.width : 500}
        height={props.height ? props.height : 400}
        options={props.options}
        plugins={plugins}
      />
    );
      break;
    case 'Line': chartType = (
      <Line
        ref={inputEl}
        width={200}
        height={props.height ? props.height : 200}
        data={data}
        options={props.options}
        key={props.key}
        plugins={plugins}
      />
    );
      break;
    case 'Doughnut': chartType = (
      <Doughnut
        ref={inputEl}
        data={data}
        width={props.width ? props.width : 600}
        height={props.height ? props.height : 400}
        options={props.options}
        key={props.key}
        plugins={plugins}
      />
    );
      break;
    default: chartType = null;
  }


  return (
    <div>
      {legendRendered && (
        <div
          style={{
            paddingLeft: '10px',
            paddingRight: '10px',
          }}
        >
          <Grid container justify="center">
            { props.title ? (
              <Grid item xs={12}>
                <Typography
                  align="center"
                  style={{
                    padding: 8, fontWeight: 'bold', fontSize: 12, color: '#666', userSelect: 'none',
                  }}
                >
                  {props.title}
                </Typography>
              </Grid>
            ) : null}
            {inputEl.current.chartInstance.legend.legendItems.map((tick: ChartLegendLabelItem) => {
              const chart = inputEl.current.chartInstance;
              const isHidden = chart.getDatasetMeta(tick.datasetIndex).hidden;

              return (
                <Grid
                  item
                  key={tick.datasetIndex}
                  onMouseEnter={() => handleLegendHover(tick.datasetIndex)}
                  onMouseLeave={() => handleLegendLeave(tick.datasetIndex)}
                  onClick={() => handleLegendClick(tick.datasetIndex)}
                >
                  <Grid container alignItems="center" direction="row" wrap="nowrap" style={{ height: '100%', width: '100%' }}>
                    <Grid item>
                      {props.numberBoxLegend === true ? (
                        <Box style={{
                          marginRight: 4, textAlign: 'center', width: 25, height: 25, color: 'white', backgroundColor: props.origColor[tick.datasetIndex], lineHeight: '25px',
                        }}
                        >
                          {tick.datasetIndex + 1}
                        </Box>
                      ) : (
                        <Box style={{
                          marginRight: 4, textAlign: 'center', width: 35, height: 12, color: 'white', backgroundColor: props.origColor[tick.datasetIndex],
                        }}
                        />
                      )}
                    </Grid>
                    <Grid item>
                      <Typography style={{
                        marginRight: 8, fontSize: 12, color: '#666', userSelect: 'none', textDecoration: isHidden ? 'line-through' : 'none',
                      }}
                      >
                        {tick.text}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </div>
      )}
      <div>
        {chartType}
      </div>
    </div>
  );
};

export default withStyles(styles)(CustomChart);
