export const ColorPicker = { // Base #38A33F, Hue: 181, Dist: 40,
  primary: [
    'rgba(199,224,200,1)',
    'rgba(124,202,129,1)',
    'rgba( 56,163, 63,1)',
    'rgba( 18,134, 26,1)',
    'rgba(  0, 97,  6,1)',
  ],
  primaryFaded: [
    'rgba(199,224,200,0.5)',
    'rgba(124,202,129,0.5)',
    'rgba( 56,163, 63,0.5)',
    'rgba( 18,134, 26,0.5)',
    'rgba(  0, 97,  6,0.5)',
  ],
  primaryDim: [
    'rgba(199,224,200,0.1)',
    'rgba(124,202,129,0.1)',
    'rgba( 56,163, 63,0.1)',
    'rgba( 18,134, 26,0.1)',
    'rgba(  0, 97,  6,0.1)',
  ],
  secondary1: [
    'rgba(180,194,202,1)',
    'rgba(104,143,164,1)',
    'rgba( 49,103,132,1)',
    'rgba( 19, 77,108,1)',
    'rgba(  3, 52, 79,1)',
  ],
  complement: [
    'rgba(255,227,227,1)',
    'rgba(255,160,158,1)',
    'rgba(207, 75, 71,1)',
    'rgba(170, 27, 23,1)',
    'rgba(124,  3,  0,1)',
  ],
  complementFaded: [
    'rgba(255,227,227,0.5)',
    'rgba(255,160,158,0.5)',
    'rgba(207, 75, 71,0.5)',
    'rgba(170, 27, 23,0.5)',
    'rgba(124,  3,  0,0.5)',
  ],
  complementDim: [
    'rgba(255,227,227,0.1)',
    'rgba(255,160,158,0.1)',
    'rgba(207, 75, 71,0.1)',
    'rgba(170, 27, 23,0.1)',
    'rgba(124,  3,  0,0.1)',
  ],
  chartPallet: {
    normal: [
      'rgba(138,173,61,1)',
      'rgba(173,61,138,1)',
      'rgba(173,78,61,1)',
      'rgba(61,173,132,1)',
      'rgba(138,173,61,1)',
      'rgba(173,61,138,1)',
      'rgba(173,78,61,1)',
      'rgba(61,173,132,1)'],
    dim: [
      'rgba(138,173,61,0.1)',
      'rgba(173,61,138,0.1)',
      'rgba(173,78,61,0.1)',
      'rgba(61,173,132,0.1)',
      'rgba(138,173,61,0.1)',
      'rgba(173,61,138,0.1)',
      'rgba(173,78,61,0.1)',
      'rgba(61,173,132,0.1)'],
  },
};

export const ColorList = [
  'rgba(  0, 80,  5,1)',
  'rgba( 20,129, 27,1)',
  'rgba( 56,163, 63,1)',
  'rgba(117,203,123,1)',
  'rgba(102,139,169,1)',
  'rgba( 52, 97,134,1)',
  'rgba( 23, 68,106,1)',
  'rgba(  3, 37, 65,1)',
  'rgba(101,  3,  0,1)',
  'rgba(164, 29, 26,1)',
  'rgba(207, 75, 71,1)',
  'rgba(255,150,147,1)',
];

export const ColorListDim = [
  'rgba(  0, 80,  5, 0.1)',
  'rgba( 20,129, 27, 0.1)',
  'rgba( 56,163, 63, 0.1)',
  'rgba(117,203,123, 0.1)',
  'rgba(  3, 37, 65, 0.1)',
  'rgba( 23, 68,106, 0.1)',
  'rgba( 52, 97,134, 0.1)',
  'rgba(102,139,169, 0.1)',
  'rgba(101,  3,  0, 0.1)',
  'rgba(164, 29, 26, 0.1)',
  'rgba(207, 75, 71, 0.1)',
  'rgba(255,150,147, 0.1)',
];
