import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/sv';
import 'moment/locale/de';

moment.locale('en', { // TODO: fix locale for moment
  week: {
    dow: 1,
    doy: 1,
  },
});

i18n
  .use(XHR)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: 'en',
    fallbackLng: ['en', 'sv', 'de'],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    ns: [
      'components',
      'pages',
    ],
    defaultNS: 'pages',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  });

export default i18n;
