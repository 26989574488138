import React from 'react';
import {
  WithStyles, withStyles, Theme, createStyles, Tooltip, Box,
} from '@material-ui/core';
import { HorizontalBar } from 'react-chartjs-2';
import { Translation } from 'react-i18next';
import { ColorPicker } from '../../util/colors';

const styles = (theme: Theme) => createStyles({
  tooltip: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    fontSize: '14px',
  },
});

interface Props extends WithStyles<typeof styles> {
  data: number[];
  options?: Chart.ChartOptions;
  name: string;
  backgrundColor: string;
  width?: number;
  height?: number;

}

export const ProgressChart = (props: Props) => {
  const { classes } = props;

  return (
    <Translation>
      {t => (
        <Tooltip
          placement="top"
          title={(
            <div>
              {`E(t): ${Math.floor(props.data[0])}h ${((props.data[0] - Math.floor(props.data[0])) * 60).toFixed(0)}m`}
              <br />
              {`${t('components:chart.monitoring.downtime')}: ${Math.floor(props.data[1])}h ${((props.data[1] - Math.floor(props.data[1])) * 60).toFixed(0)}m`}
            </div>
          )}
          classes={{ tooltip: classes.tooltip }}
        >
          <Box>
            <HorizontalBar
              data={{
                labels: ['Work'],
                datasets: [{
                  label: 'Work',
                  backgroundColor: [ColorPicker.primary[3]],
                  data: [props.data[0]],
                },
                {
                  label: 'Blocked',
                  backgroundColor: [ColorPicker.complement[3]],
                  data: [props.data[1]],
                }, {
                  label: 'idle',
                  backgroundColor: [props.backgrundColor],
                  data: [props.data[2]],
                }],
              }}
              width={props.width ? props.width : 150}
              height={props.height ? props.height : 50}
              options={
                {
                  tooltips: {
                    enabled: false,
                  },
                  maintainAspectRatio: false,
                  legend: {
                    display: false,
                  },
                  scales: {
                    xAxes: [{
                      ticks: {
                        min: 0,
                        max: props.data.reduce((total, num) => total + num),
                      },
                      display: false,
                      stacked: true,
                    }],
                    yAxes: [{
                      display: false,
                      stacked: true,
                    }],
                  },
                }
              }
            />
          </Box>
        </Tooltip>
      )}
    </Translation>
  );
};

export default withStyles(styles)(ProgressChart);
