import Convert from 'convert-units';
import i18next from 'i18next';
import { ParsingOptions, SpeciesData } from './dataFormatting';

export interface ChartUnitOptions {
  convList?: ({ from: string; to: string } | null)[];
  valueLabelList: string[];
}

export const ConversionTypes = ['metric', 'imperial'];


export const displayUnitTypes = (convType: string) => {
  switch (convType) {
    case 'metric': return i18next.t('components:conversion.metric');
    case 'imperial': return i18next.t('components:conversion.imperial');
    default: return i18next.t('components:conversion.metric');
  }
};

// Converts the options to conversion-functions which are used in the parsing
export const createConvFunctions = (chartUnitOptions: ChartUnitOptions) => {
  const options: ParsingOptions[] = new Array(chartUnitOptions.valueLabelList.length).fill(null)
    .map(() => ({ doConvert: false, average: false, converter: (num: number) => num }));

  if (chartUnitOptions.convList) {
    chartUnitOptions.convList.forEach((convParams, index) => {
      options[index] = (convParams ? {
        doConvert: true,
        converter: (num: number) => Convert(num).from(convParams.from).to(convParams.to),
      } : { doConvert: false, average: false, converter: (num: number) => num });
    });
  }
  return options;
};

// Converts the values of a table in the hpr page
export const convertHprTable = (data: SpeciesData[], options: ParsingOptions[]) => {
  data.map((element) => {
    const elem = element;
    elem.m3Sub = options[0].converter(elem.m3Sub);
    elem.m3Sob = options[1].converter(elem.m3Sob);
    elem.numberOfStems = options[2].converter(elem.numberOfStems);
    elem.numberOfLogs = options[3].converter(elem.numberOfLogs);
    elem.avgLogLength = options[4].converter(elem.avgLogLength);
    elem.avgLogDiameter = options[5].converter(elem.avgLogDiameter);
    return elem;
  });
};
