import React from 'react';
import {
  withStyles,
  WithStyles,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import dasa from '../helpers/dasa.svg';
import env from '../helpers/env';

const styles = (theme: Theme) => createStyles({
  root: {
    backgroundColor: theme.palette.primary.light,
    height: 100,
  },
  container: {
    maxWidth: 1600,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  logo: {
    height: 31,
    width: 252,
  },
  logoContainer: {
    paddingTop: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
  },
  buildContainer: {
    paddingTop: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    fontSize: 12,
    color: theme.palette.grey[500],
  },
});

interface Props extends WithStyles<typeof styles> {}

const Footer = (props: Props) => (
  <div className={props.classes.root}>
    <div className={props.classes.container}>
      <div className={props.classes.logoContainer}>
        <img src={dasa} className={props.classes.logo} alt="logo" />
      </div>
      <div className={props.classes.buildContainer}>
        {`v. ${env.version}.${env.buildDate}.${env.buildNumber}`}
      </div>
    </div>
  </div>
);

export default withStyles(styles)(Footer);
