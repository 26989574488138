import React from 'react';
import {
  Route, Redirect, RouteProps, RouteComponentProps,
} from 'react-router-dom';
import { AuthConsumer, AuthContext } from './AuthContext';

interface Props extends RouteComponentProps { }


class PrivateRoute extends React.Component<RouteProps> {
  public componentDidUpdate() {
    const { reAuth } = this.context;
    reAuth();
  }

  public render() {
    const { component: Component, ...rest } = this.props;
    if (!Component) {
      return null;
    }
    return (
      <AuthConsumer>
        {({ isAuth }) => (
          <Route
            {...rest}
            render={
              (props: Props) => (
                isAuth() ? <Component {...props} /> : (
                  <Redirect to={{
                    pathname: '/login',
                    state: { from: props.location },
                  }}
                  />
                )
              )
            }
          />
        )}
      </AuthConsumer>
    );
  }
}

PrivateRoute.contextType = AuthContext;

export default PrivateRoute;
