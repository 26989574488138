import {
  createStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  withStyles,
  WithStyles,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton,
  CircularProgress,
  Menu,
  MenuItem,
} from '@material-ui/core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Link, Redirect } from 'react-router-dom';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { SFFile, snackbarVariant } from '../pages/Files/Files';
import { AuthContext } from './AuthContext';

const styles = (theme: Theme) => createStyles({

  root: {
    marginTop: theme.spacing(3),
    alignItems: 'center',
    justify: 'center',
  },
  [theme.breakpoints.up('md')]: {
    root: {
      margin: 30,
    },
  },
  h3: {
    marginLeft: theme.spacing(),
  },
  table: {
  },
  tableContainer: {
    overflowX: 'auto',
  },
  tr: {
    cursor: 'pointer',
    height: 'auto',
  },
  trNoSelect: {
    height: 'auto',
  },
  td: {
    paddingTop: 0,
    paddingBottom: 0,

    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: 'black',
    fontSize: 14,
  },
  tdh: {
    fontSize: 16,
    fontWeight: 'bolder',

    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  [theme.breakpoints.up('sm')]: {
    td: {
      '&:first-child': {
        paddingLeft: theme.spacing(1),
      },
      '&:last-child': {
        paddingRight: 0,
      },
    },
    tableContainer: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  link: {
    textDecoration: 'none',
    color: 'black',
    alignItems: 'center',
  },
  titleHeader: {
    backgroundColor: theme.palette.primary.light,
    margin: 20,
    marginBottom: 0,
    paddingTop: 5,
    paddingBottom: 5,
    width: '100%',
  },
  button: {
    maxHeight: 46,
    maxWidth: 46,
  },
  loadingFile: {
    textAlign: 'center',
    verticalAlign: 'middle',
    lineHeight: '46px',
    display: 'inline-block',
  },
  menuIcon: {
    paddingRight: 10,
  },

});

interface Props extends WithStyles<typeof styles> {
  files: SFFile[];
  triggerParentUpdate: (filename: string) => void;
  setNotification: (text: string, type?: snackbarVariant) => void;
}

const FileList = (props: Props) => {
  const [open, setOpen] = React.useState(false);
  const [deleteFilename, setDeleteFilename] = React.useState('');
  const [deleteInProgress, setDeleteInProgress] = React.useState(false);
  const [deleteCompeted, setDeleteCompeted] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuFilename, setMenuFilename] = React.useState('');
  const [menuGbqtableId, setMenuGbqtableId] = React.useState('');
  const [redirectTo, setRedirectTo] = React.useState('');

  const apiContext = useContext(AuthContext);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (!deleteInProgress && deleteCompeted) {
      setDeleteCompeted(false);
      setDeleteFilename('');
    }
  },
  [props.files]); //eslint-disable-line

  const { classes, files } = props;

  const handleClickMenu = (
    file: SFFile,
    fileName: string,
    event: React.MouseEvent<HTMLElement>,
  ) => {
    setMenuGbqtableId(file.gbqtableId);
    setMenuFilename(fileName);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const deleteFile = (fileName: string) => {
    apiContext.api.deleteFile(fileName)
      .then(() => {
        setDeleteCompeted(true);
        setDeleteInProgress(false);
        props.triggerParentUpdate(fileName);
        const statusText = `${fileName} ${t('pages:files.fileHandling.delete')}`;
        props.setNotification(statusText, 'success');
      })
      .catch(() => {
        setDeleteCompeted(false);
        setDeleteInProgress(false);
      });
  };

  const handleClickDelete = () => {
    handleCloseMenu();
    if (!deleteInProgress && !deleteCompeted) {
      setDeleteFilename(menuFilename);
      setOpen(true);
    }
    setMenuFilename('');
  };

  const handleCloseDeleteDialog = (resp: boolean) => {
    setOpen(false);
    if (resp) {
      setDeleteInProgress(true);
      deleteFile(deleteFilename);
    }
  };

  const handleClickDownload = () => {
    handleCloseMenu();
    if (menuFilename !== deleteFilename || (!deleteInProgress && !deleteCompeted)) {
      apiContext.api.downloadFile(menuFilename)
        .then((res) => {
          const link = document.createElement('a');
          link.href = res.url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(() => {
        });
    }
    setMenuFilename('');
  };

  const onRowClick = (filename: string, gbqtableId: string) => {
    if (gbqtableId.length > 10) {
      setRedirectTo(`/files/${filename}`);
    }
  };

  const preventRedirect = (event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>) => {
    event.stopPropagation();
  };

  const humanFileSize = (filename: number) => {
    let bytes = filename;
    const thresh = 1024;
    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    if (Math.abs(bytes) < thresh) {
      return `${bytes} B`;
    }
    let u = -1;
    do {
      bytes /= thresh;
      u += 1;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return `${bytes.toFixed(1)} ${units[u]}`;
  };

  if (redirectTo !== '') {
    return (<Redirect push to={redirectTo} />);
  }

  return (
    <>
      <Grid className={classes.root} item xs>
        <div className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.trNoSelect}>
                <TableCell className={[classes.td, classes.tdh].join(' ')}>{t('pages:files.fileName')}</TableCell>
                <TableCell className={[classes.td, classes.tdh].join(' ')}>{t('pages:files.size')}</TableCell>
                <TableCell className={[classes.td, classes.tdh].join(' ')}>{t('pages:files.uploaded')}</TableCell>
                <TableCell className={[classes.td, classes.tdh].join(' ')} />
              </TableRow>
            </TableHead>
            <TableBody>
              {files.map(file => (
                <TableRow
                  hover={file.gbqtableId !== 'NA'}
                  key={file.Key}
                  className={file.gbqtableId === 'NA' ? classes.trNoSelect : classes.tr}
                  onClick={() => onRowClick(file.Key, file.gbqtableId)}
                >
                  <TableCell className={classes.td}>
                    {file.gbqtableId === 'undefined' || file.gbqtableId === 'NA'
                      ? (
                        <div className={classes.loadingFile} style={file.gbqtableId === 'NA' ? { color: 'grey' } : {}}>
                          {file.Key}
                        </div>
                      )
                      : <Link className={classes.link} to={{ pathname: `/files/${file.Key}` }}>{file.Key}</Link>
                    }
                  </TableCell>
                  <TableCell className={classes.td} style={file.gbqtableId === 'NA' ? { color: 'grey' } : {}}>{humanFileSize(file.Size)}</TableCell>
                  <TableCell className={classes.td} style={file.gbqtableId === 'NA' ? { color: 'grey' } : {}}>{moment(file.LastModified).format('YYYY-MM-DD HH:MM')}</TableCell>
                  <TableCell className={classes.td} style={file.gbqtableId === 'NA' ? { color: 'grey' } : {}} onClick={(event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>) => preventRedirect(event)}>


                    {(file.Key === deleteFilename && (deleteInProgress || deleteCompeted)) || (file.gbqtableId === 'undefined')
                      ? (
                        <IconButton
                          className={classes.button}
                          disabled
                        >
                          <CircularProgress size={22} />
                        </IconButton>
                      )

                      : (
                        <IconButton
                          key={file.Key}
                          className={classes.button}
                          onClick={(event) => { handleClickMenu(file, file.Key, event); }
                          }
                        >
                          <MoreIcon />
                        </IconButton>
                      )
                    }
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            {menuGbqtableId === 'NA'
              ? ''
              : (
                <Link className={classes.link} to={{ pathname: `/files/${menuFilename}/monitoring` }}>
                  <MenuItem>
                    <VisibilityIcon className={classes.menuIcon} />
                    {t('pages:files.menu.view')}
                  </MenuItem>
                </Link>
              )
            }
            <MenuItem onClick={handleClickDownload}>
              <CloudDownloadIcon className={classes.menuIcon} />
              {t('pages:files.menu.download')}
            </MenuItem>
            <MenuItem onClick={handleClickDelete}>
              <DeleteIcon className={classes.menuIcon} />
              {t('pages:files.menu.delete')}
            </MenuItem>
          </Menu>
        </div>
      </Grid>
      <Dialog
        open={open}
        onClose={() => handleCloseDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`${t('pages:files.fileHandling.deleteQ')} "${deleteFilename}"?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('pages:files.fileHandling.deleteText')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDeleteDialog(false)} color="primary">
            {t('components:button.no')}
          </Button>
          <Button onClick={() => handleCloseDeleteDialog(true)} color="secondary" autoFocus>
            {t('components:button.yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(FileList);
