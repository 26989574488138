import React from 'react';
import {
  WithStyles, withStyles, Theme, createStyles,
} from '@material-ui/core';
import { Doughnut, ChartData } from 'react-chartjs-2';

const styles = (theme: Theme) => createStyles({
  doughnut: {
    paddingBottom: theme.spacing(1),
  },
});

interface Props extends WithStyles<typeof styles> {
  datasets: ChartData<Chart.ChartData>;
  unitLabel: string;
  options: Chart.ChartOptions;
  width?: number;
  height?: number;
}

export const DoughnutChart = (props: Props) => {
  const { classes } = props;
  const opt = props.options;
  opt.tooltips = {
    callbacks: {
      label: (item, data) => {
        if (data.datasets && data.labels
          && item.datasetIndex !== undefined && item.index !== undefined) {
          const dataset = data.datasets[item.datasetIndex];
          const label = data.labels[item.index];
          if (dataset.data !== undefined) {
            const tmp = dataset.data[item.index];
            return `${label}: ${typeof tmp === 'number' ? tmp.toFixed(1) : ''} ${props.unitLabel}`;
          }
        }
        return '';
      },
    },
  };
  return (
    <div className={classes.doughnut}>
      <Doughnut
        data={props.datasets}
        width={props.width ? props.width : 600}
        height={props.height ? props.height : 400}
        options={opt}
      />
    </div>
  );
};

export default withStyles(styles)(DoughnutChart);
