import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import ButtonStyles from './ButtonStyles';

const styles = (theme: Theme) => ButtonStyles(theme);

interface Props extends WithStyles<typeof styles> {
  to: string;
  children: React.ReactNode;
  Icon: React.ComponentType<SvgIconProps>;
  dark?: boolean;
}

const NavLink = ({
  classes, to, Icon, children, dark = false,
}: Props) => (
  <Link to={to} className={classes.root}>
    <Button className={dark ? classes.buttonDark : classes.buttonLight}>
      <Icon className={classes.icon} />
      {' '}
      {children}
    </Button>
  </Link>
);

export default withStyles(styles, { withTheme: true })(NavLink);
