interface DashboardMap {
  overview: number;
  monitoring: number;
  production: number;
}

interface Endpoints {
  thingsFind: string;
  login: string;
  refreshToken: string;
  pdfBaseUrl: string;
  metabaseToken: string;
  getFileList: string;
  uploadFile: string;
  deleteFile: string;
  getFile: string;
  downloadFile: string;
  getMachineData: string;
  userdata: string;
}

interface Environment {
  dashboards: DashboardMap;
  api: Endpoints;
  version: string;
  buildDate: string;
  buildNumber: string;
}

const DEV_ROOT = 'https://api.prod1.dasaconnect.com';
const DEV_BASE = `${DEV_ROOT}/connectbucking`;
const STAGE_ROOT = 'https://api.stage.dasaconnect.com';
const STAGE_BASE = `${STAGE_ROOT}/connectbucking`;
const PROD_ROOT = 'https://api.dasaconnect.com';
const PROD_BASE = `${PROD_ROOT}/connectbucking`;
const PROD1_ROOT = 'https://api.prod1.dasaconnect.com';
const PROD1_BASE = `${PROD1_ROOT}/connectbucking`;
const PROD2_ROOT = 'https://api.prod2.dasaconnect.com';
const PROD2_BASE = `${PROD2_ROOT}/connectbucking`;

const VERSION = '0.1.0';
const BUILD_DATE = 'NoDate';
const BUILD_NUMBER = 'NoNumber';

const KC_PATH = 'https://id.dasa.se/auth/realms/Dasa';

const environments: { [key: string]: Environment } = {
  dev: {
    dashboards: {
      overview: 7,
      monitoring: 12,
      production: 13,
    },
    api: {
      thingsFind: `${DEV_BASE}/things/find`,
      login: `${DEV_BASE}/auth/login`,
      refreshToken: `${KC_PATH}/userResources/refreshMic`,
      pdfBaseUrl: `${DEV_ROOT}/reports/pdf`,
      metabaseToken: `${DEV_BASE}/metabase-token`,
      getFileList: `${DEV_BASE}/files/list`,
      uploadFile: `${DEV_BASE}/files/upload`,
      deleteFile: `${DEV_BASE}/files/delete`,
      getFile: `${DEV_BASE}/filedata`,
      downloadFile: `${DEV_BASE}/files/download`,
      getMachineData: `${DEV_BASE}/data`,
      userdata: `${DEV_BASE}/userdata`,
    },
    version: VERSION,
    buildDate: BUILD_DATE,
    buildNumber: BUILD_NUMBER,
  },
  stage: {
    dashboards: {
      overview: 15,
      monitoring: 16,
      production: 21,
    },
    api: {
      thingsFind: `${STAGE_BASE}/things/find`,
      login: `${STAGE_BASE}/auth/login`,
      refreshToken: `${KC_PATH}/userResources/refreshMic`,
      pdfBaseUrl: `${STAGE_ROOT}/reports/pdf`,
      metabaseToken: `${STAGE_BASE}/metabase-token`,
      getFileList: `${STAGE_BASE}/files/list`,
      uploadFile: `${STAGE_BASE}/files/upload`,
      deleteFile: `${STAGE_BASE}/files/delete`,
      getFile: `${STAGE_BASE}/filedata`,
      downloadFile: `${STAGE_BASE}/files/download`,
      getMachineData: `${STAGE_BASE}/data`,
      userdata: `${STAGE_BASE}/userdata`,
    },
    version: VERSION,
    buildDate: BUILD_DATE,
    buildNumber: BUILD_NUMBER,
  },
  prod: {
    dashboards: {
      overview: 15,
      monitoring: 16,
      production: 21,
    },
    api: {
      thingsFind: `${PROD_BASE}/things/find`,
      login: `${PROD_BASE}/auth/login`,
      refreshToken: `${PROD_BASE}/auth/refresh`,
      pdfBaseUrl: `${PROD_BASE}/reports/pdf`,
      metabaseToken: `${PROD_BASE}/metabase-token`,
      getFileList: `${PROD_BASE}/files/list`,
      uploadFile: `${PROD_BASE}/files/upload`,
      deleteFile: `${PROD_BASE}/files/delete`,
      getFile: `${PROD_BASE}/filedata`,
      downloadFile: `${PROD_BASE}/files/download`,
      getMachineData: `${PROD_BASE}/data`,
      userdata: `${PROD_BASE}/userdata`,
    },
    version: VERSION,
    buildDate: BUILD_DATE,
    buildNumber: BUILD_NUMBER,
  },
  prod1: {
    dashboards: {
      overview: 15,
      monitoring: 16,
      production: 21,
    },
    api: {
      thingsFind: `${PROD1_BASE}/things/find`,
      login: `${PROD1_BASE}/auth/login`,
      refreshToken: `${PROD1_BASE}/auth/refresh`,
      pdfBaseUrl: `${PROD1_BASE}/reports/pdf`,
      metabaseToken: `${PROD1_BASE}/metabase-token`,
      getFileList: `${PROD1_BASE}/files/list`,
      uploadFile: `${PROD1_BASE}/files/upload`,
      deleteFile: `${PROD1_BASE}/files/delete`,
      getFile: `${PROD1_BASE}/filedata`,
      downloadFile: `${PROD1_BASE}/files/download`,
      getMachineData: `${PROD1_BASE}/data`,
      userdata: `${PROD1_BASE}/userdata`,
    },
    version: VERSION,
    buildDate: BUILD_DATE,
    buildNumber: BUILD_NUMBER,
  },
  prod2: {
    dashboards: {
      overview: 15,
      monitoring: 16,
      production: 21,
    },
    api: {
      thingsFind: `${PROD2_BASE}/things/find`,
      login: `${PROD2_BASE}/auth/login`,
      refreshToken: `${PROD2_BASE}/auth/refresh`,
      pdfBaseUrl: `${PROD2_BASE}/reports/pdf`,
      metabaseToken: `${PROD2_BASE}/metabase-token`,
      getFileList: `${PROD2_BASE}/files/list`,
      uploadFile: `${PROD2_BASE}/files/upload`,
      deleteFile: `${PROD2_BASE}/files/delete`,
      getFile: `${PROD2_BASE}/filedata`,
      downloadFile: `${PROD2_BASE}/files/download`,
      getMachineData: `${PROD2_BASE}/data`,
      userdata: `${PROD2_BASE}/userdata`,
    },
    version: VERSION,
    buildDate: BUILD_DATE,
    buildNumber: BUILD_NUMBER,
  },
};

const envname = process.env.REACT_APP_ENVIRONMENT || 'dev';
const env = environments[envname];

export default env;
