import React from 'react';
import {
  WithStyles, createStyles, Theme, withStyles, IconButton, Tooltip, Fade,
} from '@material-ui/core';
import SaveAlt from '@material-ui/icons/SaveAlt';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SpeciesData } from '../../util/dataFormatting';

const styles = (theme: Theme) => createStyles({
  onTop: {
    padding: 8,
    margin: 4,
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1000,
  },
});

interface Data {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
  } [];
}

interface Props extends WithStyles<typeof styles>, WithTranslation{
  barData?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  tableData?: Map<string, SpeciesData[]>[];
  filename: string;
  tooltip?: string;
  titles: string[];
  hidden: boolean;
}

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const ChartExportButton = (props: Props) => {
  const { classes, t } = props;

  const handleExport = () => {
    const wb = XLSX.utils.book_new();

    let outIndex = -1;
    if (props.barData) {
      const barData: Data[] = props.barData; // eslint-disable-line prefer-destructuring
      barData.forEach((input) => {
        const list: any = []; // eslint-disable-line @typescript-eslint/no-explicit-any
        input.labels.forEach((label, index) => {
          const outData: any = {}; // eslint-disable-line @typescript-eslint/no-explicit-any
          outData[''] = label;
          input.datasets.forEach((listOfVal) => {
            outData[listOfVal.label] = listOfVal.data[index];
          });
          list.push(outData);
        });
        const ws = XLSX.utils.json_to_sheet(list);
        XLSX.utils.book_append_sheet(wb, ws, props.titles[outIndex += 1].substr(0, 31));
      });
    }

    if (props.tableData) {
      props.tableData.forEach((table) => {
        let outDataList: SpeciesData[] = [];
        Array.from(table.values()).forEach((list) => {
          outDataList = outDataList.concat(list);
        });
        const ws = XLSX.utils.json_to_sheet(outDataList);
        XLSX.utils.book_append_sheet(wb, ws, props.titles[outIndex += 1]);
      });
    }

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, props.filename + fileExtension);
  };
  const tooltip = props.tooltip ? props.tooltip : t('components:misc.downloadAs');
  return (
    <Fade in={!props.hidden}>
      <Tooltip title={tooltip}>
        <IconButton
          className={classes.onTop}
          onClick={handleExport}
          type="submit"
          color="primary"
        >
          <SaveAlt />
        </IconButton>
      </Tooltip>
    </Fade>
  );
};

export default withStyles(styles)(withTranslation()(ChartExportButton));
