import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Machines from './Machines';
import Loading from '../../components/Loading';
import { AuthContext, AuthInterface } from '../../components/AuthContext';

export interface Machine {
  _id: string;
  _index: string;
  _source: {
    createdAt: string;
    domain: string;
    label: string;
    thingName: string;
    externalId: string;
  };
}

interface State {
  isLoading: boolean;
  error?: Error;
  machines?: Machine[];
  savedSwitches?: { id: string; val: boolean }[];
}

interface Props extends RouteComponentProps {
}

const withFetching = (Component: React.ComponentType<any>): React.ComponentClass<Props> => { // eslint-disable-line
  class WithFetching extends React.Component<Props, State> {
    public constructor(props: Props) {
      super(props);

      this.state = {
        isLoading: true,
      };
    }

    public componentDidMount() {
      const { api, user }: AuthInterface = this.context;
      api.getMachines()
        .then((machines) => {
          const savedSwitches: string[] = user.OverviewMachines;
          const switchesMap: {id: string; val: boolean}[] = [];

          machines.forEach((machine, index) => {
            switchesMap[index] = { id: machine._id, val: savedSwitches.includes(machine._id) } // eslint-disable-line
          });

          this.setState({ machines, isLoading: false, savedSwitches: switchesMap });
        }).catch((error) => {
          this.setState({ error });
        });
    }

    public render() {
      if (this.state.error !== undefined) {
        return <p>{`Error: ${this.state.error.message}`}</p>;
      }
      if (this.state.isLoading) {
        return <Loading />;
      }
      return (
        <Component
          savedSwitches={this.state.savedSwitches}
          machines={this.state.machines}
          {...this.props}
        />
      );
    }
  }
  WithFetching.contextType = AuthContext;
  return WithFetching;
};

export default withFetching(Machines);
