import React from 'react';
import { Grid } from '@material-ui/core';
import enFlag from '../resources/icons/en_flag.png';
import svFlag from '../resources/icons/sv_flag.png';
import deFlag from '../resources/icons/de_flag.png';

export const localeToLanguage = (locale: string) => {
  switch (locale) {
    case 'en' || 'en-GB': return 'English';
    case 'sv': return 'Svenska';
    case 'de': return 'Deutsch';
    default: return 'English';
  }
};

export const localeToLanguageAndFlag = (locale: string) => {
  switch (locale) {
    case 'en' || 'en-GB': return (
      <Grid container spacing={1} justify="space-between" alignItems="center">
        <Grid item>English </Grid>
        <Grid item><img src={enFlag} alt="" /></Grid>
      </Grid>
    );
    case 'sv': return (
      <Grid container spacing={1} justify="space-between" alignItems="center">
        <Grid item>Svenska </Grid>
        <Grid item><img src={svFlag} alt="" /></Grid>
      </Grid>
    );
    case 'de': return (
      <Grid container spacing={1} justify="space-between" alignItems="center">
        <Grid item>Deutsch</Grid>
        <Grid item><img src={deFlag} alt="" /></Grid>
      </Grid>
    );
    default: return (
      <Grid container spacing={1} justify="space-between" alignItems="center">
        <Grid item>English </Grid>
        <Grid item><img src={enFlag} alt="" /></Grid>
      </Grid>
    );
  }
};
