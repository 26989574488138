import React from 'react';
import {
  Theme, Grid, WithStyles, withStyles, createStyles, Paper, Fade,
} from '@material-ui/core';
import moment from 'moment';
import BackIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ForwardIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import DayPicker, { RangeModifier } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import Popper from '@material-ui/core/Popper';
import withWidth, { WithWidthProps } from '@material-ui/core/withWidth';
import { Translation } from 'react-i18next';

const styles = (theme: Theme) => createStyles({
  root: {
    width: '100%',
    height: '40px',
    backgroundColor: theme.palette.primary.light,
    alignItems: 'center',
  },
  button: {
    borderStyle: 'none',
    backgroundColor: theme.palette.primary.light,
    outline: 'none',
    cursor: 'pointer',
    height: 32,
    backgroundClip: 'content-box',
  },
  icon: {
    height: 30,
    fontSize: 27,
    '&:hover': {
      fontSize: 30,
    },
  },
  typography: {
    padding: 2,
  },
  dateHeader: {
    cursor: 'pointer',
  },
  dateText: {
    height: '100%',
    width: '100%',
    textAlign: 'center',
    '&:focus': {
      outline: 'none !important',
    },
  },
  popper: {
    zIndex: 1500,
  },
  calendar: {
    '& .DayPicker-Month': {
      'border-collapse': 'separate',
    },
    '& .DayPicker-WeekNumber': {
      outline: 'none',
    },
    '& .DayPicker-Day': {
      outline: 'none',
      border: '1px solid transparent',
      '&:hover:not(.DayPicker-Day--selectedRangeStart):not(.DayPicker-Day--selectedRangeEnd)': {
        'background-color': `${theme.palette.grey[100]}!important`,
        'border-radius': '0 !important',
      },
    },
    '& .DayPicker-Day--hoverRange': {
      'background-color': `${theme.palette.grey[100]}!important`,
      'border-radius': '0 !important',
    },
    '& .DayPicker-Day--selectedRange': {
      'background-color': `${theme.palette.primary.light}!important`,
      'border-top-color': theme.palette.grey.A100,
      'border-bottom-color': theme.palette.grey.A100,
      'border-left-color': theme.palette.primary.light,
      'border-right-color': theme.palette.primary.light,
      'border-radius': '0 !important',
    },
    '& .DayPicker-Day--selectedRangeStart': {
      'background-color': `${theme.palette.grey.A100}!important`,
      'border-left': `1px solid${theme.palette.grey.A100}`,
      '&:hover': {
        'background-color': `${theme.palette.grey[300]}!important`,
      },
    },
    '& .DayPicker-Day--selectedRangeEnd': {
      'background-color': `${theme.palette.grey.A100}!important`,
      'border-right': `1px solid${theme.palette.grey.A100}`,
      '&:hover': {
        'background-color': `${theme.palette.grey[300]}!important`,
      },
    },
    '& .DayPicker-Day--selectedRange:not(.DayPicker-Day--outside).DayPicker-Day--selected': {
      'border-radius': '0 !important',
      color: 'black',
    },
    '& .DayPicker-Day--hoverRange:not(.DayPicker-Day--outside).DayPicker-Day--selected': {
      'border-radius': '0 !important',
      color: 'black',
    },
    '& .DayPicker-Day--hoverRange:hover': {
      'border-radius': '0 !important',
    },
    '& .DayPicker-Day--today': {
      color: 'red !important',
    },
    '& .DayPicker-Footer': {
      textAlign: 'center',
    },
    '& .DayPicker-TodayButton': {
      fontSize: '1.15em',
    },
  },
});

interface Props extends WithStyles<typeof styles>, WithWidthProps {
  triggerParentUpdate: (dateRange: string) => void;
  rangeType?: 'week' | 'range';
  startDate?: string;
  endDate?: string;
}

interface State {
  dateRange: RangeModifier;
  selectingNewRange: boolean;
  hoverRange: RangeModifier | undefined;
  anchorEl: null | HTMLElement;
}

function getWeekRange(date: Date) {
  return {
    from: moment(date)
      .startOf('week')
      .toDate(),
    to: moment(date)
      .endOf('week')
      .toDate(),
  };
}

class DateRangePicker extends React.Component<Props, State> {
  public static defaultProps = {
    triggerParentUpdate: (dateRange: string) => { },
  }

  private calendarRef = React.createRef<HTMLDivElement>();

  private dateHeaderRef = React.createRef<HTMLDivElement>();

  public constructor(props: Props) {
    super(props);
    let from;
    let to;
    if (this.props.startDate !== undefined && this.props.endDate !== undefined) {
      from = new Date(this.props.startDate);
      to = new Date(this.props.endDate);
    } else {
      from = new Date();
      to = new Date();
    }

    this.state = {
      dateRange: {
        from,
        to,
      },
      selectingNewRange: false,
      hoverRange: undefined,
      anchorEl: null,
    };
  }

  public componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
    window.addEventListener('blur', this.closeCalendar);
    if (!this.props.startDate || !this.props.endDate) {
      this.updateWeekRange((moment().toDate()));
    }
  }

  public componentWillReceiveProps(nextProps: Props) {
    const { from, to } = this.state.dateRange;
    if (nextProps.startDate && nextProps.endDate) {
      const newFrom = new Date(nextProps.startDate);
      const newTo = new Date(nextProps.endDate);
      if (newFrom !== from || newTo !== to) {
        this.setState({
          dateRange: { from: newFrom, to: newTo },
        });
      }
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('blur', this.closeCalendar);
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  private handleClick = (event: MouseEvent) => {
    const calendarNode = this.calendarRef.current;
    const dateHeaderNode = this.dateHeaderRef.current;
    const target = event.target as HTMLElement;
    if (
      calendarNode
      && !calendarNode.contains(target)
      && !(dateHeaderNode && dateHeaderNode.contains(target))
    ) {
      this.closeCalendar();
    }
  }

  private prevWeek = () => {
    const selectedDuration = moment(this.state.dateRange.to).diff(this.state.dateRange.from, 'days');
    const date: Date = moment(this.state.dateRange.from).subtract((7 - Math.min(selectedDuration / 2, 3)), 'days').toDate();
    this.updateWeekRange(date);
  }

  private nextWeek = () => {
    const selectedDuration = moment(this.state.dateRange.to).diff(this.state.dateRange.from, 'days');
    const date: Date = moment(this.state.dateRange.to).add((7 - Math.min(selectedDuration / 2, 3)), 'days').toDate();
    this.updateWeekRange(date);
  }

  private toggleCalendar = (event: React.MouseEvent<HTMLElement>) => {
    const savedTarget = event.currentTarget;
    this.setState(prevState => ({
      anchorEl: prevState.anchorEl ? null : savedTarget,
    }));
  }

  private closeCalendar = () => {
    this.setState({
      anchorEl: null,
    });
  };

  private onDayClick = (date: Date) => {
    if (this.props.rangeType === 'week') {
      this.handleDayClickWeek(date);
    } else if (this.props.rangeType === 'range') {
      this.handleDayClickRange(date);
    }
  }

  private handleDayClickWeek = (date: Date) => {
    this.closeCalendar();
    this.updateWeekRange(moment(date).toDate());
  };

  private handleDayClickRange = (day: Date) => {
    const { selectingNewRange } = this.state;
    if (!selectingNewRange) {
      this.setState({
        dateRange: {
          from: day,
          to: day,
        },
        selectingNewRange: true,
      });
    } else {
      if (moment(day).isBefore(this.state.dateRange.from)) {
        this.updateDateRange({
          from: day,
          to: this.state.dateRange.from,
        });
      } else {
        this.updateDateRange({
          from: this.state.dateRange.from,
          to: day,
        });
      }
      this.closeCalendar();
    }
  }

  private handleWeekClick = (weekNumber: number, days: Date[]) => {
    this.closeCalendar();
    this.updateWeekRange(moment(days[0]).toDate());
  };

  private onDayMouseEnter = (date: Date) => {
    if (this.props.rangeType === 'week') {
      this.handleDayEnterWeek(date);
    } else if (this.props.rangeType === 'range') {
      this.handleDayEnterRange(date);
    }
  }

  private handleDayEnterWeek = (date: Date) => {
    this.setState({
      hoverRange: getWeekRange(date),
    });
  };

  private handleDayEnterRange = (date: Date) => {
    if (this.state.selectingNewRange) {
      this.setState(prevState => ({
        hoverRange: {
          from: prevState.dateRange.from,
          to: date,
        },
      }));
    } else {
      this.setState({
        hoverRange: undefined,
      });
    }
  };

  private handleDayLeave = () => {
    this.setState({
      hoverRange: undefined,
    });
  };

  private updateWeekRange(date: Date) {
    const weekRange = getWeekRange(date);
    this.updateDateRange(weekRange);
  }

  private updateDateRange(dateRange: RangeModifier) {
    this.setState({
      dateRange,
      selectingNewRange: false,
    });
    const dateRangeString = `${moment(dateRange.from).format('YYYY-MM-DD')}~${moment(dateRange.to).format('YYYY-MM-DD')}`;
    this.props.triggerParentUpdate(dateRangeString);
  }

  public render() {
    const { classes } = this.props;
    const { hoverRange, dateRange, selectingNewRange } = this.state;
    const daysAreSelected = true;
    const selectedRangeStart = daysAreSelected ? dateRange.from : null;
    const selectedRangeEnd = daysAreSelected ? dateRange.to : null;
    const modifiers = {
      hoverRange,
      selectedRange: {
        from: dateRange.from,
        to: dateRange.to,
      },
      hoverRangeStart: hoverRange && hoverRange.from,
      hoverRangeEnd: hoverRange && hoverRange.to,
      selectedRangeStart,
      selectedRangeEnd,
    };
    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const { width } = this.props;
    const isMobile = width === 'xs';

    return (
      <Translation>
        {t => (
          <React.Fragment>
            <Grid container justify="center" className={classes.root}>
              <Grid container justify="center" item xs={isMobile ? 2 : 1} alignItems="flex-start">
                <button type="button" className={classes.button} onClick={this.prevWeek}>
                  <BackIcon className={classes.icon} />
                </button>
              </Grid>
              <Grid className={classes.dateHeader} container justify="center" item xs={isMobile ? 8 : 10} aria-describedby={id}>
                <div
                  ref={this.dateHeaderRef}
                  onClick={this.toggleCalendar}
                  onKeyDown={() => { }}
                  aria-pressed="false"
                  role="button"
                  className={classes.dateText}
                  tabIndex={-1}
                >
                  {moment(this.state.dateRange.from).format(isMobile ? 'll' : 'LL')}
                  {' – '}
                  {selectingNewRange ? '' : moment(this.state.dateRange.to).format(isMobile ? 'll' : 'LL')}
                </div>
              </Grid>
              <Grid container justify="center" item xs={isMobile ? 2 : 1}>
                <button type="button" className={classes.button} onClick={this.nextWeek}>
                  <ForwardIcon className={classes.icon} />
                </button>
              </Grid>
            </Grid>
            <div>
              <Popper
                id={id}
                open={open}
                anchorEl={this.state.anchorEl}
                transition
                className={classes.popper}
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={250}>
                    <Paper>
                      <div className={classes.calendar} ref={this.calendarRef}>
                        <DayPicker
                          selectedDays={dateRange}
                          showWeekNumbers
                          showOutsideDays
                          modifiers={modifiers}
                          onDayClick={this.onDayClick}
                          onDayMouseEnter={this.onDayMouseEnter}
                          onDayMouseLeave={this.handleDayLeave}
                          onWeekClick={this.handleWeekClick}
                          firstDayOfWeek={1}
                          month={new Date((dateRange.from.getTime() + dateRange.to.getTime()) / 2)}
                          disabledDays={{ after: new Date() }}
                          todayButton={t('components:dateRangePicker.today')}
                          onTodayButtonClick={() => this.handleDayClickWeek(moment().toDate())}
                        />
                      </div>
                    </Paper>
                  </Fade>
                )}
              </Popper>
            </div>
          </React.Fragment>
        )}
      </Translation>
    );
  }
}

export default withWidth()(withStyles(styles)(DateRangePicker));
