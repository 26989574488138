import React from 'react';
import {
  RouteComponentProps, match, Link,
} from 'react-router-dom';
import {
  Grid, WithStyles, withStyles, Theme, createStyles, Dialog, DialogContent,
  DialogContentText, DialogActions, Button,
} from '@material-ui/core';
import { Translation } from 'react-i18next';
import HprPages from './Hpr/HprPages';
import DrfPages from './Drf/DrfPages';

const styles = (theme: Theme) => createStyles({
  [theme.breakpoints.up('md')]: {
    root: {
      paddingTop: 0,
      paddingBottom: 10,
      marginTop: 0,
      marginBottom: 0,
      margin: 30,
      padding: 30,
    },
  },
  [theme.breakpoints.down('sm')]: {
    root: {
      margin: 12,
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 0,
      paddingBottom: 10,
    },
  },
  [theme.breakpoints.down('xs')]: {
    root: {
      margin: 12,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 10,
    },
  },
  root: {
    position: 'relative',
    width: '100%',
  },
});

interface Params {
  id: string;
}

interface Props extends RouteComponentProps, WithStyles<typeof styles> {
  match: match<Params>;
}

const FileAnalysis = (props: Props) => {
  const { classes } = props;
  const { id } = props.match.params;
  const fileId = localStorage.getItem(id);

  if (!fileId) {
    return (
      <Translation>
        {t => (
          <Dialog open>
            <DialogContent>
              <DialogContentText>
                {t('pages:files.fileHandling.errorOpenFile')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Link to="/files/">
                <Button color="primary">
                  {t('components:button.confirm')}
                </Button>
              </Link>
            </DialogActions>
          </Dialog>
        )}
      </Translation>
    );
  } if (id.endsWith('.hpr')) {
    return (
      <Grid container spacing={3} className={classes.root} justify="center">
        <HprPages fileId={fileId} fileName={id} />
      </Grid>
    );
  } if (id.endsWith('.drf')) {
    return (
      <>
        <h1>{id}</h1>
        <Grid container spacing={3} className={classes.root} justify="center">
          <DrfPages fileId={fileId} fileName={id} />
        </Grid>
      </>
    );
  }
  return (
    <Translation>
      {t => (
        <Dialog open>
          <DialogContent>
            <DialogContentText>
              {t('pages:files.fileHandling.errorFileFormat')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Link to="/files/">
              <Button color="primary">
                {t('components:button.confirm')}
              </Button>
            </Link>
          </DialogActions>
        </Dialog>
      )}
    </Translation>
  );
};

export default withStyles(styles)(FileAnalysis);
