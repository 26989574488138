import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import {
  WithStyles, withStyles, Theme, createStyles, TableHead,
  TableRow, TableCell, TableBody, Tabs, Tab, Typography,
} from '@material-ui/core';
import { Translation } from 'react-i18next';
import { SpeciesData } from '../../util/dataFormatting';
import { dataToRows, DisplayType } from '../../util/MapToTable';

const styles = (theme: Theme) => createStyles({
  table: {
    margin: theme.spacing(2),
    marginTop: 0,
    maxHeight: 400,
    overflow: 'auto',
  },
  [theme.breakpoints.down('xs')]: {
    table: {
      margin: theme.spacing(0),
      marginTop: 0,
    },
  },
  tabs: {
    borderBottom: 'solid',
  },
});

const TableCellHead = withStyles((theme: Theme) => createStyles({
  head: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.paper,
    color: 'black',
    fontSize: 14,
    fontWeight: 'bold',
    paddingRight: theme.spacing(1),
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);


interface Props extends WithStyles<typeof styles> {
  list: Map<string, SpeciesData[]>;
  labels: string[];
}

const productDataToRows = (spec: [string, SpeciesData[]]) => {
  const species = spec[1];
  const operatorMap = new Map<string, SpeciesData[]>();

  species.forEach((node) => {
    const operator = operatorMap.get(node.operatorName);
    operator ? operator.push(node) : operatorMap.set(node.operatorName, [node]);
  });
  return dataToRows(operatorMap, DisplayType.Product, false);
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <div>{children}</div>
    </Typography>
  );
}

export const ProductTable = (props: Props) => {
  const { classes, labels } = props;
  const [value, setValue] = useState(0);
  const array = Array.from(props.list.entries());

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Translation>
      {t => (
        <div>
          <Typography align="center" variant="h6">{t('components:chart.production.products')}</Typography>
          <Tabs className={classes.tabs} value={value} onChange={handleChange}>
            {array.map((node, index) => (
              <Tab label={node[0]} key={`tab${node[0]}`} />
            ))}
          </Tabs>
          {array.map((node, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <TabPanel value={value} index={index} key={`panel${index}`}>
              <div className={classes.table}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCellHead>{`${t('components:chart.production.products')} / ${t('components:chart.production.operator')}`}</TableCellHead>
                      <TableCellHead align="right">{`${t('components:chart.production.volume')} (${labels[0]}${t('components:chart.production.sub')})`}</TableCellHead>
                      <TableCellHead align="right">{`${t('components:chart.production.volume')} (${labels[1]}${t('components:chart.production.sob')})`}</TableCellHead>
                      <TableCellHead align="right">{`${t('components:chart.production.avgLogVol')} (${labels[0]}${t('components:chart.production.sub')})`}</TableCellHead>
                      <TableCellHead align="right">{`${t('components:chart.production.logs')} ${labels[3]}`}</TableCellHead>
                      <TableCellHead align="right">{`${t('components:chart.production.avgLen')} (${labels[4]})`}</TableCellHead>
                      <TableCellHead align="right">{`${t('components:chart.production.avgDiam')} (${labels[5]})`}</TableCellHead>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {productDataToRows(node)}
                  </TableBody>
                </Table>
              </div>
            </TabPanel>
          ))}
        </div>
      )}
    </Translation>
  );
};

export default withStyles(styles)(ProductTable);
