import React from 'react';
import Keycloak from 'keycloak-js';
import { Grid } from '@material-ui/core';
import API from '../api/API';
import Loading from './Loading';

export interface UserData {
  userName: string;
  UserId: string;
  firstName: string;
  lastName: string;
  email: string;
  Comment: string;
  Locale: string;
  UnitType: string;
  CreatedAt: number;
  UpdatedAt: number;
  OverviewMachines: string[];
  OverviewFilter: number;
}
export type AccessRights = string[];

export interface AuthInterface {
  user: UserData;
  api: API;
  isAuth: () => boolean;
  reAuth: () => void;
  accessRights: (string: string) => boolean;
}
const AuthContext = React.createContext<AuthInterface>({
  user: {
    userName: '', UserId: '', firstName: '', lastName: '', email: '', Comment: '', Locale: 'en-GB', CreatedAt: 0, UnitType: 'metric', UpdatedAt: 0, OverviewMachines: [], OverviewFilter: 0,
  },
  api: new API(
    (): Keycloak.KeycloakInstance => Keycloak({
      url: 'https://id.dasa.se/auth',
      realm: 'Dasa',
      clientId: 'connectbucking',
      flow: 'standard',
    }),
    (): Credentials => ({ userToken: '', userIdentityId: '' }),
    () => { },
    () => { },
    (): UserData => ({
      userName: '', UserId: '', firstName: '', lastName: '', email: '', Comment: '', Locale: 'en-GB', CreatedAt: 0, UnitType: 'metric', UpdatedAt: 0, OverviewMachines: [], OverviewFilter: 0,
    }),
  ),
  isAuth: () => false,
  reAuth: () => { },
  accessRights: string => false,
});

export interface Credentials {
  userIdentityId: string;
  userToken: string;
}


interface Props {
  children: React.ReactNode;
}

interface State {
  authorized: boolean;
  keycloak: Keycloak.KeycloakInstance;
  user: UserData;
  credentials: Credentials;
  api: API;
  accessRights: AccessRights;
}

class AuthProvider extends React.Component<Props, State> {
  private authorizing = false;

  public constructor(props: Props) {
    super(props);
    const keycloak = Keycloak({
      url: 'https://id.dasa.se/auth',
      realm: 'Dasa',
      clientId: 'connectbucking',
      flow: 'standard',
    });


    this.getKeycloak = this.getKeycloak.bind(this);
    this.getCredentials = this.getCredentials.bind(this);
    this.updateContext = this.updateContext.bind(this);
    this.updateCredentials = this.updateCredentials.bind(this);
    this.getUserdata = this.getUserdata.bind(this);
    this.isAuth = this.isAuth.bind(this);
    this.reAuth = this.reAuth.bind(this);
    this.accessRights = this.accessRights.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);

    this.state = {
      authorized: false,
      keycloak,
      user: {
        userName: '', UserId: '', firstName: '', lastName: '', email: '', Comment: '', Locale: 'en-GB', CreatedAt: 0, UnitType: 'metric', UpdatedAt: 0, OverviewMachines: [], OverviewFilter: 0,
      },
      credentials: { userToken: '', userIdentityId: '' },
      api: new API(
        this.getKeycloak,
        this.getCredentials,
        this.updateContext,
        this.updateCredentials,
        this.getUserdata,
      ),
      accessRights: [],
    };
  }

  public async componentDidMount() {
    this.authorizing = true;
    this.state.api.login();
  }

  public getKeycloak(): Keycloak.KeycloakInstance {
    return this.state.keycloak;
  }

  private getCredentials(): Credentials {
    return this.state.credentials;
  }

  private getUserdata(): UserData {
    return this.state.user;
  }

  public updateCredentials(credentials: Credentials) {
    this.setState({ credentials });
  }

  public updateContext(
    authorized: boolean,
    credentials: Credentials,
    accessRights: AccessRights,
    user: UserData,
  ) {
    this.authorizing = false;
    this.setState({
      authorized, user, credentials, accessRights,
    });
  }

  public reAuth() {
    if (this.state.keycloak.authenticated) {
      if (this.state.keycloak.isTokenExpired(30) && this.authorizing === false) {
        this.authorizing = true;
        this.setState({ authorized: false }, () => {
          this.state.api.updateTokenKC();
        });
      }
    }
  }

  public isAuth() {
    if (this.authorizing) {
      return false;
    }
    return this.state.authorized;
  }

  public accessRights(access: string) {
    return this.state.accessRights ? this.state.accessRights.includes(access) : false;
  }

  public render() {
    if (this.state.authorized && !this.authorizing) {
      return (
        <AuthContext.Provider
          value={{
            isAuth: this.isAuth,
            reAuth: this.reAuth,
            user: this.state.user,
            api: this.state.api,
            accessRights: this.accessRights,
          }}
        >
          {this.props.children}
        </AuthContext.Provider>
      );
    }
    return (
      <Grid container justify="center">
        <Loading />
      </Grid>
    );
  }
}

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer, AuthContext };
