import React from 'react';
import {
  Theme, Grid, WithStyles, withStyles, createStyles, Paper, Table,
  TableFooter, TableCell, TableRow, TableBody,
} from '@material-ui/core';
import moment from 'moment';
import { ChartData } from 'react-chartjs-2';
import { Translation } from 'react-i18next';
import { AuthInterface, AuthContext } from '../../../components/AuthContext';
import {
  FileData, parseMultipleValues, createChartData, createOptionsCustom,
} from '../../../util/dataFormatting';
import Loading from '../../../components/Loading';
import BarChart from '../../../components/Charts/BarChart';
import ChartExportButton from '../../../components/Charts/ChartExportButton';
import ProgressChart from '../../../components/Charts/ProgressChart';
import { ChartUnitOptions, createConvFunctions } from '../../../util/conversionUtil';
import ChartPaper from '../../../components/Charts/ChartPaper';

const styles = (theme: Theme) => createStyles({
  root: {
    margin: 20,
  },
  paper: {
    overflow: 'auto',
    height: '100%',
  },
  export: {
    height: 0,
    margin: 10,
    position: 'relative',
  },
});

const TableCellHead = withStyles((theme: Theme) => createStyles({
  footer: {
    borderLeftStyle: 'solid',
    borderLeftWidth: 1,
    borderLeftColor: theme.palette.common.black,
    fontSize: 14,
  },
}))(TableCell);

const defaultDataset: ChartData<Chart.ChartData> = {
  labels: [''],
  datasets: [
    {
      type: 'bar',
      label: '',
      backgroundColor: 'rgba(138,173,61,1)',
      borderColor: 'rgba(138,173,61,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(138,173,61,0.6)',
      hoverBorderColor: 'rgba(138,173,61,1)',
      data: [0],
    },
  ],
};


const MONITOR_PATH = '/monitor';

interface Props extends WithStyles<typeof styles> {
  fileId: string;
  fileName: string;
  chartUnitOptions: ChartUnitOptions;
}

interface State {
  isLoading: boolean;
  chartDataList: ChartData<Chart.ChartData>[];
}

class DrfOverview extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
      chartDataList: new Array(3).fill(defaultDataset),
    };
  }

  public componentDidMount() {
    const { api }: AuthInterface = this.context;

    const options = createConvFunctions(this.props.chartUnitOptions);

    let data: ChartData<Chart.ChartData>[] = [];

    api.getFile<FileData>(MONITOR_PATH, this.props.fileId, 'day').then((response) => {
      const parsed = parseMultipleValues(response.map(({
        startTime: time,
        operator: label,
        summary: value1,
        numberOfStems: value2,
        workLength: value3,
      }) => ({ time: moment(time).format('M/D/YY'), label, value: [value1, value2, value3] })), options);
      data = createChartData(3, parsed.labels, parsed.operatorList, parsed.dataMap);

      this.setState({
        chartDataList: data,
        isLoading: false,
      });
    }).catch(() => {
      this.setState({
        isLoading: false,
      });
    });
  }

  public render() {
    if (this.state.isLoading) {
      return (
        <Grid container justify="center">
          <Loading />
        </Grid>
      );
    }

    const { classes } = this.props;
    const titles = ['Volume', 'Trunks', 'Work'];
    let num = 0;
    const list1: number[] = new Array(4).fill(0).map(() => {
      num += 1;
      return num;
    });
    num = 0;
    const list2: number[] = new Array(7).fill(0).map(() => {
      num += 1;
      return num;
    });
    return (
      <Translation>
        {t => (
          <>
            <div>
              <ChartExportButton barData={this.state.chartDataList} filename={`${this.props.fileName}_overview`} tooltip="Download all" titles={titles} hidden={false} />
            </div>
            <Grid container spacing={4} justify="center">
              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <Table padding="none">
                    <TableBody>
                      {list1.map((n, index) => (
                        <TableRow key={n}>
                          <TableCell>
                            {`Machine${index}`}
                          </TableCell>
                          {list2.map(n2 => (
                            <TableCell key={`${n}${n2}`}>
                              <ProgressChart options={createOptionsCustom()} name="Volume" data={[1, 2, 3]} backgrundColor="lightgray" />
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell />
                        {list2.map(n => (
                          <TableCellHead align="center" key={`foot${n}`}>
                            <h3>day</h3>
                          </TableCellHead>
                        ))}
                      </TableRow>
                    </TableFooter>
                  </Table>

                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ChartPaper
                  datasets={[this.state.chartDataList[0]]}
                  isTabOrMobile={false}
                  name="Vol"
                  component={
                    <BarChart datasets={this.state.chartDataList[0]} options={createOptionsCustom(this.props.chartUnitOptions.valueLabelList[0])} title={`Volume ${this.props.chartUnitOptions.valueLabelList[0]}`} />
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ChartPaper
                  datasets={[this.state.chartDataList[1]]}
                  isTabOrMobile={false}
                  name="Trunks"
                  component={
                    <BarChart datasets={this.state.chartDataList[1]} options={createOptionsCustom(this.props.chartUnitOptions.valueLabelList[1])} title="Trunks/E(t)-Day" />
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ChartPaper
                  datasets={[this.state.chartDataList[2]]}
                  isTabOrMobile={false}
                  name="Work"
                  component={
                    <BarChart datasets={this.state.chartDataList[2]} options={createOptionsCustom(this.props.chartUnitOptions.valueLabelList[2], { num: 10, label: t('components:chart:goal') })} title="E(t)-Hour/Day" />
                  }
                />
              </Grid>
            </Grid>
          </>
        )}
      </Translation>
    );
  }
}
DrfOverview.contextType = AuthContext;

export default withStyles(styles)(DrfOverview);
