import React from 'react';
import {
  withStyles,
  WithStyles,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme: Theme) => createStyles({
  progress: {
    margin: theme.spacing(2),
  },
});

interface Props extends WithStyles<typeof styles> {
  size?: number;
}

function CircularIndeterminate(props: Props) {
  const { classes } = props;
  return (
    <div>
      <CircularProgress className={classes.progress} size={props.size} />
    </div>
  );
}

export default withStyles(styles)(CircularIndeterminate);
