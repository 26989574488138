import 'chartjs-plugin-annotation';
import moment from 'moment';
import { ColorPicker } from './colors';

export interface ParsingData {
  time: string;
  label: string;
  value: number;
}

export interface ParsingDataMul {
  time: string;
  label: string;
  value: number[];
}

export interface FileData {
  startTime: string;
  operator: string;
  summary: number;
  numberOfStems: number;
  workLength: number;
}

export interface M3Data {
  operator: string;
  harvestDate: string;
  m3SubSum: number;
  m3SobSum: number;
  stemCount: number;
  logCount: number;
  avgLogLength: number;
  avgLogDiameter: number;
}

export interface ParsingOptions {
  doConvert: boolean;
  converter(n: number): number;
  average?: boolean;
}

export interface SpeciesData {
  speciesGroupName: string;
  speciesIdentity: string;
  operatorName: string;
  productName?: string;
  productIdentity?: string;
  m3Sub: number;
  m3Sob: number;
  numberOfStems: number;
  numberOfLogs: number;
  avgLogLength: number;
  avgLogDiameter: number;
}

export interface MachineData {
  startTime: string;
  downtime: number;
  et: number;
  machineIdentity: string;
  numberOfStems: number;
  summary: number;
  fuel: number;
}

export interface FormattedMachineData {
  startTime: string;
  operator: string;
  downtime: number;
  et: number;
  machineIdentity: string;
  numberOfStems: number;
  summary: number;
  fuel: number;
  tu: number;
}

export const parseMultipleValues = (
  data: ParsingDataMul[],
  options: ParsingOptions[],
  dateRange?: string,
) => {
  const operatorSet = new Set<string>();
  const dayMap = new Map<string, ParsingDataMul[]>();

  let momentList: string[] = [];
  if (dateRange) {
    const range = dateRange.split('~');
    const startDate = moment(range[0]);
    const endDate = moment(range[1]);
    let currentDate = moment(startDate);
    currentDate.add(1, 'day');
    momentList = [startDate.format('M/D/YY')];
    while (currentDate < endDate) {
      momentList.push(currentDate.format('M/D/YY'));
      currentDate = currentDate.add(1, 'day');
    }
    momentList.push(endDate.format('M/D/YY'));
  }
  momentList.forEach((day) => {
    dayMap.set(day, []);
  });

  // Create map with date as key and list of FileData as value
  data.forEach((element) => {
    const list = dayMap.get(element.time);
    operatorSet.add(element.label);
    list ? list.push(element) : dayMap.set(element.time, [element]); // eslint-disable-line
  });
  const labels = Array.from(dayMap.keys()); // List of dates
  const operatorList = Array.from(operatorSet);

  // Create map with operator as key and a value as a list initialized to zero
  const dataMap = new Map<string, number[][]>();
  operatorList.forEach((name) => {
    const temp = new Array(labels.length).fill(new Array(data[0].value.length).fill(0));
    dataMap.set(name, temp);
  });

  const sums: number[] = new Array(data[0].value.length).fill(0);
  // For each date, fill the element in dataMap's list with the value for each operator
  Array.from(dayMap.values()).forEach((list, index) => {
    list.forEach((node) => {
      const lst = dataMap.get(node.label);
      if (lst) {
        lst[index] = node.value.map((str, valIndex) => {
          let num = Number(str);
          if (options[valIndex].doConvert) {
            num = options[valIndex].converter(num);
          }
          sums[valIndex] += num;
          return Number(num.toFixed(2));
        });
      }
    });
  });

  sums.forEach((val, index) => {
    if (options[index].average === true) {
      sums[index] = val / data.length;
    }
  });

  return {
    operatorList, dataMap, labels, summary: sums,
  };
};

export const createChartData = (
  charts: number,
  labels: string[],
  operatorList: string[],
  dataMap: Map<string, number[][]>,
) => {
  const returnValues = [];
  for (let i = 0; i < charts; i += 1) {
    const datasets: Chart.ChartDataSets[] = [];
    operatorList.forEach((operator, index) => {
      let valMatrix = dataMap.get(operator);
      valMatrix = valMatrix || [];
      const col = valMatrix.map(value => value[i]);
      datasets.push({
        type: 'bar',
        label: operator,
        backgroundColor: ColorPicker.chartPallet.normal[index],
        borderWidth: 1,
        data: col,
      });
    });
    returnValues.push({ labels, datasets });
  }
  return returnValues;
};


export const createDoughnut = (map: Map<string, SpeciesData[]>) => {
  const values: number[] = [];
  const labels: string[] = [];
  let index = 0;
  map.forEach((element) => {
    labels[index] = element[0].speciesGroupName;
    let val = 0;
    element.forEach((node) => {
      val += Number(node.m3Sub);
    });
    val = Number(val.toFixed(1));
    values.push(val);
    index += 1;
  });
  const data = {
    datasets: [{
      label: 'Operator',
      data: values,
      backgroundColor: ColorPicker.chartPallet.normal,
    }] as Chart.ChartDataSets[],
    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels,
  };
  return data;
};

export const createOptionsCustom = (
  yaxis?: string,
  goal?: { num: number; label: string },
  fixed?: number,
): Chart.ChartOptions => (
  {
    hover: { animationDuration: 0 },
    annotation: {
      ...goal ? {
        annotations: [{
          id: 'goal-line',
          type: 'line',
          mode: 'horizontal',
          scaleID: 'y-axis-0',
          value: goal.num,
          borderColor: 'gray',
          borderWidth: 2,
          borderDash: [4, 4],
          label: {
            backgroundColor: 'gray',
            enabled: true,
            content: goal.label + goal.num,
          },
        }],
      } : {},
    },
    legend: {
      display: false,
    },
    tooltips: {
      ...yaxis ? {
        callbacks: {
          label: (item, data) => {
            if (data.datasets && item.datasetIndex !== undefined) {
              const { label } = data.datasets[item.datasetIndex];
              return `${label}: ${typeof item.yLabel === 'number' ? item.yLabel.toFixed(fixed !== undefined ? fixed : 2) : ''} ${yaxis}`;
            }
            return '';
          },
        },
      } : {},
    },
    maintainAspectRatio: true,
    scales: {
      xAxes: [{ gridLines: { display: false } }],
      yAxes: [{
        ...yaxis ? {
          scaleLabel: {
            display: true,
            fontSize: 14,
            labelString: yaxis,
          },
        } : {},
        ticks: {
          beginAtZero: true,
          ...goal ? {
            suggestedMax: goal.num * 1.1,
          } : {},
        },
      }],
    },
  } as Chart.ChartOptions
);


export const createOptions = (
  label?: string,
  yaxis?: string,
  goal?: { num: number; label: string },
  hideLegend?: boolean,
): Chart.ChartOptions => (
  {
    hover: { animationDuration: 0 },
    annotation: {
      ...goal ? {
        annotations: [{
          id: 'goal-line',
          type: 'line',
          mode: 'horizontal',
          scaleID: 'y-axis-0',
          value: goal.num,
          borderColor: 'gray',
          borderWidth: 2,
          borderDash: [4, 4],
          label: {
            backgroundColor: 'gray',
            enabled: true,
            content: goal.label + goal.num,
          },
        }],
      } : {},
    },
    tooltips: {
      ...yaxis ? {
        callbacks: {
          label: item => `${item.yLabel} ${yaxis}`,
        },
      } : {},
    },
    title: {
      ...label ? {
        display: true, text: label,
      } : {},
    },
    legend: {
      display: !hideLegend,
    },

    maintainAspectRatio: true,
    scales: {
      xAxes: [{ gridLines: { display: false } }],
      yAxes: [{
        ...yaxis ? {
          scaleLabel: {
            display: true,
            fontSize: 14,
            labelString: yaxis,
          },
        } : {},
        ticks: {
          beginAtZero: true,
          ...goal ? {
            suggestedMax: goal.num * 1.1,
          } : {},
        },
      }],
    },
  } as Chart.ChartOptions
);
