import {
  createStyles, Grid, Theme, Typography, WithStyles, withStyles, withWidth, FormGroup,
  FormControlLabel, Select, MenuItem,
} from '@material-ui/core';
import { WithWidthProps, isWidthDown } from '@material-ui/core/withWidth';
import moment from 'moment';
import React from 'react';
import { RouteComponentProps, match, Link } from 'react-router-dom';
import { GridSpacing } from '@material-ui/core/Grid';
import axios, { CancelTokenSource } from 'axios';
import EditIcon from '@material-ui/icons/Edit';
import {
  withTranslation, WithTranslation,
} from 'react-i18next';
import { Bar } from 'react-chartjs-2';
import { AuthContext, AuthInterface } from '../components/AuthContext';
import OverviewMachineMonitor from '../components/Charts/OverviewMachineMonitor';
import OverviewHeader from '../components/Charts/OverviewHeader';
import DateRangePicker from '../components/DateRangePicker';
import { ColorList, ColorListDim } from '../util/colors';
import { ChartUnitOptions, createConvFunctions } from '../util/conversionUtil';
import { Machine } from './Machines';
import { MachineData, createOptionsCustom } from '../util/dataFormatting';
import CustomChart from '../components/Charts/CustomChart';
import ChartPaper from '../components/Charts/ChartPaper';


const styles = (theme: Theme) => createStyles({
  export: {
    position: 'relative',
  },
  chartPaper: {
    padding: theme.spacing(2),
  },
  [theme.breakpoints.down('sm')]: {
    chartPaper: {
      padding: theme.spacing(0),
    },
  },
  root: {
    overflow: 'hidden',
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1),
    alignItems: 'center',
    width: '100%',
  },
  [theme.breakpoints.up('md')]: {
    root: {
      paddingTop: theme.spacing(0),
      padding: 30,
    },
  },
  link: {
    textDecoration: 'none',
    color: 'black',
  },
  formControl: {
    minWidth: 180,
  },
  label: {
    paddingRight: theme.spacing(1),
  },
  datePickerWrapper: {
    maxWidth: 1600,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  titleHeader: {
    backgroundColor: theme.palette.primary.light,
    marginTop: 5,
    paddingTop: 5,
    paddingBottom: 5,
    width: '100%',
  },
});

export interface ProgressData {
  machineId: string;
  machineName: string;
  days: Map<string, {
    work: number;
    down: number;
    totalVol: number;
    fuel: number;
  }>;
}

/*
Options for the conversion of values.
convList, which is optional, contatins the unit conversions,
  or null if no conversion for the value is needed.
valueLabelList containes the unit labels to be shown in the chars and tables.
*/
const convOpt: { [index: string]: ChartUnitOptions } = {};
convOpt.metric = { valueLabelList: ['m\xB3', 'L'] };
convOpt.imperial = {
  convList: [
    { from: 'm3', to: 'ft3' },
    { from: 'l', to: 'gal' },
  ],
  valueLabelList: ['ft\xB3', 'gal'],
};

interface Params {
  startDate?: string;
  endDate?: string;
}

interface Props extends RouteComponentProps, WithStyles<typeof styles>, WithWidthProps,
  WithTranslation {
  match: match<Params>;

}

interface State {
  cancelToken: CancelTokenSource;
  dateRange: string;
  loaded: boolean;
  selectedMachinesList: Machine[];
  allMachinesList: Machine[];
  progressData: ProgressData[];
  chartDatasets: Chart.ChartDataSets[];
  existFilteredMachines: boolean;
  machineFilter: number;
  avgData: {
    vol: number;
    work: number;
    down: number;
    fuel: number;
  };
  prevAvgData: {
    vol: number;
    work: number;
    down: number;
    fuel: number;
  };
  conversionOption: ChartUnitOptions;
  totalNumOfMachines: number;
}

class Overview extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    const { startDate, endDate } = props.match.params;
    let start;
    let end;
    const savedDateJson = sessionStorage.getItem('dateRange');
    if (startDate && endDate) {
      this.props.history.replace(`/overview/${startDate}/${endDate}`);
      start = startDate;
      end = endDate;
    } else if (savedDateJson) {
      const savedDate = JSON.parse(savedDateJson);
      const range = savedDate.split('~');
      const savedStartDate = moment(range[0]);
      const savedEndDate = moment(range[1]);
      if (savedStartDate.isValid() && savedEndDate.isValid()) {
        const duration = moment.duration(savedEndDate.diff(savedStartDate)).asDays();
        if (duration === 6) {
          start = savedStartDate.format('YYYY-MM-DD');
          end = savedEndDate.format('YYYY-MM-DD');
        } else {
          end = savedEndDate.format('YYYY-MM-DD');
          start = savedEndDate.subtract(6, 'days').format('YYYY-MM-DD');
        }
      } else {
        start = moment().startOf('week').format('YYYY-MM-DD');
        end = moment().endOf('week').format('YYYY-MM-DD');
      }
    } else {
      start = moment().startOf('week').format('YYYY-MM-DD');
      end = moment().endOf('week').format('YYYY-MM-DD');
    }
    const dateRange = `${start}~${end}`;
    const cancelToken: CancelTokenSource = axios.CancelToken.source();


    this.state = {
      cancelToken,
      selectedMachinesList: [],
      allMachinesList: [],
      dateRange,
      loaded: false,
      progressData: [],
      chartDatasets: [],
      existFilteredMachines: false,
      machineFilter: 0,
      avgData: {
        vol: 0, work: 0, down: 0, fuel: 0,
      },
      prevAvgData: {
        vol: 0, work: 0, down: 0, fuel: 0,
      },
      conversionOption: convOpt.metric,
      totalNumOfMachines: 0,
    };
    this.setDateRange = this.setDateRange.bind(this);
    this.changeFilterMode = this.changeFilterMode.bind(this);
  }

  public componentDidMount() {
    const { api, user }: AuthInterface = this.context;

    const switchList = user.OverviewMachines;
    const machineFilter = user.OverviewFilter;
    if (switchList.length > 0) {
      const machineList: Machine[] = [];
      api.getMachines().then((machines) => {
        machines.forEach((machine) => {
          // eslint-disable-next-line no-underscore-dangle
          if (switchList.includes(machine._id) === true) machineList.push(machine);
        });
        if (machineList.length === 0) {
          this.setState({
            totalNumOfMachines: machines.length,
            selectedMachinesList: machines,
            allMachinesList: machines,
            machineFilter,
            existFilteredMachines: false,
          }, () => { this.retrieveData(); });
        } else {
          this.setState({
            totalNumOfMachines: machines.length,
            selectedMachinesList: machineList,
            allMachinesList: machines,
            machineFilter,
            existFilteredMachines: true,
          }, () => { this.retrieveData(); });
        }
      });
    } else {
      api.getMachines().then((machines) => {
        this.setState({
          totalNumOfMachines: machines.length,
          selectedMachinesList: machines,
          allMachinesList: machines,
          machineFilter: 0,
          existFilteredMachines: false,
        }, () => { this.retrieveData(); });
      });
    }
  }

  public componentWillUnmount() {
    this.state.cancelToken.cancel('Cancel');
  }

  private setDateRange(dateRange: string) {
    if (this.state.dateRange !== dateRange) {
      const range = dateRange.split('~');
      this.props.history.replace(`/overview/${range[0]}/${range[1]}`);
      this.state.cancelToken.cancel();
      sessionStorage.setItem('dateRange', JSON.stringify(dateRange));
      this.setState({ dateRange, loaded: false, cancelToken: axios.CancelToken.source() }, () => {
        this.retrieveData();
      });
    }
  }

  private getTwoWeeksString() {
    const range = this.state.dateRange.split('~');
    const startDate = moment(range[0]);
    startDate.subtract(7, 'days');
    const endDate = moment(range[1]);
    const startString = startDate.format('YYYY-MM-DD');
    const endString = endDate.format('YYYY-MM-DD');
    return `${startString}~${endString}`;
  }

  private getDateRange(timeback?: number) {
    const range = this.state.dateRange.split('~');
    const startDate = moment(range[0]);
    const endDate = moment(range[1]);
    if (timeback) {
      startDate.subtract(timeback, 'days');
    }
    let currentDate = moment(startDate);
    currentDate.add(1, 'day');
    const momentList: string[] = [startDate.format('M/D/YY')];
    while (currentDate < endDate) {
      momentList.push(currentDate.format('M/D/YY'));
      currentDate = currentDate.add(1, 'day');
    }
    momentList.push(endDate.format('M/D/YY'));
    return momentList;
  }

  private changeFilterMode(event: React.ChangeEvent<{ value: unknown }>) {
    const { api, user }: AuthInterface = this.context;
    this.state.cancelToken.cancel();
    this.setState({
      loaded: false,
      cancelToken: axios.CancelToken.source(),
      machineFilter: event.target.value as number,
    }, () => {
      const currentUser = user;
      currentUser.OverviewFilter = this.state.machineFilter;
      api.userData<string>('PUT', undefined, currentUser);
      this.retrieveData();
    });
  }

  private async retrieveData() {
    const { user, api }: AuthInterface = this.context;
    const { t } = this.props;
    const { machineFilter } = this.state;
    let machineList: Machine[] = this.state.allMachinesList;
    if (machineFilter > 0) {
      machineList = this.state.selectedMachinesList;
    }
    const conversionOption: ChartUnitOptions = convOpt[user.UnitType];
    const conversionList = createConvFunctions(conversionOption);

    let machineString = '';
    machineList.forEach((machine) => { machineString += `'${machine._source.externalId}',`; }); // eslint-disable-line no-underscore-dangle
    machineString = machineString.slice(0, machineString.length - 1);

    this.setState({ conversionOption }, () => {
      api.getMachineData('/monitor', machineString, this.getTwoWeeksString(), 'day',
        this.state.machineFilter === 0, false, this.state.cancelToken).then((response) => {
        let machineDataList: ProgressData[];

        if (machineFilter > 0) {
          machineDataList = machineList.map(machine => ({
            machineId: machine._source.externalId, // eslint-disable-line no-underscore-dangle
            machineName: machine._source.label, // eslint-disable-line no-underscore-dangle
            days: new Map(
              this.getDateRange(7).map(str => ([str, {
                work: 0, down: 0, totalVol: 0, fuel: 0,
              }])),
            ),
          }));
        } else {
          machineDataList = [{
            machineId: 'all_machines',
            machineName: t('pages:overview.allMachines'),
            days: new Map(this.getDateRange(7).map(str => ([str, {
              work: 0, down: 0, totalVol: 0, fuel: 0,
            }]))),
          }];
        }

        if (response.length !== 0) {
          const formattedResponse: MachineData[] = response.map(machine => ({ ...machine, startTime: moment(machine.startTime).format('M/D/YY') }
          ));

          let currentMachine: ProgressData = machineDataList[0];
          let prevDay = formattedResponse[0].startTime;
          formattedResponse.forEach((machineData) => {
            if ((machineData.machineIdentity !== currentMachine.machineId)
                || (machineData.startTime !== prevDay)) {
              const foundMachine = machineDataList.find(elem => (
                elem.machineId === machineData.machineIdentity));
              currentMachine = foundMachine || currentMachine;
            }
            const foundData = currentMachine.days.get(machineData.startTime);
            if (foundData) {
              foundData.down = (Number(machineData.downtime));
              foundData.work = (Number(machineData.et));
              foundData.totalVol = (conversionList[0].converter(Number(machineData.summary)));
              foundData.fuel = (conversionList[1].converter(Number(machineData.fuel)));
              currentMachine.days.set(machineData.startTime, foundData);
            }
            prevDay = machineData.startTime;
          });
        }
        const chartDatasets: Chart.ChartDataSets[] = [];
        const avgData = {
          vol: 0,
          work: 0,
          down: 0,
          fuel: 0,
        };
        const prevAvgData = {
          vol: 0,
          work: 0,
          down: 0,
          fuel: 0,
        };

        machineDataList.forEach((machine, i) => {
          const volData: number[] = [];
          const prevVolData: number[] = [];
          Array.from(machine.days.values()).slice(7).forEach((day) => {
            volData.push(day.totalVol);
            avgData.vol += day.totalVol;
            avgData.work += day.work;
            avgData.down += day.down;
            avgData.fuel += day.fuel;
          });
          Array.from(machine.days.values()).slice(0, 7).forEach((day) => {
            prevVolData.push(day.totalVol);
            prevAvgData.vol += day.totalVol;
            prevAvgData.work += day.work;
            prevAvgData.down += day.down;
            prevAvgData.fuel += day.fuel;
          });
          chartDatasets.push({
            type: 'bar', label: machine.machineName, data: volData, backgroundColor: ColorList[i],
          });
        });
        this.setState({
          loaded: true,
          progressData: machineDataList,
          chartDatasets,
          avgData,
          prevAvgData,
        });
      }).catch((e) => {
        if (!axios.isCancel(e)) {
          this.setState({
            loaded: true,
          });
        }
      });
    });
  }

  public render() {
    const { classes, width, t } = this.props;
    const { user }: AuthInterface = this.context;

    const currentWidth = width || 'xs';
    const momentList = this.getDateRange();
    const isMobile = (currentWidth === 'xs');
    const isTabOrMobile = isWidthDown('sm', currentWidth);

    let height = 50;
    let spacing: GridSpacing = 4;
    if (isMobile) {
      height = 100;
      spacing = 1;
    } else if (isTabOrMobile) {
      height = 75;
      spacing = 2;
    }

    const volChartOptions = createOptionsCustom(
      this.state.conversionOption.valueLabelList[0], { num: 300, label: t('components:chart.goal') }, 1,
    );
    if (volChartOptions.scales) volChartOptions.scales.xAxes = [{ gridLines: { display: true } }];
    const volChart = (
      <>
        <Typography variant="h6" align="center">{t('pages:overview.production')}</Typography>
        <Typography align="center">{t('pages:overview.prodVol')}</Typography>
        {this.state.machineFilter > 0 ? (
          <CustomChart
            chartType="Bar"
            numberBoxLegend
            width={200}
            height={height}
            datasets={{ labels: momentList, datasets: this.state.chartDatasets }}
            options={volChartOptions}
            key={height.toString() + this.state.machineFilter + this.state.loaded}
            origColor={ColorList}
            dimColor={ColorListDim}
          />
        ) : (
          <Bar
            width={200}
            height={height}
            data={{ labels: momentList, datasets: this.state.chartDatasets }}
            options={volChartOptions}
            key={height.toString() + this.state.machineFilter}
          />
        )}
      </>
    );

    return (
      <main className={classes.root}>
        <Grid container alignItems="center" style={{ paddingTop: 8 }}>
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                classes={{ label: classes.label }}
                control={(
                  <Select
                    disabled={!this.state.existFilteredMachines}
                    className={classes.formControl}
                    id="demo-simple-select-outlined"
                    value={user.OverviewFilter}
                    onChange={this.changeFilterMode}
                  >
                    <MenuItem value={0}>
                      <em>{t('pages:overview.allMachines')}</em>
                    </MenuItem>
                    <MenuItem value={1}>{t('pages:overview.filter.selectedMachines')}</MenuItem>
                  </Select>
                )}
                label={`${t('pages:overview.filter.filter')}:`}
                labelPlacement="start"
              />
            </FormGroup>
          </Grid>
          <Grid item style={{ paddingLeft: 16 }}>
            <Link className={classes.link} to="/machines">
              <EditIcon />
            </Link>
          </Grid>
        </Grid>

        <Grid container justify="center">
          <Grid item className={classes.titleHeader}>
            <Typography align="center" variant="h4">
              {t('components:navigation.overview')}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.datePickerWrapper}>
            <DateRangePicker
              triggerParentUpdate={this.setDateRange}
              rangeType="week"
              startDate={this.state.dateRange.split('~')[0]}
              endDate={this.state.dateRange.split('~')[1]}
            />
          </Grid>
        </Grid>
        <Grid container spacing={spacing} justify="center">
          <>
            <Grid item>
              <OverviewHeader
                loading={!this.state.loaded}
                avgData={this.state.avgData}
                prevAvgData={this.state.prevAvgData}
                currentWidth={currentWidth}
                convLabels={this.state.conversionOption.valueLabelList}
                username={user ? user.userName : ''}
                machinesSelected={this.state.selectedMachinesList.length}
                totalNumOfMachines={this.state.totalNumOfMachines}
                showFiltered={this.state.machineFilter > 0}
                filterExists={this.state.existFilteredMachines}
              />
            </Grid>
            <Grid item xs={12}>
              <OverviewMachineMonitor
                loaded={this.state.loaded}
                specificMachines={this.state.machineFilter > 0}
                progressData={this.state.progressData}
                momentList={momentList}
                currentWidth={currentWidth}
                machineList={this.state.selectedMachinesList}
              />
            </Grid>
            <Grid item xs={12}>
              <ChartPaper
                loaded={this.state.loaded}
                datasets={[{ labels: momentList, datasets: this.state.chartDatasets }]}
                isTabOrMobile={isTabOrMobile}
                name={t('pages:overview.production')}
                customClasses={classes.chartPaper}
                component={
                  volChart
                }
              />
            </Grid>
          </>
        </Grid>
      </main>
    );
  }
}
Overview.contextType = AuthContext;
export default withWidth()(withStyles(styles)(withTranslation()(Overview)));
