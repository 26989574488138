import React from 'react';

import {
  Grid, Typography, Paper, Tooltip, Theme, createStyles, withStyles, WithStyles,
} from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import RemoveIcon from '@material-ui/icons/Remove';
import { ThemeStyle } from '@material-ui/core/styles/createTypography';
import Loading from '../Loading';

const styles = (theme: Theme) => createStyles({
  card: {
    padding: 8,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  [theme.breakpoints.down('sm')]: {
    card: {
      padding: 0,
      paddingBottom: 4,
      paddingTop: 4,
    },
  },
});

interface Props extends WithStyles<typeof styles> {
  loading: boolean;
  currentWidth: Breakpoint;
  data: {
    val: string;
    change: number;
    useChange: boolean;
    label: string;
    iconPath: string | JSX.Element;
  }[];
  fullSize?: boolean;
}

const KpiPanels = (props: Props) => {
  const { classes, currentWidth, loading } = props;
  const isMobile = (currentWidth === 'xs');

  const createCard = (
    value: string,
    change: number,
    useChange: boolean,
    label: string,
    iconPath: string | JSX.Element,
    fullSize?: boolean,
  ) => {
    let changeElem: JSX.Element | undefined;
    if (useChange) {
      const showChange = Number.isFinite(change) && !loading;
      let changeIcon: JSX.Element;
      if (!showChange) {
        changeIcon = <RemoveIcon style={{ color: 'black' }} />;
      } else if (change > 0) {
        changeIcon = <ArrowDropUpIcon style={{ color: 'green' }} />;
      } else if (change < 0) {
        changeIcon = <ArrowDropDownIcon style={{ color: 'red' }} />;
      } else {
        changeIcon = <DragHandleIcon style={{ color: 'black' }} />;
      }
      changeElem = (
        <Grid item xs={6}>
          {changeIcon}
          {showChange ? (
            <>
              <Typography style={isMobile ? { fontSize: 12, userSelect: 'none' } : {}}>
                {' '}
                {`${change.toFixed(0)}%`}
              </Typography>
            </>
          ) : null}

        </Grid>
      );
    }
    let typVariant: ThemeStyle = 'h4';
    if (isMobile) typVariant = 'body1';
    else if (isWidthDown('sm', currentWidth)) typVariant = 'h5';

    return (
      <Grid container item xs key={label} alignItems="stretch">
        <Tooltip title={isMobile ? label : ''}>
          <Paper className={classes.card}>
            <Grid item container alignItems="center">
              <Grid item xs={useChange || fullSize === true ? 6 : 12}>
                {typeof iconPath === 'string' ? (
                  <div style={{ paddingTop: 8, paddingLeft: 8 }}>
                    <svg width="100%" height="4rem" viewBox="0 0 24 24">
                      <path fill="#000000" d={iconPath} />
                    </svg>
                  </div>
                ) : iconPath}
              </Grid>
              {changeElem}
              <Grid item xs={fullSize === true ? 6 : 12}>
                {loading ? <Loading size={20} />
                  : (
                    <Typography gutterBottom={fullSize !== true} color="textPrimary" variant={typVariant} style={{ userSelect: isMobile ? 'none' : 'auto' }}>
                      {value}
                    </Typography>
                  )
                }
              </Grid>
              {isMobile ? null : (
                <Grid item xs={12}>
                  <Typography style={{ fontSize: isWidthDown('sm', currentWidth) ? 14 : '' }}>{label}</Typography>
                </Grid>
              )
              }
            </Grid>
          </Paper>
        </Tooltip>
      </Grid>
    );
  };

  return (
    <Grid item container spacing={(isWidthDown('md', currentWidth) && isWidthUp('sm', currentWidth)) || props.fullSize === true ? 2 : 1} alignItems="stretch">
      {props.data.map(card => (
        createCard(card.val, card.change, card.useChange, card.label, card.iconPath, props.fullSize)
      ))}
    </Grid>
  );
};

export default withStyles(styles)(KpiPanels);
