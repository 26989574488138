import {
  createStyles, Grid, Theme, WithStyles, withStyles, Typography,
} from '@material-ui/core';
import React from 'react';
import { ChartData } from 'react-chartjs-2';
import withWidth, { WithWidthProps, isWidthDown } from '@material-ui/core/withWidth';
import axios, { CancelTokenSource } from 'axios';
import moment from 'moment';
import { withTranslation, WithTranslation } from 'react-i18next';
import { GridSpacing } from '@material-ui/core/Grid';
import {
  SpeciesData, M3Data, parseMultipleValues, createDoughnut, createChartData, createOptionsCustom,
} from '../../../util/dataFormatting';
import { AuthInterface, AuthContext } from '../../../components/AuthContext';
import ChartExportButton from '../../../components/Charts/ChartExportButton';
import DoughnutChart from '../../../components/Charts/DoughnutChart';
import BarChart from '../../../components/Charts/BarChart';
import { createConvFunctions, ChartUnitOptions, convertHprTable } from '../../../util/conversionUtil';
import CustomIcons from '../../../util/customIcons';
import KpiPanels from '../../../components/Charts/KpiPanels';
import MachineProductTable from '../../../components/Charts/MachineProductTable';
import MachineSpeciesTable from '../../../components/Charts/MachineSpeciesTable';
import ChartPaper from '../../../components/Charts/ChartPaper';

const styles = (theme: Theme) => createStyles({
  paper: {
    width: '100%',
    height: '100%',
  },
});

const convOpt: { [index: string]: ChartUnitOptions } = {};
convOpt.metric = { valueLabelList: ['m\xB3', 'm\xB3', '', '', 'cm', 'mm'] };
convOpt.imperial = {
  convList: [
    { from: 'm3', to: 'ft3' },
    { from: 'm3', to: 'ft3' },
    null,
    null,
    { from: 'cm', to: 'in' },
    { from: 'mm', to: 'in' },
  ],
  valueLabelList: ['ft\xB3', 'ft\xB3', '', '', 'in', 'in'],
};

const defaultDataset = {
  labels: [],
  datasets: [
    {
      type: 'bar',
      label: '',
      backgroundColor: 'rgba(138,173,61,1)',
      borderColor: 'rgba(138,173,61,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(138,173,61,0.6)',
      hoverBorderColor: 'rgba(138,173,61,1)',
      data: [],
    },
  ],
};

const defaultDataset2 = {
  labels: [],
  datasets: [
    {
      type: 'doughnut',
      label: '',
      backgroundColor: 'rgba(138,173,61,1)',
      borderColor: 'rgba(138,173,61,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(138,173,61,0.6)',
      hoverBorderColor: 'rgba(138,173,61,1)',
      data: [],
    },
  ],
};

interface Props extends WithStyles<typeof styles>, WithWidthProps, WithTranslation {
  machineName: string;
  machineId: string;
  dateRange: string;
  rendered: boolean;
}

interface State {
  cancelToken: CancelTokenSource;
  isLoading1: boolean;
  isLoading2: boolean;
  isLoading3: boolean;
  summaryValues: number[];
  chartDataList: ChartData<Chart.ChartData>[];
  speciesDataList: Map<string, SpeciesData[]>;
  productsDataList: Map<string, SpeciesData[]>;
  doughnutData: ChartData<Chart.ChartData>;
  conversionOption: ChartUnitOptions;
}

class MachineProduction extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    const chartDataList = new Array(6).fill(defaultDataset);
    const speciesDataList = new Map<string, SpeciesData[]>();
    const productsDataList = new Map<string, SpeciesData[]>();
    const doughnutData = defaultDataset2;
    this.state = {
      cancelToken: axios.CancelToken.source(),
      isLoading1: true,
      isLoading2: true,
      isLoading3: true,
      summaryValues: [0, 0, 0, 0, 0, 0, 0],
      chartDataList,
      speciesDataList,
      productsDataList,
      doughnutData,
      conversionOption: convOpt.metric,
    };
    this.retrieveData = this.retrieveData.bind(this);
  }

  public componentDidMount() {
    this.retrieveData();
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.dateRange !== prevProps.dateRange) {
      this.retrieveData();
    }
  }

  public componentWillUnmount() {
    this.state.cancelToken.cancel();
  }

  private retrieveData = () => {
    const { api, user }: AuthInterface = this.context;

    let { conversionOption } = this.state;
    conversionOption = convOpt[user.UnitType];
    const options = createConvFunctions(conversionOption);

    this.state.cancelToken.cancel();
    this.setState({
      isLoading1: true, isLoading2: true, isLoading3: true, cancelToken: axios.CancelToken.source(),
    }, () => {
      api.getOneMachine<M3Data>('/production', this.props.machineId, this.props.dateRange,
        this.state.cancelToken).then((response) => {
        if (response.length === 0) {
          this.setState({
            isLoading1: false,
            summaryValues: [0, 0, 0, 0, 0, 0],
            chartDataList: new Array(6).fill(defaultDataset),
          });
        } else {
          const parsed = parseMultipleValues(response.map(({
            harvestDate: time,
            operator: label,
            m3SubSum: value1,
            m3SobSum: value2,
            stemCount: value3,
            logCount: value4,
            avgLogLength: value5,
            avgLogDiameter: value6,
          }) => ({ time: moment(time).format('M/D/YY'), label: 'Total', value: [value1, value2, value3, value4, value5, value6] })), options, this.props.dateRange);
          const charts = createChartData(6, parsed.labels, parsed.operatorList, parsed.dataMap);
          this.setState({
            isLoading1: false,
            chartDataList: charts,
            summaryValues: parsed.summary,
          });
        }
      }).catch((e) => {
        if (!axios.isCancel(e)) {
          this.setState({
            isLoading1: false,
          });
        }
      });

      api.getOneMachine<SpeciesData>('/production/species', this.props.machineId, this.props.dateRange,
        this.state.cancelToken).then((response) => {
        if (response.length === 0) {
          this.setState({
            isLoading2: false,
            speciesDataList: new Map<string, SpeciesData[]>(),
            doughnutData: defaultDataset2,
          });
        } else {
          const speciesDataList = new Map<string, SpeciesData[]>();
          convertHprTable(response, options);
          response.forEach((node) => {
            speciesDataList.set(node.speciesIdentity, [node]);
          });
          this.setState({
            speciesDataList,
            doughnutData: createDoughnut(speciesDataList),
            isLoading2: false,
          });
        }
      }).catch((e) => {
        if (!axios.isCancel(e)) {
          this.setState({
            isLoading2: false,
          });
        }
      });


      api.getOneMachine<SpeciesData>('/production/speciesandproducts', this.props.machineId, this.props.dateRange,
        this.state.cancelToken).then((response) => {
        if (response.length === 0) {
          this.setState({
            isLoading3: false,
            productsDataList: new Map<string, SpeciesData[]>(),
          });
        } else {
          const productsDataList = new Map<string, SpeciesData[]>();
          convertHprTable(response, options);
          response.forEach((node) => {
            const isIn = productsDataList.get(node.speciesIdentity);
            isIn ? isIn.push(node) : productsDataList.set(node.speciesIdentity, [node]);
          });
          this.setState({
            productsDataList,
            isLoading3: false,
          });
        }
      }).catch((e) => {
        if (!axios.isCancel(e)) {
          this.setState({
            isLoading3: false,
          });
        }
      });
    });
  }

  public render() {
    const { width, t } = this.props;
    const isMobile = ((width || 'xs') === 'xs');
    const currentWidth = width || 'xs';
    const isTabOrMobile = isWidthDown('sm', currentWidth);
    const {
      chartDataList, speciesDataList, productsDataList, doughnutData, summaryValues,
    } = this.state;

    let chartHeigth = 250;
    let spacing: GridSpacing = 4;
    if (isMobile) {
      chartHeigth = 200;
      spacing = 1;
    } else if (isTabOrMobile) {
      chartHeigth = 300;
      spacing = 2;
    }

    const kpiData = [{
      val: (summaryValues[0].toFixed(0)) + this.state.conversionOption.valueLabelList[0],
      change: 0,
      useChange: false,
      label: t('components:kpi.totalVolume'),
      iconPath: CustomIcons.volIconPath,
    }, {
      val: (summaryValues[0] && summaryValues[2] ? (summaryValues[0] / summaryValues[2]).toFixed(2) : '0') + this.state.conversionOption.valueLabelList[0],
      change: 0,
      useChange: false,
      label: t('components:kpi.averageStemVol'),
      iconPath: CustomIcons.avgIconPath,
    }, {
      val: summaryValues[2].toFixed(0),
      change: 0,
      useChange: false,
      label: t('components:kpi.totalStems'),
      iconPath: CustomIcons.stemsPath,
    }, {
      val: summaryValues[3].toFixed(0),
      change: 0,
      useChange: false,
      label: t('components:kpi.totalLogs'),
      iconPath: CustomIcons.logsPath,
    }];
    const exportTitles = [t('components:chart.production.volSpecExp'), t('components:chart.production.volume'), t('components:kpi.totalStems'), t('components:kpi.totalLogs'), t('components:chart.production.species'), t('components:chart.production.products')];
    return (
      <>
        {this.state.isLoading1 || this.state.isLoading2 || this.state.isLoading3 ? null
          : (
            <div>
              <ChartExportButton
                barData={[doughnutData, chartDataList[0],
                  chartDataList[2],
                  chartDataList[3]]}
                tableData={[speciesDataList, productsDataList]}
                filename={`${t('components:chart.production.production')}_${this.props.machineName}`}
                tooltip={t('components:button.downloadAll')}
                titles={exportTitles}
                hidden={false}
              />
            </div>
          )
        }
        <Grid container spacing={spacing}>
          <Grid item xs={12}>
            <KpiPanels currentWidth={currentWidth} data={kpiData} loading={this.state.isLoading1} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ChartPaper
              loaded={!this.state.isLoading2}
              tableData={[speciesDataList]}
              isTabOrMobile={isTabOrMobile}
              name={`${this.props.machineName}_${t('components:chart.production.species')}`}
              tooltip={t('components:misc.downloadRawData')}
              component={(
                <>
                  <Typography align="center" variant="h6">{t('components:chart.production.species')}</Typography>
                  <MachineSpeciesTable
                    species={speciesDataList}
                    labels={this.state.conversionOption.valueLabelList}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ChartPaper
              loaded={!this.state.isLoading2}
              datasets={[doughnutData]}
              isTabOrMobile={isTabOrMobile}
              name={`${this.props.machineName}_${t('components:chart.production.volSpecExp')}`}
              component={(
                <DoughnutChart
                  height={300}
                  datasets={doughnutData}
                  unitLabel={this.state.conversionOption.valueLabelList[0]}
                  options={{
                    title: { text: t('components:chart.production.volPerSpec'), display: true },
                    legend: { position: 'right' },
                    maintainAspectRatio: false,
                  }}
                  key={this.props.rendered + String(this.state.isLoading2)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <ChartPaper
              loaded={!(this.state.isLoading3 || this.state.isLoading2)}
              tableData={[productsDataList]}
              isTabOrMobile={isTabOrMobile}
              name={`${this.props.machineName}_${t('components:chart.production.products')}`}
              tooltip={t('components:misc.downloadRawData')}
              component={(
                <div style={{ minHeight: 300 }}>
                  {!(this.state.isLoading2 || this.state.isLoading3)
                    ? (
                      <>
                        <Typography align="center" variant="h6">{t('components:chart.production.products')}</Typography>
                        <MachineProductTable
                          list={productsDataList}
                          summary={speciesDataList}
                          labels={this.state.conversionOption.valueLabelList}
                        />
                      </>
                    )
                    : null}
                </div>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ChartPaper
              loaded={!this.state.isLoading1}
              datasets={[chartDataList[0]]}
              isTabOrMobile={isTabOrMobile}
              name={`${this.props.machineName}_${t('components:chart.production.volume')}`}
              component={
                <BarChart datasets={chartDataList[0]} height={chartHeigth} options={createOptionsCustom(this.state.conversionOption.valueLabelList[0], { num: 300, label: t('components:chart.goal') }, 1)} title={`${t('components:chart.production.volume')} (${t('components:chart.production.sub')})`} key={chartHeigth.toString() + this.state.isLoading1} />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ChartPaper
              loaded={!this.state.isLoading1}
              datasets={[chartDataList[2]]}
              isTabOrMobile={isTabOrMobile}
              name={`${this.props.machineName}_${t('components:chart.production.stems')}`}
              component={
                <BarChart datasets={chartDataList[2]} height={chartHeigth} options={createOptionsCustom(this.state.conversionOption.valueLabelList[2])} title={t('components:chart.production.stems')} key={chartHeigth.toString() + this.state.isLoading1} />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ChartPaper
              loaded={!this.state.isLoading1}
              datasets={[chartDataList[3]]}
              isTabOrMobile={isTabOrMobile}
              name={`${this.props.machineName}_${t('components:chart.production.logs')}`}
              component={
                <BarChart datasets={chartDataList[3]} height={chartHeigth} options={createOptionsCustom(this.state.conversionOption.valueLabelList[3])} title={t('components:chart.production.logs')} key={chartHeigth.toString() + this.state.isLoading1} />
              }
            />
          </Grid>
        </Grid>
      </>
    );
  }
}
MachineProduction.contextType = AuthContext;

export default withWidth()(withStyles(styles)(withTranslation()(MachineProduction)));
