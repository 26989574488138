import React from 'react';
import Table from '@material-ui/core/Table';
import {
  WithStyles, withStyles, Theme, createStyles, TableHead,
  TableRow, TableCell, TableBody,
} from '@material-ui/core';
import { withTranslation, WithTranslation } from 'react-i18next';
import { SpeciesData } from '../../util/dataFormatting';
import { TableSummary, TableCellNormal, combineData } from '../../util/MapToTable';

const styles = (theme: Theme) => createStyles({
  table: {
    margin: theme.spacing(2),
    marginTop: 0,
    maxHeight: 300 - 32 - 16,
    overflow: 'auto',
  },
  [theme.breakpoints.down('xs')]: {
    table: {
      margin: theme.spacing(0),
      marginTop: 0,
    },
  },
});

const TableCellHead = withStyles((theme: Theme) => createStyles({
  head: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.paper,
    color: 'black',
    fontSize: 14,
    fontWeight: 'bold',
    paddingRight: theme.spacing(1),
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);


interface Props extends WithStyles<typeof styles>, WithTranslation {
  species: Map<string, SpeciesData[]>;
  labels: string[];
}

export const MachineSpeciesTable = (props: Props) => {
  const { classes, labels, t } = props;

  const array = Array.from(props.species.entries());
  let summary: SpeciesData = {
    speciesGroupName: t('components:charts.summary'), speciesIdentity: '0', m3Sub: 0, m3Sob: 0, numberOfStems: 0, numberOfLogs: 0, avgLogDiameter: 0, avgLogLength: 0, operatorName: '', productName: '',
  };

  const rows = array.map((dataList) => {
    const data = dataList[1][0];
    summary = combineData(summary, data);
    return (
      <TableRow key={data.speciesGroupName}>
        <TableCellNormal align="center">{data.speciesGroupName}</TableCellNormal>
        <TableCellNormal align="right">{Number(data.m3Sub).toFixed(2)}</TableCellNormal>
        <TableCellNormal align="right">{Number(data.m3Sob).toFixed(2)}</TableCellNormal>
        <TableCellNormal align="right">{(data.m3Sub / data.numberOfStems).toFixed(2)}</TableCellNormal>
        <TableCellNormal align="right">{data.numberOfStems}</TableCellNormal>
        <TableCellNormal align="right">{data.numberOfLogs}</TableCellNormal>
        <TableCellNormal align="right">{Number(data.avgLogLength).toFixed(0)}</TableCellNormal>
        <TableCellNormal align="right">{Number(data.avgLogDiameter).toFixed(0)}</TableCellNormal>
      </TableRow>
    );
  });
  if (rows.length !== 0) summary.avgLogLength /= summary.numberOfLogs;

  return (
    <div className={classes.table}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCellHead>{`${t('components:chart.production.species')}`}</TableCellHead>
            <TableCellHead align="right">{`${t('components:chart.production.volume')} (${labels[0]}${t('components:chart.production.sub')})`}</TableCellHead>
            <TableCellHead align="right">{`${t('components:chart.production.volume')} (${labels[1]}${t('components:chart.production.sob')})`}</TableCellHead>
            <TableCellHead align="right">{`${t('components:chart.production.avgStem')} (${labels[0]}${t('components:chart.production.sub')})`}</TableCellHead>
            <TableCellHead align="right">{`${t('components:chart.production.stems')} ${labels[2]}`}</TableCellHead>
            <TableCellHead align="right">{`${t('components:chart.production.logs')} ${labels[3]}`}</TableCellHead>
            <TableCellHead align="right">{`${t('components:chart.production.avgLen')} (${labels[4]})`}</TableCellHead>
            <TableCellHead align="right">{`${t('components:chart.production.avgDiam')} (${labels[5]})`}</TableCellHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows}
          <TableRow>
            <TableSummary align="center">{t('components:chart.summary')}</TableSummary>
            <TableSummary align="right">{Number(summary.m3Sub).toFixed(2)}</TableSummary>
            <TableSummary align="right">{Number(summary.m3Sob).toFixed(2)}</TableSummary>
            <TableSummary align="right">{summary.numberOfStems !== 0 ? (summary.m3Sub / summary.numberOfStems).toFixed(2) : '0'}</TableSummary>
            <TableSummary align="right">{summary.numberOfStems}</TableSummary>
            <TableSummary align="right">{summary.numberOfLogs}</TableSummary>
            <TableSummary align="right">{Number(summary.avgLogLength).toFixed(0)}</TableSummary>
            <TableSummary align="right">{Number(summary.avgLogDiameter).toFixed(0)}</TableSummary>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default withStyles(styles)(withTranslation()(MachineSpeciesTable));
