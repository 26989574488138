import { createMuiTheme } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';
import grey from '@material-ui/core/colors/grey';

export const customTheme = createMuiTheme({
  palette: {
    primary: {
      light: grey[200],
      main: grey[500],
      dark: grey[900],
    },
    secondary: indigo,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

export const secondTheme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgb(197, 19, 61)',
    },
    secondary: grey,
  },
});
