import { Link } from 'react-router-dom';
import {
  Box, createStyles, Grid, Theme, Typography, Button, Menu, MenuItem, WithStyles,
  withStyles,
} from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { isWidthDown } from '@material-ui/core/withWidth';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Machine } from '../../pages/Machines';
import { ProgressData } from '../../pages/Overview';
import { ColorList, ColorPicker } from '../../util/colors';
import { createOptions, createOptionsCustom } from '../../util/dataFormatting';
import ProgressChart from './ProgressChart';
import CustomChart from './CustomChart';
import ChartPaper from './ChartPaper';

const styles = (theme: Theme) => createStyles({
  export: {
    position: 'relative',
  },
  chartPaper: {
    padding: theme.spacing(2),
  },
  link: {
    textDecoration: 'none',
    color: 'black',
  },
  [theme.breakpoints.down('xs')]: {
    chartPaper: {
      padding: theme.spacing(0),
    },
  },
});

interface Props extends WithStyles<typeof styles> {
  loaded: boolean;
  specificMachines: boolean;
  machineList: Machine[];
  progressData: ProgressData[];
  momentList: string[];
  currentWidth: Breakpoint;

}

const OverviewMachineMonitor = (props: Props) => {
  const { classes } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [currentMachine, setCurrentMachine] = React.useState<string>('');
  const [currentMachineLink, setCurrentMachineLink] = React.useState<string>('');
  const { t } = useTranslation();

  const isMobile = (props.currentWidth === 'xs');
  const isTabOrMobile = isWidthDown('sm', props.currentWidth);

  const getSummarizedAreaChart = (machine: ProgressData) => {
    const work: number[] = [];
    const down: number[] = [];
    Array.from(machine.days.values()).slice(6).forEach((day) => {
      work.push(day.work);
      down.push(day.down);
    });
    work.push(0);
    down.push(0);
    const workDataset: Chart.ChartDataSets = {
      type: 'line', label: t('components:chart.monitoring.work'), data: work, backgroundColor: ColorPicker.primaryFaded[3], lineTension: 0, fill: '-1', stack: 'stack1',
    };
    const downDataset: Chart.ChartDataSets = {
      type: 'line', label: t('components:chart.monitoring.downtime'), data: down, backgroundColor: ColorPicker.complementFaded[3], lineTension: 0, stack: 'stack1',
    };
    return [downDataset, workDataset];
  };

  const handleMenu = (name: string, link: string) => (event: React.MouseEvent<HTMLElement>) => {
    setCurrentMachine(name);
    setCurrentMachineLink(link);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const menu = (
    <Menu
      id="machineMenu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem>
        <Link className={classes.link} to={`/machines/${currentMachineLink}/monitoring`} style={{ height: '100%', width: '100%' }}>
          <Typography>{currentMachine}</Typography>
        </Link>
      </MenuItem>
    </Menu>
  );

  const machineRow = (list: JSX.Element[], rowNr: number,
    currentWidth: Breakpoint, machine?: Machine) => {
    const list1 = list.slice(0, 3);
    const list2 = list.slice(3);
    const machineLink = machine ? machine._source.externalId : ''; // eslint-disable-line no-underscore-dangle
    const machineName = machine ? machine._source.label : ''; // eslint-disable-line no-underscore-dangle
    const children = (
      <Grid container alignItems="center" direction="row" wrap="nowrap" style={{ height: '100%', width: '100%' }}>
        <Grid item>

          {machine ? (
            <Box style={{
              marginRight: 10, textAlign: 'center', width: 25, height: 25, color: 'white', backgroundColor: ColorList[rowNr], lineHeight: '25px',
            }}
            >
              {rowNr + 1}
            </Box>
          ) : null}
        </Grid>
        {isTabOrMobile ? null : (
          <Grid item>
            <Typography style={{ fontSize: 12, color: '#666' }}>
              {machineName}
            </Typography>
          </Grid>
        )}
      </Grid>
    );

    const label = isTabOrMobile ? (
      <Grid container justify="center" alignItems="center">
        <Button onClick={handleMenu(machineName, machineLink)}>
          {children}
        </Button>
      </Grid>
    ) : (
      <Link className={classes.link} to={`/machines/${machineLink}/monitoring`} style={{ height: '100%', width: '100%' }}>
        {children}
      </Link>
    );
    return (
      <Grid item container xs={12} key={machineName} style={rowNr % 2 !== 1 ? { backgroundColor: '#FAF9F9' } : {}}>
        <Grid item container xs={6} sm={6}>
          <Grid container item xs={3} justify="center" alignItems="center">
            {machine ? label : null}
          </Grid>
          {list1.map(elem => (
            <Grid item xs={3} key={elem.key ? elem.key : ''}>
              {elem}
            </Grid>
          ))}
        </Grid>
        <Grid item container xs={6} sm={6}>
          {list2.map(elem => (
            <Grid item xs={3} key={elem.key ? elem.key : ''}>
              {elem}
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  };

  let timeChart: JSX.Element = <div style={{ minHeight: 200 }} />;
  let datasets: Chart.ChartDataSets[] = [];
  let labels: string[] = [];
  if (props.loaded) {
    const rows: JSX.Element[][] = [];
    props.progressData.forEach((machine, rowNr) => {
      const row: JSX.Element[] = [];
      let i = 0;
      machine.days.forEach((day, str) => {
        if (props.momentList.includes(str)) {
          const remaining24hDay = 24 - day.work - day.down;
          row.push(
            <div
              key={machine.machineName + props.momentList[i]}
              style={{
                paddingRight: 1, borderLeftStyle: 'solid', borderLeftColor: 'rgba(0, 0, 0, 0.1)', borderLeftWidth: 2, height: '100%',
              }}
            >
              <ProgressChart
                options={createOptions('Volume M3/Day')}
                name="Volume"
                width={150}
                height={isMobile ? 30 : 50}
                data={[day.work, day.down, remaining24hDay > 0 ? remaining24hDay : 0]}
                backgrundColor={rowNr % 2 !== 1 ? '#FAF9F9' : 'white'}
                key={`${isMobile}`}
              />
            </div>,
          );
          i += 1;
        }
      });
      rows.push(row);
    });

    let height = 50;
    if (isMobile) height = 100;
    else if (isTabOrMobile) height = 75;

    const opt = createOptionsCustom(t('components:time.hours'), undefined, 2);
    labels = [...props.momentList];
    labels.unshift('');
    opt.scales = {
      xAxes: [{ ticks: { min: labels[1] } }],
      yAxes: [{ stacked: true, ticks: { min: 0 }, scaleLabel: { display: true, labelString: t('components:time.hours'), fontSize: 14 } }],
    } as Chart.ChartScales;
    if (opt.tooltips) {
      opt.tooltips.mode = 'index';
      opt.tooltips.intersect = false;
      opt.tooltips.filter = item => (item.xLabel !== undefined);
      opt.tooltips.callbacks = {
        label: item => `${typeof item.yLabel === 'number' ? Math.floor(item.yLabel) : ''}h ${typeof item.yLabel === 'number' ? ((item.yLabel - Math.floor(item.yLabel)) * 60).toFixed(0) : ''}m`,
      };
    }
    datasets = getSummarizedAreaChart(props.progressData[0]);
    const allMachinesLines = props.specificMachines ? <div /> : (
      <CustomChart
        chartType="Bar"
        width={200}
        height={height}
        datasets={{
          labels,
          datasets,
        }}
        options={opt}
        key={height.toString()}
        numberBoxLegend={false}
        origColor={[ColorPicker.complementFaded[3], ColorPicker.primaryFaded[3]]}
        dimColor={[ColorPicker.complementDim[3], ColorPicker.primaryDim[3]]}
      />
    );

    timeChart = (
      <>
        <Typography variant="h6" align="center">{t('components:chart.monitoring.monitoring')}</Typography>
        <Grid container justify="center">
          {props.specificMachines ? (
            <>
              <Grid item container justify="center" alignItems="center" xs={12} direction="row" style={{ paddingBottom: 8 }}>
                <Grid container xs={4} sm={3} item direction="row" justify="center" alignItems="center">
                  <Grid item>
                    <Box style={{
                      marginRight: 10, textAlign: 'center', width: 25, height: 25, color: 'white', backgroundColor: ColorPicker.primary[3],
                    }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography style={{ fontSize: 12, color: '#666', userSelect: 'none' }}>
                      {t('components:chart.monitoring.work')}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container xs={4} sm={3} item direction="row" justify="center" alignItems="center">
                  <Grid item>
                    <Box style={{
                      marginRight: 10, textAlign: 'center', width: 25, height: 25, color: 'white', backgroundColor: ColorPicker.complement[3],
                    }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography style={{ fontSize: 12, color: '#666', userSelect: 'none' }}>
                      {t('components:chart.monitoring.downtimePercent')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {menu}
              {props.machineList.map((machine, index) => (
                machineRow(rows[index], index, props.currentWidth, machine)))}
              {machineRow(props.momentList.map(date => (
                <Typography key={date} variant="body1" align="center" style={{ color: '#666', fontSize: isMobile ? 10 : 12 }}>
                  {date}
                </Typography>
              )), 1, props.currentWidth)}
            </>
          ) : (
            <Grid item xs={12}>
              {allMachinesLines}
            </Grid>
          )}
        </Grid>
      </>
    );
  }

  return (
    <ChartPaper
      loaded={props.loaded}
      datasets={props.loaded ? [{ labels, datasets }] : undefined}
      customClasses={classes.chartPaper}
      isTabOrMobile={isTabOrMobile}
      name={t('components:chart.monitoring.monitoring')}
      component={
        timeChart
      }
    />
  );
};

export default withStyles(styles)(OverviewMachineMonitor);
