import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => ({
  root: {
    textDecoration: 'none',
  },
  buttonLight: {
    color: theme.palette.grey[900],
  },
  buttonDark: {
    color: theme.palette.grey[300],
  },
  icon: {
    marginRight: theme.spacing(),
  },
});

export default styles;
