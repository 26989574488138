import React from 'react';
import {
  RouteComponentProps, withRouter,
} from 'react-router-dom';
import { Translation } from 'react-i18next';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
  Grid, WithStyles, withStyles, Theme, createStyles, Typography,
} from '@material-ui/core';
import DateRangePicker from '../../../components/DateRangePicker';
import { Machine } from '..';
import MachineMonitoring from './MachineMonitoring';
import MachineProduction from './MachineProduction';

const styles = (theme: Theme) => createStyles({
  root: {
    position: 'relative',
    overflow: 'hidden',
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1),
    alignItems: 'center',
    width: '100%',
  },
  [theme.breakpoints.up('md')]: {
    root: {
      paddingTop: theme.spacing(0),
      padding: 30,
    },
  },
  header: {
    maxWidth: 1600,
    paddingTop: 8,
  },
  tabLink: {
    color: '#222',
    textDecoration: 'none',
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <Typography
      style={{ width: '100%' }}
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </Typography>
  );
}

interface Props extends RouteComponentProps, WithStyles<typeof styles> {
  machine: Machine;
  machineID: string;
  page: string;
  startDate: string;
  endDate: string;
}

interface State {
  startDate: string;
  endDate: string;
  tabValue: number;
}

class Analysis extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.props.history.replace(`/machines/${this.props.machineID}/${this.props.page}/${props.startDate}/${props.endDate}`);
    let tabValue;
    switch (props.page) {
      case 'monitoring': tabValue = 0;
        break;
      case 'production': tabValue = 1;
        break;
      default: tabValue = 0;
    }
    this.state = {
      startDate: props.startDate,
      endDate: props.endDate,
      tabValue,
    };
  }

  public updateDateRange = (dateRange: string) => {
    const range = dateRange.split('~');
    sessionStorage.setItem('dateRange', JSON.stringify(dateRange));
    this.props.history.replace(`/machines/${this.props.machineID}/${this.props.page}/${range[0]}/${range[1]}`);
    this.setState({
      startDate: range[0],
      endDate: range[1],
    });
  }

  private changeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    let page;
    switch (newValue) {
      case 0: page = 'monitoring'; // No translation for url!
        break;
      case 1: page = 'production';
        break;
      default: page = 'monitoring';
    }
    this.props.history.replace(`/machines/${this.props.machineID}/${page}/${this.state.startDate}/${this.state.endDate}`);
    this.setState({ tabValue: newValue });
  };

  public render() {
    const { classes, machine } = this.props;
    const title = machine ? machine._source.label : ''; //eslint-disable-line
    const domain = machine ? machine._source.domain : ''; //eslint-disable-line
    const { startDate, endDate } = this.state;

    const dateRangeString = `${moment(startDate).format('YYYY-MM-DD')}~${moment(endDate).format('YYYY-MM-DD')}`;

    /* eslint-disable no-underscore-dangle */
    return (
      <Translation>
        {t => (
          <main className={classes.root}>
            <Grid container justify="center" className={classes.header}>
              <Grid item style={{ paddingBottom: 8 }}>
                <Paper square>
                  <Tabs
                    value={this.state.tabValue}
                    onChange={this.changeTab}
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    <Tab label={t('analysis.monitoring')} />
                    <Tab label={t('analysis.production')} />
                  </Tabs>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4" align="center">{title}</Typography>
              </Grid>
              <Grid item xs={12} style={{ paddingBottom: 8 }}>
                <Typography align="center">{domain}</Typography>
              </Grid>
              <Grid item xs={12} style={{ paddingBottom: 8 }}>
                <DateRangePicker startDate={startDate} endDate={endDate} triggerParentUpdate={this.updateDateRange} rangeType="range" />
              </Grid>
            </Grid>
            <Grid container justify="center">
              <TabPanel value={this.state.tabValue} index={0}>
                <MachineMonitoring
                  machineId={machine._source.externalId}
                  machineName={machine._source.label}
                  dateRange={dateRangeString}
                  rendered={this.state.tabValue === 0}
                />
              </TabPanel>
              <TabPanel value={this.state.tabValue} index={1}>
                <MachineProduction
                  machineId={machine._source.externalId}
                  machineName={machine._source.label}
                  dateRange={dateRangeString}
                  rendered={this.state.tabValue === 1}
                />
              </TabPanel>
            </Grid>
          </main>
        )}
      </Translation>
    );
  }
}

export default withStyles(styles)(withRouter(Analysis));
