import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  Button,
  Input,
  LinearProgress,
  Grid,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  InputAdornment,
} from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthContext } from './AuthContext';
import { SFFile, snackbarVariant } from '../pages/Files/Files';

const styles = (theme: Theme) => createStyles({
  root: {
    marginTop: theme.spacing(3),
    alignItems: 'center',
    justify: 'center',
    width: '100%',
  },
  titleHeader: {
    backgroundColor: theme.palette.primary.light,
    margin: 20,
    marginBottom: 0,
    paddingTop: 5,
    paddingBottom: 5,
    width: '100%',
  },
  button: {
    padding: 10,
    width: '100%',
    backgroundColor: theme.palette.grey[100],
    borderColor: 'green',
    maxHeight: 52,
    height: 52,
  },
  input: {
    display: 'none',
  },
});

interface Props extends WithStyles<typeof styles> {
  files: SFFile[];
  triggerParentUpdate: (filename: string) => void;
  setNotification: (text: string, type?: snackbarVariant) => void;
}

interface UploadObject {
  file: File | null;
  name: string;
}

const FileUpload = (props: Props) => {
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [uploadDone, setUploadDone] = useState(false);
  const [uploadProgress, setUploadProgeress] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);

  const [editedFilename, setEditedFilename] = useState('');
  const [fileExtension, setFileExtension] = useState('');
  const [filenameValid, setFilenameValid] = useState(false);
  const [fileObject, setFileObject] = useState<UploadObject>({ file: null, name: '' });

  const apiContext = useContext(AuthContext);
  const { t } = useTranslation();
  const { classes } = props;

  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const progressCallback = (pe: ProgressEvent) => {
    setUploadProgeress((pe.loaded / pe.total) * 100);
  };

  const uploadFile = (file: File, filename: string) => {
    setUploadInProgress(true);
    apiContext.api.getFileUploadURL(filename)
      .then((res) => {
        if (res.statusText) {
          const statusText = `${t('pages:files.fileHandling.duplicate1')} ${filename} ${t('pages:files.fileHandling.duplicate2')}`;
          props.setNotification(statusText, 'warning');
          setUploadInProgress(false);
          setFileObject({ file: null, name: '' });
          return;
        }
        const readyForUpload: boolean = Object.prototype.hasOwnProperty.call(res, 'url')
        && Object.prototype.hasOwnProperty.call(res, 'tagging');
        if (readyForUpload) {
          apiContext.api.directUploadFile(progressCallback, file, res.url, res.tagging)
            .then(() => {
              setUploadDone(true);
              setUploadInProgress(false);
              setUploadProgeress(0);
              setFileObject({ file: null, name: '' });
              props.triggerParentUpdate(filename);
              const statusText = `${filename} ${t('pages:files.fileHandling.upload')}`;
              props.setNotification(statusText, 'success');
            })
            .catch(() => {
              setUploadDone(false);
              setUploadInProgress(false);
            });
        } else {
          setUploadInProgress(false);
        }
      })
      .catch(() => {
        setUploadDone(false);
        setUploadInProgress(false);
      });
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Object.prototype.hasOwnProperty.call(event.target.files, 0) && !uploadInProgress) {
      setUploadInProgress(true);
      if (event.target.files !== null) {
        const file = event.target.files[0];
        setFileObject({ file, name: file.name });
      }
    }
    if (inputRef !== null && inputRef.current !== null) {
      inputRef.current.value = '';
    }
  };

  const validate = (filename: string) => {
    let validFilename = filename.replace(/[^a-zA-Z0-9\-_.]+/g, '');
    if (validFilename.length > 128) {
      validFilename = validFilename.slice(0, 128);
    }
    const valid = filename === validFilename;
    const res: [boolean, string] = [valid, validFilename];
    return res;
  };

  const checkExtension = (extension: string) => {
    const validExtensions = ['hpr', 'drf', 'mom'];
    return validExtensions.includes(extension);
  };

  const validateFilename = (filename: string, update: boolean) => {
    const filenameParts = filename.split('.');
    let extension = ''; let
      name = '';
    if (filenameParts.length >= 2) {
      extension = filenameParts[filenameParts.length - 1];
      filenameParts.pop();
      name = filenameParts !== undefined ? filenameParts.join('.') : '';
    }
    if (!checkExtension(extension)) {
      const statusText = `${t('pages:files.fileHandling.unsupportedFileType')}`;
      props.setNotification(statusText, 'error');
      setUploadInProgress(false);
      setFileObject({ file: null, name: '' });
      return;
    }
    setFileExtension(extension);
    const [valid, validFilename] = validate(name);
    if (update && !valid) {
      setEditedFilename(validFilename);
      setFilenameValid(true);
    }
    if (valid && fileObject.file !== null) {
      uploadFile(fileObject.file, filename);
    } else {
      setOpenDialog(true);
    }
  };

  React.useEffect(() => {
    setUploadDone(false);
  },
  [props.files]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (fileObject.file !== null) {
      validateFilename(fileObject.name, true);
    }
  },
  [fileObject.name]); //eslint-disable-line

  const handleClickUploadModal = () => {
    setOpenDialog(false);
    const updatedFilename: string = `${editedFilename}.${fileExtension}`;
    setFileObject({ file: fileObject.file, name: updatedFilename });
  };

  const handleClickCancelModal = () => {
    setOpenDialog(false);
    setUploadInProgress(false);
    setFileObject({ file: null, name: '' });
  };

  const validateUserInput = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const filename = event.target.value;
    const [valid] = validate(filename);
    setEditedFilename(filename);
    setFilenameValid(valid);
  };


  return (
    <div className={classes.root}>
      <label htmlFor="raised-button-file">
        <Button variant="contained" component="span" className={classes.button} disabled={uploadInProgress || uploadDone}>
          {uploadInProgress || uploadDone
            ? (
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <CircularProgress size={20} />
                <LinearProgress variant="determinate" value={uploadProgress} style={{ width: '100%', marginTop: 8 }} />
              </Grid>
            )
            : t('pages:files.upload')
          }
        </Button>
        <Input inputRef={inputRef} className={classes.input} type="file" id="raised-button-file" onChange={onChangeHandler} disabled={uploadInProgress || uploadDone} />
      </label>
      <Dialog open={openDialog} onClose={handleClickCancelModal} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t('pages:files.fileHandling.noValidFilename')}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ whiteSpace: 'pre-line' }}>
            {`${t('pages:files.fileHandling.filenamesMustContain')}:
               - ${t('pages:files.fileHandling.alphanumeric')} (0-9, A-Z, a-z)
               - ${t('pages:files.fileHandling.special')} ( -_. )
               - ${t('pages:files.fileHandling.maxLength')}
              `}
          </DialogContentText>
          <TextField
            error={!filenameValid}
            defaultValue={editedFilename}
            autoFocus
            margin="dense"
            id="name"
            label={t('pages:files.fileHandling.filename')}
            type="text"
            fullWidth
            onChange={e => validateUserInput(e)}
            InputProps={{
              endAdornment:
  <InputAdornment position="start">
    {`.${fileExtension}`}
  </InputAdornment>,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickCancelModal} color="primary">
            {t('components:button.cancel')}
          </Button>
          <Button onClick={handleClickUploadModal} color="secondary" disabled={!filenameValid} autoFocus={!({ file: null, name: '' })}>
            {t('pages:files.upload')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(FileUpload);
