import React from 'react';
import { Link } from 'react-router-dom';
import { Translation } from 'react-i18next';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import Folder from '@material-ui/icons/Folder';
import Place from '@material-ui/icons/Place';
import Settings from '@material-ui/icons/Settings';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {
  MenuItem,
  Menu,
  IconButton,
} from '@material-ui/core';
import { customTheme } from './Theme';
import NavLink from './Navigation/NavLink';
import { AuthConsumer, AuthContext, AuthInterface } from './AuthContext';

const styles = (theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.dark,
  },
  grow: {
    flexGrow: 1,
  },
  menuIcon: {
    color: theme.palette.grey[100],
  },
  button: {
    color: theme.palette.grey[100],
  },
  link: {
    textDecoration: 'none',
    color: 'black',
  },
  logo: {
    height: '31px',
  },
});

interface Props extends WithStyles<typeof styles> {
  theme: Theme;
}

interface State {
  width: number;
  anchorEl: any; // eslint-disable-line
  profileAnchorEl: any;// eslint-disable-line
}

class Navigation extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      anchorEl: null,
      profileAnchorEl: null,
      width: window.innerWidth,
    };
  }

  public componentWillMount() {
    this.setState({ width: window.innerWidth });

    window.addEventListener('resize', () => {
      this.setState({ width: window.innerWidth });
    });
  }

  private handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  private handleClose = () => {
    this.setState({ anchorEl: null });
  };


  private handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ profileAnchorEl: event.currentTarget });
  };

  private handleProfileMenuClose = () => {
    this.setState({ profileAnchorEl: null });
  };

  private logout(logout: () => void) {
    this.handleProfileMenuClose();
    logout();
  }

  public render() {
    const { classes } = this.props;
    const { accessRights }: AuthInterface = this.context;
    const showDasa = (this.props.theme === customTheme);
    const mobile = (this.state.width < 700);
    const showFiles = () => {
      if (accessRights('FileViewer')) {
        const fileLink = (
          <Translation>
            {t => (
              <NavLink to="/files/" Icon={Folder} dark={!mobile}>
                {t('components:navigation.files')}
              </NavLink>
            )}
          </Translation>
        );
        return mobile ? (
          <MenuItem onClick={this.handleClose}>
            {fileLink}
          </MenuItem>
        ) : fileLink;
      }
      return undefined;
    };
    // const showFiles = accessRights('FileViewer');
    const authenticatedMenu = mobile ? (
      <Translation>
        {t => (
          <>
            <Button
              aria-owns={this.state.anchorEl ? 'simple-menu' : undefined}
              aria-haspopup="true"
              onClick={this.handleClick}
              className={classes.menuIcon}
            >
              <MenuIcon />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={this.state.anchorEl}
              open={Boolean(this.state.anchorEl)}
              onClose={this.handleClose}
            >
              <MenuItem onClick={this.handleClose}>
                <NavLink to="/overview/" Icon={RemoveRedEye}>
                  {t('components:navigation.overview')}
                </NavLink>
              </MenuItem>
              <MenuItem onClick={this.handleClose}>
                <NavLink to="/machines/" Icon={Place}>
                  {t('components:navigation.machine')}
                </NavLink>
              </MenuItem>
              <MenuItem onClick={this.handleClose}>
                <NavLink to="/admin/" Icon={Settings}>
                  {t('components:navigation.admin')}
                </NavLink>
              </MenuItem>
              {showFiles()}
            </Menu>
          </>
        )}
      </Translation>
    ) : (
      <Translation>
        {t => (
          <>
            {showFiles()}
            <NavLink to="/overview/" Icon={RemoveRedEye} dark>
              {t('components:navigation.overview')}
            </NavLink>
            <NavLink to="/machines/" Icon={Place} dark>
              {t('components:navigation.machine')}
            </NavLink>
            <NavLink to="/admin/" Icon={Settings} dark>
              {t('components:navigation.admin')}
            </NavLink>
          </>
        )}
      </Translation>
    );

    const open = Boolean(this.state.profileAnchorEl);
    return (
      <AppBar className={classes.root} position="static">
        <AuthConsumer>
          {({ api, user }) => (
            <Translation>
              {t => (
                <Toolbar>
                  <Typography variant="h6" className={classes.grow}>
                    <Link to="/">
                      {showDasa ? <img className={classes.logo} alt="Dasa" src="https://www.dasa.se/Content/Images/logowhite.png?width=100" />
                        : <img className={classes.logo} alt="Attentec" src="https://www.attentec.se/wp-content/uploads/2019/01/Attentec-logo-vit_web.png" />
                      }
                    </Link>
                  </Typography>
                  {authenticatedMenu}
                  <>
                    <IconButton
                      className={classes.menuIcon}
                      aria-owns={open ? 'menu-appbar' : undefined}
                      aria-haspopup="true"
                      onClick={this.handleProfileMenuOpen}
                      color="inherit"
                    >
                      <AccountCircle />
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      anchorEl={this.state.profileAnchorEl}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={open}
                      onClose={this.handleProfileMenuClose}
                    >
                      <MenuItem onClick={this.handleProfileMenuClose}>
                        <Link to="/profile/" className={classes.link}>
                          {`${t('components:navigation.profile')} - `}
                          {user && (user.userName)}
                        </Link>
                      </MenuItem>
                      <MenuItem onClick={() => this.logout(api.logout.bind(api))}>{t('components:navigation.signOut')}</MenuItem>
                    </Menu>
                  </>

                </Toolbar>
              )}
            </Translation>
          )}
        </AuthConsumer>
      </AppBar>
    );
  }
}

Navigation.contextType = AuthContext;
export default withStyles(styles, { withTheme: true })(Navigation);
